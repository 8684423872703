import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

const liveBidding = createApi({
  baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
  reducerPath: 'api.liveBidding',
  tagTypes: ['liveBidding'],
  endpoints: (builder) => ({
    getActiveMode: builder.query<any, { workspaceId: any | null }>({
      query: (arg) => ({
        url: '/liveBidding/active-mode',
        method: 'GET',
        params: {
          workspaceId: arg.workspaceId,
        },
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        }
      }),
    }),
    getKoBidding: builder.query<any, { workspaceId: any | null }>({
      query: (arg) => ({
        url: '/liveBidding/ko-bidding/max-cpc',
        method: 'GET',
        params: {
          workspaceId: arg.workspaceId,
        },
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        }
      }),
    }),
    getKoBiddingTargetMaximize: builder.query<any, { workspaceId: any | null }>({
      query: (arg) => ({
        url: '/liveBidding/ko-bidding/target-maximize',
        method: 'GET',
        params: {
          workspaceId: arg.workspaceId,
        },
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        }
      }),
    }),
    addActiveMode: builder.mutation<any, Partial<any>>({
      query: (newStrategy) => ({
        url: '/liveBidding/active-mode/add',
        method: 'POST',
        body: newStrategy,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateActiveMode: builder.mutation<any, { updates: any[] }>({
      query: (arg) => ({
        url: `/liveBidding/active-mode/update`,
        method: 'PUT',
        body: arg.updates,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    deleteActiveMode: builder.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `/liveBidding/active-mode/delete/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getKeywords: builder.query<any, { client: string }>({
      query: ({ client }) => ({
        url: `/liveBidding/keywords/active-mode`,
        method: 'POST',
        body: { client },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getKeywordsKoBidding: builder.query<any, { client: string }>({
      query: ({ client }) => ({
        url: `/liveBidding/keywords/ko-bidding`,
        method: 'POST',
        body: { client },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getCampaigns: builder.query<any, { client: string; keyword: string, profile: string, match_type: string }>({
      query: ({ client, keyword, profile, match_type }) => ({
        url: `/liveBidding/campaigns/active-mode`,
        method: 'POST',
        body: { client, keyword, profile, match_type },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getCampaignsKoBidding: builder.query<any, { client: string; profile: string }>({
      query: ({ client, profile }) => ({
        url: `/liveBidding/campaigns/ko-bidding`,
        method: 'POST',
        body: { client, profile },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getCampaignsShopping: builder.query<any, { client: string, profile: string }>({
      query: ({ client, profile }) => ({
        url: `/liveBidding/campaigns/shopping`,
        method: 'POST',
        body: { client, profile },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getCountries: builder.query<any, { client: string, keyword: string, campaign: string, profile: string }>({
      query: ({ client, keyword, campaign, profile }) => ({
        url: `/liveBidding/countries/active-mode`,
        method: 'POST',
        body: { client, keyword, campaign, profile },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getProfile: builder.query<any, { client: string, keyword: string }>({
      query: ({ client, keyword }) => ({
        url: `/liveBidding/profile/active-mode`,
        method: 'POST',
        body: { client, keyword },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getProfileKoBidding: builder.query<any, { client: string }>({
      query: ({ client }) => ({
        url: `/liveBidding/profile/ko-bidding`,
        method: 'POST',
        body: { client },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getMatchTypeKoBidding: builder.query<any, { client: string, keyword: string }>({
      query: ({ client, keyword }) => ({
        url: '/liveBidding/match-type/ko-bidding',
        method: 'POST',
        body: { client, keyword },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getAdGroupKoBidding: builder.query<any, { client: string, keyword: string, profile: string, campaign: string, match_type: string }>({
      query: ({ client, keyword, campaign, profile, match_type }) => ({
        url: '/liveBidding/ad-group/ko-bidding',
        method: 'POST',
        body: { client, keyword, profile, campaign, match_type },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getAdGroupKoBiddingTargetMaximize: builder.query<any, { client: string, profile: string, campaign: any }>({
      query: ({ client, profile, campaign }) => ({
        url: '/liveBidding/ad-group/ko-bidding-target-maximize',
        method: 'POST',
        body: { client, profile, campaign },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getKeywordInfoKoBidding: builder.query<any, { client: string, keyword: string, campaign: string, profile: string, match_type: string, ad_group_name: string }>({
      query: ({ client, keyword, campaign, profile, match_type, ad_group_name }) => ({
        url: '/liveBidding/keyword-info/ko-bidding',
        method: 'POST',
        body: { client, keyword, campaign, profile, match_type, ad_group_name },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getKeywordInfoActiveMode: builder.query<any, { client: string, keyword: string, campaign: string, profile: string, country: any }>({
      query: ({ client, keyword, campaign, profile, country }) => ({
        url: '/liveBidding/keyword-info/active-mode',
        method: 'POST',
        body: { client, keyword, campaign, profile, country },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getCountriesKoBidding: builder.query<any, { client: string, keyword: string, profile: string, campaign: string, match_type: string }>({
      query: ({ client, keyword, campaign, profile, match_type }) => ({
        url: `/liveBidding/countries/ko-bidding`,
        method: 'POST',
        body: { client, keyword, campaign, profile, match_type },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getCountriesKoBiddingTargetMaximize: builder.query<any, { client: string, profile: string, campaign: string, ad_group_name: string }>({
      query: ({ client, campaign, profile, ad_group_name }) => ({
        url: `/liveBidding/countries/ko-bidding-target-maximize`,
        method: 'POST',
        body: { client, campaign, profile, ad_group_name },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getCountriesShopping: builder.query<any, { client: string, profile: string, campaign: string, ad_group_name: string }>({
      query: ({ client, ad_group_name, campaign, profile }) => ({
        url: `/liveBidding/countries/shopping`,
        method: 'POST',
        body: { client, ad_group_name, campaign, profile },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    addKoBidding: builder.mutation<any, Partial<any>>({
      query: (newKoBiddingStrategy) => ({
        url: '/liveBidding/ko-bidding/add',
        method: 'POST',
        body: newKoBiddingStrategy,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    deleteKoBidding: builder.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `/liveBidding/ko-bidding/delete/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateKoBidding: builder.mutation<any, { updates: any[] }>({
      query: (arg) => ({
        url: `/liveBidding/ko-bidding/update`,
        method: 'PUT',
        body: arg.updates,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getProfileShopping: builder.query<any, { client: string }>({
      query: ({ client }) => ({
        url: `/liveBidding/profile/shopping`,
        method: 'POST',
        body: { client },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getAdGroupShopping: builder.query<any, { client: string; campaign: string, profile: string }>({
      query: ({ client, campaign, profile }) => ({
        url: `/liveBidding/ad-groups/shopping`,
        method: 'POST',
        body: { client, campaign, profile },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    campaignInfoShopping: builder.query<any, { client: string; campaign: string, profile: string, ad_group_name: string }>({
      query: ({ client, campaign, profile, ad_group_name }) => ({
        url: `/liveBidding/campaign-info/shopping`,
        method: 'POST',
        body: { client, campaign, profile, ad_group_name },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    campaignInfoKoBidding: builder.query<any, { client: string; campaign: string, profile: string, ad_group_name: string, keyword: any, match_type: any }>({
      query: ({ client, campaign, profile, ad_group_name, keyword, match_type }) => ({
        url: `/liveBidding/campaign-info/ko-bidding`,
        method: 'POST',
        body: { client, campaign, profile, ad_group_name, keyword, match_type },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    campaignInfoKoBiddingTargetMaximize: builder.query<any, { client: string; campaign: string, profile: string }>({
      query: ({ client, campaign, profile }) => ({
        url: `/liveBidding/campaign-info/ko-bidding-target-maximize`,
        method: 'POST',
        body: { client, campaign, profile },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    profileInfoShopping: builder.query<any, { client: string; campaign: string, profile: string, ad_group_name: string }>({
      query: ({ client, campaign, profile, ad_group_name }) => ({
        url: `/liveBidding/profile-info/shopping`,
        method: 'POST',
        body: { client, campaign, profile, ad_group_name },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    profileInfoKoBidding: builder.query<any, { client: string; campaign: string, profile: string, ad_group_name: string, keyword: any, match_type: any }>({
      query: ({ client, campaign, profile, ad_group_name, keyword, match_type }) => ({
        url: `/liveBidding/profile-info/ko-bidding`,
        method: 'POST',
        body: { client, campaign, profile, ad_group_name, keyword, match_type },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    profileInfoKoBiddingTargetMaximize: builder.query<any, { client: string; campaign: string, profile: string, ad_group_name: string }>({
      query: ({ client, campaign, profile, ad_group_name }) => ({
        url: `/liveBidding/profile-info/ko-bidding-target-maximize`,
        method: 'POST',
        body: { client, campaign, profile, ad_group_name },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getShopping: builder.query<any, { workspaceId: any | null }>({
      query: (arg) => ({
        url: '/liveBidding/shopping',
        method: 'GET',
        params: {
          workspaceId: arg.workspaceId,
        },
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        },
      }),
    }),
    // Méthode pour ajouter une stratégie Shopping
    addShopping: builder.mutation<any, Partial<any>>({
      query: (newShoppingStrategy) => ({
        url: '/liveBidding/shopping/add',
        method: 'POST',
        body: newShoppingStrategy,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    deleteShopping: builder.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `/liveBidding/shopping/delete/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateShopping: builder.mutation<any, { updates: any[] }>({
      query: (arg) => ({
        url: `/liveBidding/shopping/update`,
        method: 'PUT',
        body: arg.updates,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    checkActiveModeExists: builder.mutation<any, { keywords: string, country: string, workspaceId: any, profile: string }>({
      query: ({ keywords, country, workspaceId, profile }) => ({
        url: '/liveBidding/active-mode/exists',
        method: 'GET',
        params: {
          keywords,
          country,
          workspaceId,
          profile
        },
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        }
      }),
    }),
    checkKoBiddingExists: builder.mutation<any, { keywords: string, workspaceId: any, profile: string, campaign: string, ad_group: string, match_type: string }>({
      query: ({ keywords, workspaceId, profile, campaign, ad_group, match_type }) => ({
        url: '/liveBidding/ko-bidding/exists',
        method: 'GET',
        params: {
          keywords,
          workspaceId,
          profile,
          campaign,
          ad_group,
          match_type,
        },
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        }
      }),
    }),
    checkShoppingExists: builder.mutation<any, { workspaceId: any, profile: string, campaign: string, ad_group: string }>({
      query: ({ workspaceId, profile, campaign, ad_group }) => ({
        url: '/liveBidding/shopping/exists',
        method: 'GET',
        params: {
          workspaceId,
          profile,
          campaign,
          ad_group,
        },
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        }
      }),
    }),
    checkShoppingCampaignExists: builder.mutation<any, { workspaceId: any, campaign: string }>({
      query: ({ workspaceId, campaign }) => ({
        url: '/liveBidding/shopping/campaign/exists',
        method: 'GET',
        params: {
          workspaceId,
          campaign,
        },
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        }
      }),
    }),
    checkKoBiddingCampaignExists: builder.mutation<any, { workspaceId: any, campaign: string }>({
      query: ({ workspaceId, campaign }) => ({
        url: '/liveBidding/ko-bidding/campaign/exists',
        method: 'GET',
        params: {
          workspaceId,
          campaign,
        },
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        }
      }),
    }),
    getBiddingStrategyType: builder.query<any, { client: string; profile: string; campaign: string }>({
      query: ({ client, profile, campaign }) => ({
        url: `/liveBidding/bidding-strategy-type/shopping`,
        method: 'POST',
        body: { client, profile, campaign },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getBiddingStrategyTypeKoBidding: builder.query<any, { client: string; profile: string; campaign: string }>({
      query: ({ client, profile, campaign }) => ({
        url: `/liveBidding/bidding-strategy-type/ko-bidding`,
        method: 'POST',
        body: { client, profile, campaign },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useGetActiveModeQuery,
  useGetKoBiddingQuery,
  useAddActiveModeMutation,
  useGetKeywordsQuery,
  useGetCampaignsQuery,
  useGetCountriesQuery,
  useDeleteActiveModeMutation,
  useGetProfileQuery,
  useUpdateActiveModeMutation,
  useGetKeywordsKoBiddingQuery,
  useGetMatchTypeKoBiddingQuery,
  useGetAdGroupKoBiddingQuery,
  useGetCountriesKoBiddingQuery,
  useAddKoBiddingMutation,
  useDeleteKoBiddingMutation,
  useGetProfileKoBiddingQuery,
  useUpdateKoBiddingMutation,
  useGetKeywordInfoKoBiddingQuery,
  useGetKeywordInfoActiveModeQuery,
  useGetCampaignsShoppingQuery,
  useGetAdGroupShoppingQuery,
  useGetProfileShoppingQuery,
  useCampaignInfoShoppingQuery,
  useGetCountriesShoppingQuery,
  useAddShoppingMutation,
  useGetShoppingQuery,
  useDeleteShoppingMutation,
  useUpdateShoppingMutation,
  useCheckActiveModeExistsMutation,
  useCheckKoBiddingExistsMutation,
  useCheckShoppingExistsMutation,
  useCheckShoppingCampaignExistsMutation,
  useGetBiddingStrategyTypeQuery,
  useProfileInfoShoppingQuery,
  useGetCampaignsKoBiddingQuery,
  useGetBiddingStrategyTypeKoBiddingQuery,
  useCampaignInfoKoBiddingQuery,
  useProfileInfoKoBiddingQuery,
  useGetAdGroupKoBiddingTargetMaximizeQuery,
  useCampaignInfoKoBiddingTargetMaximizeQuery,
  useProfileInfoKoBiddingTargetMaximizeQuery,
  useGetCountriesKoBiddingTargetMaximizeQuery,
  useGetKoBiddingTargetMaximizeQuery,
  useCheckKoBiddingCampaignExistsMutation
} = liveBidding;

export default liveBidding;
