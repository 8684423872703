import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subDays } from 'date-fns';

interface Keyword {
    search_term: string;
    total_organic_searches: any;
}

interface FilterState {
    start: string;
    end: string;
    compareStart: string;
    compareEnd: string;
    minDatePicker: string;
    timestep: string;
    devices: string[];
    loadedDevices: string[];
    countries: string[];
    loadedCountries: string[];
    keywords: string[];
    brandsPLA: string[];
    advertizersPLA: string[];
    notificationBrandInfringementNotification: any,
    notificationBrandInfringement: boolean;
    notificationBrandInfringementNotificationTXT: any,
    notificationBrandInfringementTXT: boolean;
    ratingRangePLA: [number, number];
    pricingRangePLA: [number, number];
    averagePositionPLA: [number, number];
    impressionSharePLA: [number, number];
    keywordsPLA: string[];
    keywordsTopCountries: string[],
    keywordsTrafficAnalysis: string[],
    domainsPLA: string[];
    keywordTXT: string[];
    brandsTXT: string[],
    advertizerTXT: string[],
    domainsTXT: string[],
    impressionShareTXT: [number, number],
    averagePositionTXT: [number, number],
    keywordsTopCompetitors: string[],
    keywordsTopCompetitorsAds: string[],
    loadedKeywords: Keyword[];
    compare: boolean;
}

const initialState: FilterState = {
    start: new Date().toISOString(),
    end: subDays(new Date(), 30).toISOString(),
    compareStart: subDays(new Date(), 60).toISOString(),
    compareEnd: subDays(new Date(), 30).toISOString(),
    minDatePicker: '',
    timestep: 'year_week',
    devices: [],
    loadedDevices: [],
    countries: [],
    loadedCountries: [],
    keywords: [],
    averagePositionPLA: [0, 50],
    impressionSharePLA: [0, 100],
    keywordsPLA: [],
    notificationBrandInfringementNotification: [],
    notificationBrandInfringementNotificationTXT: [],
    notificationBrandInfringementTXT: false,
    domainsPLA: [],
    brandsPLA: [],
    ratingRangePLA: [0, 5],
    pricingRangePLA: [0, 50000],
    advertizersPLA: [],
    keywordTXT: [],
    brandsTXT: [],
    advertizerTXT: [],
    domainsTXT: [],
    impressionShareTXT: [0, 100],
    averagePositionTXT: [0, 50],
    notificationBrandInfringement: false,
    keywordsTopCountries: [],
    keywordsTrafficAnalysis: [],
    keywordsTopCompetitors: [],
    keywordsTopCompetitorsAds: [],
    loadedKeywords: [],
    compare: false,
};

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setFilterDate: (state, action: PayloadAction<{ start: string; end: string }>) => {
            state.start = action.payload.start;
            state.end = action.payload.end;
        },
        setFilterDateCompare: (state, action: PayloadAction<{ compareStart: string; compareEnd: string }>) => {
            state.compareStart = action.payload.compareStart;
            state.compareEnd = action.payload.compareEnd;
        },
        setFilterMinDatePicker: (state, action: PayloadAction<string>) => {
            state.minDatePicker = action.payload;
        },
        setFilterTimestep: (state, action: PayloadAction<string>) => {
            state.timestep = action.payload;
        },
        setFilterDevice: (state, action: PayloadAction<string[]>) => {
            state.devices = action.payload;
        },
        setFilterLoadedDevice: (state, action: PayloadAction<string[]>) => {
            state.loadedDevices = action.payload;
        },
        setFilterCountry: (state, action: PayloadAction<string[]>) => {
            state.countries = action.payload;
        },
        setFilterLoadedCountry: (state, action: PayloadAction<string[]>) => {
            state.loadedCountries = action.payload;
        },
        setFilterKeyword: (state, action: PayloadAction<string[]>) => {
            state.keywords = action.payload;
        },
        setFilterLoadedKeyword: (state, action: PayloadAction<Keyword[]>) => {
            state.loadedKeywords = action.payload;
        },
        resetLoadedKeyword: (state) => {
            state.loadedKeywords = [];
        },
        setFilterCompare: (state, action: PayloadAction<boolean>) => {
            state.compare = action.payload;
        },
        setFilterKeywordTopCountries: (state, action: PayloadAction<string[]>) => {
            state.keywordsTopCountries = action.payload;
        },
        setFilterKeywordTrafficAnalysis: (state, action: PayloadAction<string[]>) => {
            state.keywordsTrafficAnalysis = action.payload;
        },
        setFilterKeywordTopCompetitors: (state, action: PayloadAction<string[]>) => {
            state.keywordsTopCompetitors = action.payload;
        },
        setFilterKeywordTopCompetitorsAds: (state, action: PayloadAction<string[]>) => {
            state.keywordsTopCompetitorsAds = action.payload;
        },
        setFilterKeywordPLA: (state, action: PayloadAction<string[]>) => {
            state.keywordsPLA = action.payload;
        },
        setFilterBrandPLA: (state, action: PayloadAction<string[]>) => {
            state.brandsPLA = action.payload;
        },
        setFilterAdvertizerPLA: (state, action: PayloadAction<string[]>) => {
            state.advertizersPLA = action.payload;
        },
        setFilterRatingRangePLA: (state, action: PayloadAction<[number, number]>) => {
            state.ratingRangePLA = action.payload;
        },
        setFilterPricingRangePLA: (state, action: PayloadAction<[number, number]>) => {
            state.pricingRangePLA = action.payload;
        },
        setFilterDomainPLA: (state, action: PayloadAction<string[]>) => {
            state.domainsPLA = action.payload;
        },
        setFilterKeywordTXT: (state, action: PayloadAction<string[]>) => {
            state.keywordTXT = action.payload;
        },
        setFilterBrandTXT: (state, action: PayloadAction<string[]>) => {
            state.brandsTXT = action.payload;
        },
        setFilterAdvertizerTXT: (state, action: PayloadAction<string[]>) => {
            state.advertizerTXT = action.payload;
        },
        setFilterDomainTXT: (state, action: PayloadAction<string[]>) => {
            state.domainsTXT = action.payload;
        },
        setFilterImpressionShareTXT: (state, action) => {
            state.impressionShareTXT = action.payload;
        },
        setFilterAveragePositionTXT: (state, action) => {
            state.averagePositionTXT = action.payload;
        },
        setNotificationBrandInfringement: (state, action: PayloadAction<boolean>) => {
            state.notificationBrandInfringement = action.payload;
        },
        setNotificationBrandInfringementTXT: (state, action: PayloadAction<boolean>) => {
            state.notificationBrandInfringementTXT = action.payload;
        },
        setNotificationBrandInfringementNotification: (state, action: PayloadAction<any>) => {
            state.notificationBrandInfringementNotification = action.payload;
        },
        setNotificationBrandInfringementNotificationTXT: (state, action: PayloadAction<any>) => {
            state.notificationBrandInfringementNotificationTXT = action.payload;
        },
        setFilterAveragePositionPLA: (state, action) => {
            state.averagePositionPLA = action.payload;
        },
        setFilterImpressionSharePLA: (state, action) => {
            state.impressionSharePLA = action.payload;
        },
    },
});

export const {
    setFilterDate,
    setFilterDevice,
    setFilterLoadedDevice,
    setFilterCountry,
    setFilterLoadedCountry,
    setFilterKeyword,
    setFilterKeywordTopCountries,
    setFilterKeywordTrafficAnalysis,
    setFilterKeywordTopCompetitors,
    setFilterKeywordTopCompetitorsAds,
    setFilterLoadedKeyword,
    resetLoadedKeyword,
    setFilterTimestep,
    setFilterDateCompare,
    setFilterMinDatePicker,
    setFilterCompare,
    setFilterKeywordPLA,
    setFilterBrandPLA,
    setFilterAdvertizerPLA,
    setFilterRatingRangePLA,
    setFilterPricingRangePLA,
    setFilterDomainPLA,
    setFilterKeywordTXT,
    setFilterBrandTXT,
    setFilterAdvertizerTXT,
    setFilterDomainTXT,
    setFilterImpressionShareTXT,
    setFilterAveragePositionTXT,
    setFilterAveragePositionPLA,
    setFilterImpressionSharePLA,
    setNotificationBrandInfringement,
    setNotificationBrandInfringementNotification,
    setNotificationBrandInfringementTXT,
    setNotificationBrandInfringementNotificationTXT
} = filtersSlice.actions;

export default filtersSlice.reducer;