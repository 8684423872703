import { useState, useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { LinearProgress, Typography } from '@mui/material';
import { fPercent } from 'src/utils/format-number';
import { setFilterCountry, setFilterKeyword } from 'src/context/reducers/filter-settings/filterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, useTheme } from '@mui/system';
import { useLocales } from 'src/locales';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useResponsive } from 'src/hooks/use-responsive';
import countries from "../../utils/countries.json"
import Iconify from '../iconify';
import { formatCurrency } from 'src/utils/format-currency';

// ----------------------------------------------------------------------

const createColumns = (theme: any, t: any, formatCurrency: any, currency: any, locale: any, lgUp: any): GridColDef[] => [
  {
    field: 'keyword',
    headerName: t('dashboard.global.keyword'),
    flex: lgUp ? 1 : undefined,
  },
  {
    field: 'brandSearches',
    headerName: t('dashboard.brandTraffic.brandSearches'),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ backgroundColor: theme.palette.mode === "dark" ? '#402D2F' : '#fce1db', padding: 7, color: theme.palette.mode === "dark" ? '#c7866a' : "#b71f1a", borderRadius: 4, fontWeight: 700 }}>
        {formatCurrency(params.row.brandSearches, currency, locale, true, 1)}
      </span>
    ),
  },
  {
    field: 'organicTraffic',
    headerName: t('dashboard.brandOverview.organic_traffic'),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ backgroundColor: theme.palette.mode === "dark" ? '#21433a' : '#d8f3e3', padding: 7, color: theme.palette.mode === "dark" ? '#75ea89' : "#118d57", borderRadius: 4, fontWeight: 700 }}>
        {formatCurrency(params.row.organicTraffic, currency, locale, true, 1)}
      </span>
    ),
  },
  {
    field: 'paidTraffic',
    headerName: t('dashboard.global.paid_traffic'),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ backgroundColor: theme.palette.mode === "dark" ? '#1c414e' : '#d3f1f7', padding: 7, color: theme.palette.mode === "dark" ? '#61f3f3' : "#06709e", borderRadius: 4, fontWeight: 700 }}>
        {formatCurrency(params.row.paidTraffic, currency, locale, true, 1)}
      </span>
    ),
  },
  {
    field: 'uncapturedBrandTraffic',
    headerName: t('dashboard.brandTraffic.uncaptured_brand_traffic'),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ backgroundColor: theme.palette.mode === "dark" ? '#333c47' : '#eaecf0', padding: 7, color: theme.palette.mode === "dark" ? 'white' : "#637381", borderRadius: 4, fontWeight: 700 }}>
        {formatCurrency(params.row.uncapturedBrandTraffic, currency, locale, true, 1)}
      </span>
    ),
  },
  {
    field: 'blendedCtr',
    headerName: t('dashboard.global.blended_ctr'),
    flex: lgUp ? 1 : undefined,
    width: 160,
    renderCell: (params) => (
      <span style={{ backgroundColor: theme.palette.mode === "dark" ? '#332b54' : '#eadbfd', padding: 7, color: theme.palette.mode === "dark" ? '#c281fb' : "#5119b7", borderRadius: 4, fontWeight: 700 }}>
        {params.row.blendedCtr === 0 ? '0%' : fPercent(params.row.blendedCtr)}
      </span>
    ),
    type: 'number',
  },
  {
    field: 'adCost',
    headerName: t('dashboard.global.ad_cost'),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ backgroundColor: theme.palette.mode === "dark" ? '#453f2b' : '#fcefd4', padding: 7, color: theme.palette.mode === "dark" ? '#ffd666' : "#bb750d", borderRadius: 4, fontWeight: 700 }}>
        {formatCurrency(params.row.adCost, currency, locale, true, 1)}
      </span>
    ),
    type: 'number',
  },
];


// ----------------------------------------------------------------------

type Props = {
  data: {
    id: string;
    keyword: string;
  }[];
};

export default function DataGridCustomKeywords({ data }: Props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const { t } = useLocales();
  const workspace = useCurrentWorkspace();
  const currency = workspace?.currency || 'USD';
  const locale = localStorage.getItem('i18nextLng')
  const lgUp = useResponsive('up', 'lg');

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-root': {
          '--unstable_DataGrid-radius': '0px',
        },
        '& .css-jr9x4l-MuiTablePagination-displayedRows': {
          'display': 'none!important'
        },
        '& .css-yrdy0g-MuiDataGrid-columnHeaderRow': {
          background: theme.palette.mode === "dark" ? '#28323c!important' : '#f4f6f8!important', // Appliquer la couleur de fond
        },
        '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
          color: theme.palette.mode === "dark" ? '#959fa9!important' : '#637381!important'
        },
        "& .css-lnsnn8-MuiDataGrid-rowCount": {
          display: 'none!important'
        }
      }}
    >
      <DataGridPro
        autoHeight
        disableColumnMenu
        disableDensitySelector
        checkboxSelection={false}
        disableRowSelectionOnClick
        rows={data}
        columns={createColumns(theme, t, formatCurrency, currency, locale, lgUp)}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
      />
    </Box>
  );
}
