import { useCurrentWorkspace } from "src/context/reducers/app-settings"
import { Badge, Box, Button, Chip, Divider, Drawer, drawerClasses, DrawerProps, IconButton, InputAdornment, MenuItem, MenuList, Paper, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useLocales } from "src/locales";
import { OrgChart } from 'd3-org-chart';
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useGetDataQuery } from "src/context/api/campaignExplorer/api";
import Iconify from "src/components/iconify";
import { paper } from "src/theme/css";
import { ClearIcon } from "@mui/x-date-pickers";
import CustomPopover from "src/components/custom-popover";
import { formatCurrency } from "src/utils/format-currency";

const FilterDrawerContext = createContext({
    open: false,
    toggleDrawer: () => { },
    onClose: () => { },
    onReset: () => { }
});

export const useFilterDrawer = () => useContext(FilterDrawerContext);

type Props = DrawerProps & {
    favorited?: boolean;
};

export default function CampaignExplorer() {
    const workspace = useCurrentWorkspace();
    const theme = useTheme()
    const { t } = useLocales();
    const chartRef = useRef<any>(null);
    const chartContainerRef = useRef(null);
    const lang: any = localStorage.getItem('i18nextLng');

    const [sortPeriod, setSortPeriod] = useState('l7d');
    const [anchorElPeriod, setAnchorElPeriod] = useState(null);

    const [searchCampaign, setSearchCampaign] = useState("");
    const [searchCampaignValue, setSearchCampaignValue] = useState("");
    const [searchAdGroup, setSearchAdGroup] = useState("");
    const [searchAdGroupValue, setSearchAdGroupValue] = useState("");
    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchKeywordValue, setSearchKeywordValue] = useState("");
    const [searchSearchTerm, setSearchSearchTerm] = useState("");
    const [searchSearchTermValue, setSearchSearchTermValue] = useState("");

    const queryResult = useGetDataQuery({
        client: workspace?.bqTable || '',
        searchAdGroup: searchAdGroup,
        searchCampaign: searchCampaign,
        searchKeyword: searchKeyword,
        searchSearchTerm: searchSearchTerm,
        sortPeriod: sortPeriod,
    });

    const { data: campaignExplorerData, error: statsError, isFetching, isLoading } = queryResult || {};

    const transformData = (data: any[]) => {
        return data.map((item: any) => ({
            id: item.id,
            client: item.client,
            name: item.displayLabel,
            children: item.children || [],
            displayLabel: item.display_label,
            iconStatus: item.global_status === "enabled"
                ? "/assets/icons/navbar/lets-icons:check-fill.svg"
                : item.global_status === "removed"
                    ? "/assets/icons/navbar/mdi:cross-circle.svg"
                    : item.global_status === "paused"
                        ? "/assets/icons/navbar/zondicons:pause-solid.svg"
                        : "/assets/icons/navbar/lets-icons:check-fill.svg",
            icon:
                item.item_type === "Campaign"
                    ? item.campaign_type === "Search"
                        ? "/assets/icons/navbar/solar--card-search-bold-duotone-2.svg"
                        : item.campaign_type === "Shopping"
                            ? "/assets/icons/navbar/solar:bag-2-bold-duotone.svg"
                            : item.campaign_type === "Performance Max"
                                ? "/assets/icons/navbar/solar:graph-up-bold-duotone.svg"
                                : null // Icône par défaut pour Campaign si aucune correspondance
                    : item.item_type === "Ad Group"
                        ? "/assets/icons/navbar/solar--layers-minimalistic-bold-duotone.svg"
                        : item.item_type === "Account"
                            ? "/assets/icons/navbar/mdi--google-ads.svg"
                            : item.item_type === "Keyword"
                                ? "/assets/icons/navbar/solar--key-minimalistic-square-2-bold-duotone.svg"
                                : item.item_type === "Search Term"
                                    ? "/assets/icons/navbar/solar--text-field-focus-bold-duotone.svg"
                                    : "https://cdn-icons-png.flaticon.com/128/10440/10440763.png", // Icône par défaut pour les autres types

            bgColor:
                item.item_type === "Account"
                    ? "#431A9E"
                    : item.item_type === "Campaign"
                        ? "#B71D18"
                        : item.item_type === "Ad Group"
                            ? "#118D57"
                            : item.item_type === "Keyword"
                                ? "#FFAB00"
                                : item.item_type === "Search Term"
                                    ? "#006C9C"
                                    : "#B76E00",
            itemType: item.item_type,
            globalStatus: item?.global_status,
            campaignType: item?.campaign_type,
            parentId: item.parent_id,
            biddingStrategyType: item?.bidding_strategy_type,
            cost: item?.cost,
            clicks: item?.clicks,
            impressions: item?.impressions,
            matchType: item.match_type,
            campaignSecondaryStatus: item?.campaign_secondary_status,
            campaignSecondaryStatusReasons: item?.campaign_secondary_status_reasons,
            backgroundSpanStatus: item.item_type === "Account"
                ? "#B985F4"
                : item.item_type === "Campaign"
                    ? "#FF5630"
                    : item.item_type === "Ad Group"
                        ? "#22C55E"
                        : item.item_type === "Keyword"
                            ? "#FFD666"
                            : item.item_type === "Search Term"
                                ? "#00B8D9"
                                : "#B76E00",
            textColor: item.item_type === "Account"
                ? "#B985F4"
                : item.item_type === "Campaign"
                    ? "#FF5630"
                    : item.item_type === "Ad Group"
                        ? "#22C55E"
                        : item.item_type === "Keyword"
                            ? "#FFD666"
                            : item.item_type === "Search Term"
                                ? "#00B8D9"
                                : "#B76E00",
            colorTxtStatus: item.item_type === "Account"
                ? "white"
                : item.item_type === "Campaign"
                    ? "white"
                    : item.item_type === "Ad Group"
                        ? "white"
                        : item.item_type === "Keyword"
                            ? "black"
                            : item.item_type === "Search Term"
                                ? "white"
                                : "#B76E00",

        }));
    };

    useEffect(() => {
        if (campaignExplorerData && chartContainerRef.current) {
            const transformedData = transformData(campaignExplorerData);

            chartRef.current = new OrgChart()
                .nodeHeight((d: any) => {
                    const baseHeight = 150; // Hauteur de base
                    const charPerLine = 28; // Nombre de caractères par ligne
                    const lineHeight = 25; // Hauteur ajoutée par ligne supplémentaire

                    // Calculer le nombre de lignes nécessaires
                    const numLines = Math.ceil(d.data.displayLabel.length / charPerLine);

                    // Retourner la hauteur totale
                    return baseHeight + (numLines - 1) * lineHeight; // La première ligne est incluse dans `baseHeight`
                })


                .nodeWidth((d: any) => 230 + 45)
                .childrenMargin((d: any) => 50)
                .compactMarginBetween((d: any) => 35)
                .compactMarginPair((d: any) => 30)
                .neighbourMargin((a: any, b: any) => 20)
                .nodeContent(function (d: any, i: any, arr: any, state: any) {
                    const imageDiffVert = 25 + 2;
                    const highlightedDisplayLabel = highlightText(d.data.displayLabel, searchAdGroup, searchCampaign, searchKeyword, searchSearchTerm, d.data.itemType);
                    return `
                    <div style='width:${d.width}px;height:${d.height}px; position:relative;'>
                        <!-- Conteneur principal avec border-radius -->
                        <div
                            style="width: 100%; height: 100%; background-color:${d.data.bgColor || '#B71D18'}; border-radius: 10px; overflow: hidden; position: relative; padding: 10px; box-sizing: border-box;">

                            <!-- Carré pivoté -->
                            <div
                                style="position: absolute; top: -40px; right: -40px; width: 100px; height: 100px; background: rgba(255, 255, 255, 0.1); border-radius: 20px; transform: rotate(25deg); z-index: 0;">
                                <image
                                    style="widht: 32px; height: 32px; position: relative; right: -20px; top: 60px; transform: rotate(-25deg);"
                                    src="${d.data.icon}" />
                            </div>

                            <!-- Contenu principal -->
                            <div style="position: relative; z-index: 1;">
                                <!-- Statut Global et Type d'objet -->
                                <div style="display: flex; flex-direction: row; align-items: center;">
                                    <!-- Statut Global -->
                                    <div title="${d?.data?.itemType === "Campaign" ? `${d?.data?.campaignSecondaryStatus}: ${d?.data?.campaignSecondaryStatusReasons}` : ''}"
                                        style="background-color: ${d?.data?.backgroundSpanStatus}; padding: 2px 5px; border-radius: 5px; display: flex; flex-direction: row">
                                        <image style="widht: 16px; height: 16px;" src="${d.data.iconStatus}" />
                                        <div
                                            style="color:${d?.data?.colorTxtStatus}; font-size:10px; position: relative; top: 1px; margin-left: 3px">
                                            ${d.data.globalStatus ? d.data.globalStatus.charAt(0).toUpperCase() +
                            d.data.globalStatus.slice(1) : "Enabled"}
                                        </div>
                                    </div>

                                    <!-- Type d'objet -->
                                    <div style="color:${d?.data?.textColor}; margin-left: 5px; margin-right: 5px; font-size:10px;">
                                        ${d.data.itemType}</div>

                                    ${d.data.itemType === "Campaign" ?
                            `<div style=color:${d?.data?.textColor}; margin-left:5px; font-size:10px">•</div>
                                    <!-- Type de campagne -->
                                    <div style="color:${d?.data?.textColor}; margin-left:5px; font-size:10px;">${d.data.campaignType ===
                                "Performance Max" ? "Pmax" : d.data.campaignType}</div>`
                            : ''
                        }

                                </div>

                                <!-- Label Principal -->
                                <div
                                    style="font-size:11px; font-weight:600; color:white; margin-top:10px; word-wrap: break-word; width: 90%; line-height: 1.8;">
                                    ${highlightedDisplayLabel}
                                </div>


                               <div style="display:flex; flex-direction: row; gap: 4px; align-items:center; margin-top:20px; flex-wrap: nowrap; overflow: hidden;">
                                <!-- KPI 1 -->
                                <div style="text-align:center; background-color: rgba(0, 0, 0, 0.2); padding: 3px; border-radius: 5px; flex-shrink: 0;">
                                    <div style="display: flex; flex-direction: row; align-items: center;">
                                        <img src="https://www.cross-brand.com/wp-content/uploads/2023/12/cropped-fav-cross-brand-32x32.png"
                                            style="
                                                height: 16px;
                                                width: 16px;
                                                background: white;
                                                padding: 2px;
                                                border-radius: 3px;
                                                position: relative;
                                            ">
                                        <div style="font-size:10px; font-weight:600; color:white; margin-left: 3px; white-space: nowrap;">
                                            Ko Bidding Actived
                                        </div>
                                    </div>
                                </div>

                                <!-- KPI 2 -->
                                ${d.data.biddingStrategyType
                            ? `<div style="display: flex; flex-direction: row; text-align:center; background-color: rgba(0, 0, 0, 0.2); padding: 3px; border-radius: 5px; flex-shrink: 0;">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m14 22l-6-3.5v-7l3.5-2.05v6.05h5V9.45L20 11.5v7zM5.5 11L2 9V5l3.5-2L9 5v4zm7.5 3V2h9l-2 3l2 3h-7v6z"></path></svg>
                                        </div>
                                        <div style="font-size:10px; margin-left: 3px; font-weight:600; color:white; white-space: nowrap;">${d.data.biddingStrategyType}</div>
                                    </div>`
                            : ''
                        }
                            </div>


                                <!-- Indicateurs -->
                                <div style="display:flex; flex-direction: row; gap: 4px; align-items:center; margin-top:10px;">
                                    <!-- KPI 1 -->
                                    <div
                                        title="Ad Cost on ${periodOptions.find(option => option.value === sortPeriod)?.label} (${formatCurrency(d.data.cost, workspace?.currency, lang, false, 2)})"
                                        style="text-align:center; background-color: rgba(0, 0, 0, 0.2); padding: 3px; border-radius: 5px; display: flex; flex-direction: row">
                                        <div style="position: relative; top: 1px">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                <path fill="currentColor"
                                                    d="M11.25 7.847c-.936.256-1.5.975-1.5 1.653s.564 1.397 1.5 1.652zm1.5 5.001v3.304c.936-.255 1.5-.974 1.5-1.652s-.564-1.397-1.5-1.652">
                                                </path>
                                                <path fill="currentColor" fill-rule="evenodd"
                                                    d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2s10 4.477 10 10M12 5.25a.75.75 0 0 1 .75.75v.317c1.63.292 3 1.517 3 3.183a.75.75 0 0 1-1.5 0c0-.678-.564-1.397-1.5-1.653v3.47c1.63.292 3 1.517 3 3.183s-1.37 2.891-3 3.183V18a.75.75 0 0 1-1.5 0v-.317c-1.63-.292-3-1.517-3-3.183a.75.75 0 0 1 1.5 0c0 .678.564 1.397 1.5 1.652v-3.469c-1.63-.292-3-1.517-3-3.183s1.37-2.891 3-3.183V6a.75.75 0 0 1 .75-.75"
                                                    clip-rule="evenodd"></path>
                                            </svg>
                                        </div>
                                        <div style="font-size:12px; font-weight:600; color:white; margin-left: 3px">${formatCurrency(d.data.cost, workspace?.currency, lang, true, 1)}</div>
                                    </div>

                                    <!-- KPI 2 -->
                                    <div
                                        title="Clicks on ${periodOptions.find(option => option.value === sortPeriod)?.label} (${d.data.clicks || 0})"
                                        style="text-align:center; background-color: rgba(0, 0, 0, 0.2); padding: 3px; border-radius: 5px;display: flex; flex-direction: row">
                                        <div>
                                            <svg class="iconify iconify--solar mnl__icon__root MuiBox-root css-9h0d0y"
                                                xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 48 48">
                                                <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-width="4">
                                                    <path d="M24 4v8"></path>
                                                    <path fill="currentColor" fill-rule="evenodd" d="m22 22l20 4l-6 4l6 6l-6 6l-6-6l-4 6z"
                                                        clip-rule="evenodd"></path>
                                                    <path
                                                        d="m38.142 9.858l-5.657 5.657M9.858 38.142l5.657-5.657M4 24h8M9.858 9.858l5.657 5.657">
                                                    </path>
                                                </g>
                                            </svg>
                                        </div>
                                        <div style="font-size:12px; font-weight:600; color:white; margin-left: 3px">${d.data.clicks || 0}
                                        </div>
                                    </div>

                                    <!-- KPI 3 -->
                                    <div
                                        title="Impressions on ${periodOptions.find(option => option.value === sortPeriod)?.label} (${d.data.impressions || 0})"
                                        style="text-align:center; background-color: rgba(0, 0, 0, 0.2); padding: 3px; border-radius: 5px; display: flex; flex-direction: row">
                                        <div style="position: relative; top: 1px">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M9.75 12a2.25 2.25 0 1 1 4.5 0a2.25 2.25 0 0 1-4.5 0"></path>
                                                <path fill="currentColor" fill-rule="evenodd"
                                                    d="M2 12c0 1.64.425 2.191 1.275 3.296C4.972 17.5 7.818 20 12 20s7.028-2.5 8.725-4.704C21.575 14.192 22 13.639 22 12c0-1.64-.425-2.191-1.275-3.296C19.028 6.5 16.182 4 12 4S4.972 6.5 3.275 8.704C2.425 9.81 2 10.361 2 12m10-3.75a3.75 3.75 0 1 0 0 7.5a3.75 3.75 0 0 0 0-7.5"
                                                    clip-rule="evenodd"></path>
                                            </svg>
                                        </div>
                                        <div style="font-size:12px; font-weight:600; color:white; margin-left: 3px">${d.data.impressions ||
                        0}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                `;

                })
                .container(chartContainerRef.current)
                .data(transformedData)
                .render();


            // Fonction pour parcourir récursivement tous les nœuds et les développer
            const traverseAndExpand = (nodes: any) => {
                nodes.forEach((node: any) => {
                    chartRef.current.setExpanded(node.id, true);
                    if (node.children && node.children.length > 0) {
                        traverseAndExpand(node.children);
                    }
                });
            };

            if (searchCampaign?.length !== 0 || searchAdGroup?.length !== 0 || searchKeyword?.length !== 0 || searchSearchTerm?.length !== 0)
                traverseAndExpand(transformedData);

            // Re-render le graphique après avoir développé tous les nœuds
            chartRef.current.render();
        }
    }, [workspace, campaignExplorerData, searchAdGroup, searchCampaign]);


    const horizontalView = () => {
        chartRef.current.compact(false).render().fit()
    }

    const compactView = () => {
        chartRef.current.compact(true).render().fit()
        chartRef.current.zoomIn()
    }

    const [filters, setFilters] = useState<Omit<Props, 'open'> & { open: boolean }>({
        open: false,
        favorited: false,
    });


    const toggleDrawer = () => {
        setFilters((prev: Props) => ({ ...prev, open: !prev.open, favorited: prev.favorited ?? false }));
    };

    const handleClose = () => {
        setFilters((prev: Props) => ({ ...prev, open: false }));
    };

    const handleReset = () => {
        handleClearSearchAdGroup()
        handleClearSearchCampaign()
        handleClearSearchKeyword()
        handleClearSearchSearchTerm()
    };

    const handleBlurAdGroup = (e: any) => {
        setSearchAdGroup(e.target.value)
    };

    const handleKeyDownAdGroup = (e: any) => {
        if (e.key === 'Enter') {
            setSearchAdGroup(e.target.value)
        }
    };

    const handleBlurKeyword = (e: any) => {
        setSearchKeyword(e.target.value)
    };

    const handleKeyDownKeyword = (e: any) => {
        if (e.key === 'Enter') {
            setSearchKeyword(e.target.value)
        }
    };

    const handleBlurSearchTerm = (e: any) => {
        setSearchSearchTerm(e?.target?.value)
    }

    const handleKeyDownSearchTerm = (e: any) => {
        if (e.key === 'Enter') {
            setSearchSearchTerm(e.target.value)
        }
    };

    const highlightText = (text: string, searchAdGroup: string, searchCampaign: string, searchKeyword: string, searchSearchTerm: string, itemType: string) => {
        if (!text) return text;

        let regex;
        let highlightColor = "";

        // Si un terme de recherche pour les Ad Groups est défini, on l'applique sur les Ad Groups
        if (searchAdGroup && itemType === "Ad Group") {
            regex = new RegExp(`(${searchAdGroup})`, 'gi');
            highlightColor = "#7635dc";  // Couleur pour Ad Group
        }

        // Si un terme de recherche pour les Campaigns est défini, on l'applique sur les Campaigns
        if (searchCampaign && itemType === "Campaign") {
            regex = new RegExp(`(${searchCampaign})`, 'gi');
            highlightColor = "#ff9800";  // Couleur pour Campaign
        }

        // Si un terme de recherche pour les Keywords est défini, on l'applique sur les Keywords
        if (searchKeyword && itemType === "Keyword") {
            regex = new RegExp(`(${searchKeyword})`, 'gi');
            highlightColor = "red";  // Couleur pour Campaign
        }

        // Si un terme de recherche pour les Search Term est défini, on l'applique sur les Search Term
        if (searchSearchTerm && itemType === "Search Term") {
            regex = new RegExp(`(${searchSearchTerm})`, 'gi');
            highlightColor = "green";  // Couleur pour Campaign
        }


        // Si un regex a été défini, on applique le surlignage
        if (regex) {
            return text.replace(regex, `<span style="background-color: ${highlightColor}; font-weight: bold; padding: 4px; border-radius: 10px">$1</span>`);
        }

        return text;  // Retourne le texte original si aucun filtre n'est appliqué
    };

    const handleClearAllFilter = () => {
        handleClearSearchAdGroup()
        handleClearSearchCampaign()
        handleClearSearchKeyword()
        handleClearSearchSearchTerm()
    }

    const handleClearSearchAdGroup = () => {
        setSearchAdGroup('')
        setSearchAdGroupValue('')
    }

    const handleClearSearchCampaign = () => {
        setSearchCampaign('')
        setSearchCampaignValue('')
    }

    const handleClearSearchSearchTerm = () => {
        setSearchSearchTerm('')
        setSearchSearchTermValue('')
    }

    const handleClearSearchKeyword = () => {
        setSearchKeyword('')
        setSearchKeywordValue('')
    }

    const handleBlurCampaign = (e: any) => {
        setSearchCampaign(e.target.value);
    };

    const handleKeyDownCampaign = (e: any) => {
        if (e.key === 'Enter') {
            setSearchCampaign(e.target.value);
        }
    };

    const periodOptions = [
        { value: 'l7d', label: 'Last 7 Days' },
        { value: 'l14d', label: 'Last 14 Days' },
        { value: 'l30d', label: 'Last 30 Days' },
        { value: 'l90d', label: 'Last 90 Days' },
        { value: 'currentyear', label: 'Current Year' },
    ];
    

    const popoverPeriod: any = {
        open: Boolean(anchorElPeriod),
        anchorEl: anchorElPeriod,
        onOpen: (event: any) => {
            setAnchorElPeriod(event.currentTarget);
        },
        onClose: () => {
            setAnchorElPeriod(null);
        },
    };

    const handlePeriodChange = (newValue: any) => {
        setSortPeriod(newValue);
    };

    return (
        <FilterDrawerContext.Provider value={{
            open: filters.open,
            toggleDrawer,
            onClose: handleClose,
            onReset: handleReset
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box sx={{ mt: 1 }}>

                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>

                        {searchCampaign &&
                            <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Campaign:</Typography>
                                <Chip
                                    variant='soft'
                                    size='small'
                                    label={searchCampaign}
                                    sx={{ position: 'relative', ml: 1 }}
                                    onDelete={() => handleClearSearchCampaign()}
                                />
                            </Box>
                        }

                        {searchAdGroup &&
                            <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Ad Group:</Typography>
                                <Chip
                                    variant='soft'
                                    size='small'
                                    label={searchAdGroup}
                                    sx={{ position: 'relative', ml: 1 }}
                                    onDelete={() => handleClearSearchAdGroup()}
                                />
                            </Box>
                        }

                        {searchKeyword &&
                            <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Keyword:</Typography>
                                <Chip
                                    variant='soft'
                                    size='small'
                                    label={searchKeyword}
                                    sx={{ position: 'relative', ml: 1 }}
                                    onDelete={() => handleClearSearchKeyword()}
                                />
                            </Box>
                        }

                        {searchSearchTerm &&
                            <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Search Term:</Typography>
                                <Chip
                                    variant='soft'
                                    size='small'
                                    label={searchSearchTerm}
                                    sx={{ position: 'relative', ml: 1 }}
                                    onDelete={() => handleClearSearchSearchTerm()}
                                />
                            </Box>
                        }

                        {(searchAdGroup || searchCampaign || searchKeyword || searchSearchTerm) &&
                            <Box>
                                <Button
                                    onClick={handleClearAllFilter}
                                    startIcon={<Iconify icon="eva:trash-2-outline" />}
                                    sx={{ color: 'error.main', fontWeight: 'bold', textTransform: 'none', mt: .5 }}
                                >
                                    Clear
                                </Button>
                            </Box>
                        }
                    </Box>
                </Box>
                <Box sx={{ mt: 1.8, display: 'flex', flexDirection: 'row' }}>
                    <div style={{marginTop: 1}}>
                        <Button size="small" variant="outlined" onClick={horizontalView}>Horizontal View</Button>
                        <Button size="small" sx={{ ml: 1 }} variant="outlined" onClick={compactView}>Compact View</Button>
                    </div>
                    

                    <Box>
                        <Button
                            disableRipple
                            color="inherit"
                            onClick={(event) => popoverPeriod.onOpen(event)}
                            endIcon={
                                <Iconify
                                    icon={
                                        popoverPeriod.open
                                            ? 'eva:arrow-ios-upward-fill'
                                            : 'eva:arrow-ios-downward-fill'
                                    }
                                />
                            }
                            sx={{ fontWeight: 'fontWeightSemiBold' }}
                        >
                            Period:
                            <Box
                                component="span"
                                sx={{
                                    ml: 0.5,
                                    fontWeight: 'fontWeightBold',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {periodOptions.find((option) => option.value === sortPeriod)?.label}
                            </Box>
                        </Button>

                        <CustomPopover
                            open={popoverPeriod.open}
                            anchorEl={popoverPeriod.anchorEl}
                            onClose={popoverPeriod.onClose}
                        >
                            <MenuList>
                                {periodOptions.map((option: any) => (
                                    <MenuItem
                                        key={option.value}
                                        selected={option.value === sortPeriod}
                                        onClick={() => {
                                            popoverPeriod.onClose();
                                            handlePeriodChange(option.value);
                                        }}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </CustomPopover>
                    </Box>

                    <Button sx={{ ml: 1 }} onClick={toggleDrawer}>
                        {t('dashboard.global.filters')}
                        <Badge
                            color="error"
                            variant="dot"
                            invisible={
                                !(
                                    searchAdGroup ||
                                    searchKeyword ||
                                    searchCampaign ||
                                    searchSearchTerm
                                )
                            }
                            sx={{
                                '& .MuiBadge-dot': {
                                    zIndex: 1,
                                },
                            }}
                        >
                            <Iconify sx={{ ml: 0.5 }} icon={'mynaui:filter'} />
                        </Badge>
                    </Button>
                </Box>


            </Box>

            <Paper
                sx={{
                    backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                    boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                    borderRadius: 2,
                    marginTop: 3,
                    marginBottom: 1
                }}
            >
                <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25, marginBottom: 40 }}>
                    <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>Campaigns Structure</Typography>

                    <Typography variant="h6" sx={{ mb: 1 }}>
                        Brand Campaigns Explorer
                    </Typography>
                </div>

                {isFetching
                    ? <Skeleton
                        width={'100%'}
                        height={'750px'}
                        style={{ borderRadius: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, marginTop: '15px' }}
                        animation="wave"
                    >
                        <>
                            <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
                            <Typography color="success.main">Active</Typography>
                        </>
                    </Skeleton>
                    : <Box
                        ref={chartContainerRef}
                        className="chart-container"
                        style={{ borderRadius: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, background: '#28323c', marginTop: '15px' }}
                    />
                }

                <Drawer
                    anchor="right"
                    open={filters.open}
                    onClose={handleClose}
                    slotProps={{
                        backdrop: { invisible: true },
                    }}
                    sx={{
                        [`& .${drawerClasses.paper}`]: {
                            ...paper({ theme, bgcolor: theme.palette.background.default }),
                            width: 320,
                        },
                    }}
                >
                    <Box sx={{ overflowX: 'hidden' }}>
                        <Stack spacing={3} sx={{ width: '100%' }}>

                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ px: 2, pt: 2 }}
                            >
                                <Typography variant="h6">{t('dashboard.textAdsPage.filters')}</Typography>
                                <Box>
                                    <IconButton onClick={handleReset}>
                                        <Badge
                                            color="error"
                                            variant="dot"
                                            invisible={
                                                !(
                                                    searchAdGroup ||
                                                    searchCampaign ||
                                                    searchKeyword ||
                                                    searchSearchTerm
                                                )
                                            }
                                            sx={{
                                                '& .MuiBadge-dot': {
                                                    zIndex: 1,
                                                },
                                            }}
                                        >
                                            <Iconify icon="solar:restart-bold" />
                                        </Badge>
                                    </IconButton>

                                    <IconButton onClick={handleClose}>
                                        <Iconify icon="mingcute:close-line" />
                                    </IconButton>
                                </Box>
                            </Stack>

                            <Divider
                                sx={{
                                    borderColor: 'rgba(145, 158, 171, 0.2)', // Gris plus foncé
                                    borderWidth: '1px', // Ligne plus épaisse
                                    mb: 1
                                }}
                                variant="fullWidth"
                            />

                            <Box sx={{ px: 2 }}>
                                <Box>
                                    <Typography>Campaign</Typography>
                                    <TextField
                                        label="Campaign"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={searchCampaignValue}
                                        onChange={(e) => setSearchCampaignValue(e.target.value)}
                                        onBlur={handleBlurCampaign}
                                        onKeyDown={handleKeyDownCampaign}
                                        sx={{
                                            backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                            borderRadius: 1,
                                            marginTop: 2,
                                        }}
                                    />
                                </Box>

                                <Box sx={{ mt: 4 }}>
                                    <Typography>Ad Group</Typography>
                                    <TextField
                                        label="Ad Group"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={searchAdGroupValue}
                                        onChange={(e) => setSearchAdGroupValue(e.target.value)}
                                        onBlur={handleBlurAdGroup}
                                        onKeyDown={handleKeyDownAdGroup}
                                        sx={{
                                            backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                            borderRadius: 1,
                                            marginTop: 2,
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {searchAdGroupValue && (
                                                        <IconButton
                                                            aria-label="clear search term"
                                                            onClick={() => { setSearchAdGroupValue(''); setSearchAdGroup('') }}
                                                            edge="end"
                                                        >
                                                            <ClearIcon sx={{ width: 15 }} />
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>

                                <Box sx={{ mt: 4 }}>
                                    <Typography>Keyword</Typography>
                                    <TextField
                                        label="Keyword"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={searchKeywordValue}
                                        onChange={(e) => setSearchKeywordValue(e.target.value)}
                                        onBlur={handleBlurKeyword}
                                        onKeyDown={handleKeyDownKeyword}
                                        sx={{
                                            backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                            borderRadius: 1,
                                            marginTop: 2,
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {searchKeywordValue && (
                                                        <IconButton
                                                            aria-label="clear search term"
                                                            onClick={() => { setSearchKeywordValue(''); setSearchKeyword('') }}
                                                            edge="end"
                                                        >
                                                            <ClearIcon sx={{ width: 15 }} />
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>

                                <Box sx={{ mt: 4 }}>
                                    <Typography>Search Term</Typography>
                                    <TextField
                                        label="Search Term"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={searchSearchTermValue}
                                        onChange={(e) => setSearchSearchTermValue(e.target.value)}
                                        onBlur={handleBlurSearchTerm}
                                        onKeyDown={handleKeyDownSearchTerm}
                                        sx={{
                                            backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                            borderRadius: 1,
                                            marginTop: 2,
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {searchSearchTermValue && (
                                                        <IconButton
                                                            aria-label="clear search term"
                                                            onClick={() => { setSearchSearchTermValue(''); setSearchSearchTerm('') }}
                                                            edge="end"
                                                        >
                                                            <ClearIcon sx={{ width: 15 }} />
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                </Box>
                            </Box>

                        </Stack>
                    </Box>
                </Drawer>
            </Paper>
        </FilterDrawerContext.Provider>
    )
}