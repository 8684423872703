import { useState, useEffect, useCallback, useRef } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowModel,
  GridToolbar,
  useGridApiContext,
  GridRenderCellParams,
  getGridStringOperators,
  GridFilterItem,
  GridFilterOperator,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { Autocomplete, Box, Chip, IconButton, Popover, TextField, Tooltip, Typography } from '@mui/material';
import Iconify from '../iconify';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import React from 'react';
import { useCheckKoBiddingExistsMutation, useGetAdGroupKoBiddingQuery, useGetCampaignsQuery, useGetCountriesKoBiddingQuery, useGetKeywordInfoKoBiddingQuery, useGetKeywordsKoBiddingQuery, useGetMatchTypeKoBiddingQuery, useGetProfileKoBiddingQuery } from 'src/context/api/liveBidding/api';
import { formatUpdatedAt } from 'src/utils/dates-labels';
import { useLocales } from 'src/locales';

// Typing for country and other data structures
type Country = {
  country_code: string;
  country_name: string;
};

type DataRow = {
  id: number;
  keyword: string;
  country: Country;
  frequency: string;
  device: string[];
  status?: boolean;
  isDeleted?: boolean;
  isDuplicate?: boolean; // Added to indicate duplicates
};

const PlatformEditInputCell = ({ id, field, value }: GridRenderCellParams) => {
  const apiRef = useGridApiContext();

  const handleChange = useCallback(
    (event: any, newValue: string | null) => {
      if (newValue) {
        apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
        apiRef.current.stopCellEditMode({ id, field });
      }
    },
    [apiRef, id, field]
  );

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        options={["SA360", "Google Ads"]}
        value={value || ""}
        onChange={handleChange}
        disableClearable
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder="Select platform" size="small" />
        )}
      />
    </Box>
  );
};

const AccountEditInputCell = ({ id, field, row, value, profileKoBiddingOptionsRef }: any) => {
  const apiRef = useGridApiContext();
  const workspace: any = useCurrentWorkspace();

  const keyword = row.keyword;
  const match_type = row.match_type;


  // Récupérer les options de profils via l'API pour le compte
  const { data: profileKoBiddingOptions, isLoading: isLoadingProfileKoBidding } = useGetProfileKoBiddingQuery({
    client: workspace?.bqTable,
  });

  // Mettre à jour la référence lorsque profileKoBiddingOptions change
  useEffect(() => {
    if (profileKoBiddingOptions) {
      profileKoBiddingOptionsRef.current = profileKoBiddingOptions; // Met à jour la référence
    }
  }, [profileKoBiddingOptions]);

  const handleChange = useCallback(
    (event: any, newValue: any) => {
      if (newValue) {
        apiRef.current.setEditCellValue({ id, field: 'account', value: newValue.profile }, event);
        apiRef.current.stopCellEditMode({ id, field });
      }
    },
    [apiRef, id, field]
  );

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        options={profileKoBiddingOptions || []}  // Options provenant de l'API
        loading={isLoadingProfileKoBidding}
        getOptionLabel={(option) => option.profile || ''}  // Utiliser la clé `profile` pour l'affichage
        value={profileKoBiddingOptions?.find((option: any) => option.profile === value) || null}  // Trouver la valeur correspondante
        onChange={handleChange}
        disableClearable
        size='small'
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder="Select account" size="small" />
        )}
      />
    </Box>
  );
};

const CampaignEditInputCell = ({ id, value, field, api, row, campaignOptionsRef }: any) => {
  const workspace: any = useCurrentWorkspace();

  const match_type = row.match_type;


  // Récupérer les options de campagnes en fonction du keyword et du profil
  const { data: campaignOptions, isLoading: isLoadingCampaigns } = useGetCampaignsQuery({
    client: workspace?.bqTable,
    keyword: row.keyword,
    profile: row.account,
    match_type: match_type ? match_type.toLowerCase().charAt(0).toUpperCase() + match_type.slice(1).toLowerCase() : ''
  });

  useEffect(() => {
    if (campaignOptions) {
      campaignOptionsRef.current = campaignOptions;  // Mettre à jour la référence des campagnes
    }
  }, [campaignOptions]);

  const handleCampaignChange = (event: any, newValue: any) => {
    // Convertir la nouvelle valeur en tableau même si elle est unique
    const selectedCampaign = newValue ? [newValue.campaign_name] : [];
    api.setEditCellValue({ id, field: 'campaign_name', value: selectedCampaign }); // Stocker en tableau
    api.stopCellEditMode({ id, field });
  };

  // Trouver la campagne sélectionnée pour l'affichage (prend la première valeur du tableau)
  const selectedCampaign = campaignOptions?.find((opt: any) => opt.campaign_name === value?.[0]) || null;

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        disableClearable
        options={campaignOptions || []}
        getOptionLabel={(option) => option.campaign_name || ''}
        value={selectedCampaign}
        onChange={handleCampaignChange}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        size="small"
        isOptionEqualToValue={(option, value) => option.campaign_id === value?.campaign_id}
      />
    </Box>
  );
};

const KeywordEditInputCell = ({ id, field, value }: GridRenderCellParams) => {
  const apiRef = useGridApiContext();
  const workspace: any = useCurrentWorkspace();

  // Récupérer les options de mots-clés via l'API
  const { data: keywordKoBiddingOptions, isLoading: isLoadingKoBiddingKeywords } = useGetKeywordsKoBiddingQuery({
    client: workspace?.bqTable,
  });

  const handleChange = useCallback(
    (event: any, newValue: any) => {
      if (newValue) {
        // Mettez à jour le keyword_id et le keyword_bid (si disponibles)
        apiRef.current.setEditCellValue({ id, field: 'keyword', value: newValue.keyword_bid }, event);
        apiRef.current.stopCellEditMode({ id, field });
      }
    },
    [apiRef, id, field]
  );

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        options={keywordKoBiddingOptions || []}  // S'assurer que l'option est toujours un tableau
        loading={isLoadingKoBiddingKeywords}
        getOptionLabel={(option) => option.keyword_bid || ''} // Assure une string pour l'affichage
        isOptionEqualToValue={(option, value) => option.keyword_bid === value} // Comparer correctement les objets
        value={
          keywordKoBiddingOptions?.find((option: any) => option.keyword_bid === value) || null
        } // Trouver la valeur correspondante ou null si elle n'existe pas
        onChange={handleChange}
        size='small'
        disableClearable
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder="Select keyword" size="small" />
        )}
      />
    </Box>
  );
};

const MatchTypeEditInputCell = ({ id, field, row, value }: GridRenderCellParams) => {
  const apiRef = useGridApiContext();
  const workspace: any = useCurrentWorkspace();

  const keyword = row.keyword;

  // Récupérer les options de match type via l'API
  const { data: matchTypeKoBiddingOptions, isLoading: isLoadingMatchTypeKoBidding } = useGetMatchTypeKoBiddingQuery({
    client: workspace?.bqTable,
    keyword: keyword,
  });

  const handleChange = useCallback(
    (event: any, newValue: string | null) => {
      if (newValue) {
        apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
        apiRef.current.stopCellEditMode({ id, field });
      }
    },
    [apiRef, id, field]
  );

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        options={matchTypeKoBiddingOptions || []}  // Options provenant de l'API
        loading={isLoadingMatchTypeKoBidding}
        value={value || ""}
        onChange={handleChange}
        disableClearable
        getOptionLabel={(option) => option || ''}  // Assurez-vous que l'option est bien une string
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder="Select match type" size="small" />
        )}
      />
    </Box>
  );
};


const MaxCpcEditInputCell = ({ id, field, value }: any) => {
  const apiRef = useGridApiContext();
  const [error, setError] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string>(value?.toFixed(2) || '');

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setInputValue(newValue); // Update the display value as a string to keep precision
    },
    []
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const newValue = parseFloat(event.target.value);
      if (isNaN(newValue) || newValue < 0.01 || newValue > 30) {
        setError("Max CPC must be between 0.01 and 30");
      } else {
        setError(null);
        apiRef.current.setEditCellValue({ id, field, value: parseFloat(newValue.toFixed(2)) }, event); // Keep three decimal places
        apiRef.current.stopCellEditMode({ id, field });
      }
    },
    [apiRef, id, field]
  );

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <TextField
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!error}
        variant="outlined"
        size="small"
        type="number"
        inputProps={{
          step: "0.01", // Allow decimal values to three places
          min: "0.01",
          max: "30"
        }}
      />
    </Box>
  );
};

const ModeEditInputCell = ({ id, field, value }: GridRenderCellParams) => {
  const apiRef = useGridApiContext();

  const handleChange = useCallback(
    (event: any, newValue: string | null) => {
      if (newValue) {
        apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
        apiRef.current.stopCellEditMode({ id, field });
      }
    },
    [apiRef, id, field]
  );

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        options={["KO Bidding Manual", "KO Bidding Blended CTR", "KO Bidding Ad CTR"]}
        value={value || ""}
        onChange={handleChange}
        disableClearable
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder="Select mode" size="small" />
        )}
      />
    </Box>
  );
};

const AdGroupEditInputCell = ({ id, value, field, api, row, adGroupOptionsRef }: any) => {
  const workspace: any = useCurrentWorkspace();

  // Récupérer les options de groupes d'annonces via l'API
  const { data: adGroupOptionsKoBidding, isLoading: isLoadingAdGroupKoBidding } = useGetAdGroupKoBiddingQuery({
    client: workspace?.bqTable,
    keyword: row.keyword,
    profile: row.account,
    campaign: row.campaign_name,
    match_type: row.match_type
  });

  useEffect(() => {
    if (adGroupOptionsKoBidding) {
      adGroupOptionsRef.current = adGroupOptionsKoBidding;  // Mettre à jour la référence des groupes d'annonces
    }
  }, [adGroupOptionsKoBidding]);

  const handleAdGroupChange = (event: any, newValue: any) => {
    // Mettre à jour la valeur des ad_group_name et ad_group_id
    api.setEditCellValue({ id, field: 'ad_group_name', value: newValue?.ad_group });

    api.stopCellEditMode({ id, field });
  };

  const selectedAdGroup = adGroupOptionsKoBidding?.find(
    (opt: any) => opt.ad_group_name === value
  ) || null;

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        options={adGroupOptionsKoBidding || []}
        getOptionLabel={(option) => option.ad_group || ''}
        value={selectedAdGroup}
        onChange={handleAdGroupChange}
        renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select ad group" />}
        size="small"
        isOptionEqualToValue={(option, value) => option.ad_group_id === value?.ad_group_id}
      />
    </Box>
  );
};

const KeywordCell = (params: GridRenderCellParams) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const isDuplicate = params.row.isDuplicate;

  const keyword = params.row.keyword;
  const account = params.row.account;
  const campaign = params.row.campaign_name;
  const ad_group = params.row.ad_group_name;
  const match_type = params.row.match_type;

  return (
    <>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Chip
          size="small"
          label={
            params.row.isDuplicate
              ? `🚫 ${params.row.keyword}`
              : params.row.keyword
          }
          style={{ margin: 2 }}
        />

      </div>
      {isDuplicate && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
            width: 700,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box sx={{ p: 1 }}>
            <Typography>Unable to add the following keyword: {keyword}.</Typography>
            <Typography fontSize={14} sx={{ mt: 1 }}>The keyword '{keyword}' has already been configured for the account '{account}', campaign '{campaign}', ad group '{ad_group}', match type '{match_type}'.</Typography>
          </Box>
        </Popover>
      )}
    </>
  );
};

const defaultStringOperators = getGridStringOperators();

// Clone default string operators
const operators = defaultStringOperators.filter(
  (operator) => operator.value !== 'isEmpty' && operator.value !== 'isNotEmpty'
);

// Ajoutez vos opérateurs personnalisés si nécessaire
const customStringOperators = [
  ...operators,
  // Ajoutez ici vos opérateurs personnalisés si vous en avez
];

// Create columns
const createColumns = (
  deleteItem: (id: number | string) => void,
  resetItem: (id: number | string) => void,
  editedRows: Map<number | string, Partial<GridRowModel>>,
  profileKoBiddingOptionsRef: React.RefObject<any>,
  rows: any,
  currentLang: any,
  campaignOptionsRef: React.RefObject<any>,
  adGroupOptionsRef: React.RefObject<any>
): GridColDef[] => [
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params: GridRenderCellParams) => (
        <ActionMenu params={params} editedRow={editedRows.has(params.id)} deleteItem={deleteItem} resetItem={resetItem} />
      ),
    },
    {
      field: 'keyword',
      headerName: 'Keyword',
      filterOperators: customStringOperators,
      flex: 1,
      editable: true,
      type: 'singleSelect',
      renderCell: (params: GridRenderCellParams) => <KeywordCell {...params} />,
      renderEditCell: (params: GridRenderCellParams) => <KeywordEditInputCell {...params} />,
    },
    {
      field: 'match_type',
      headerName: 'Match Type',
      flex: 1,
      valueOptions: Array.from(new Set(rows.map((row: any) => row.match_type))), // Dynamically fetch unique values
      editable: true,
      type: 'singleSelect',
      renderEditCell: (params: GridRenderCellParams) => <MatchTypeEditInputCell {...params} />,  // Utilisation du nouveau composant
    },
    {
      field: 'account',
      headerName: 'Account',
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueOptions: Array.from(new Set(rows.map((row: any) => row.account))), // Dynamically fetch unique values
      renderEditCell: (params: GridRenderCellParams) => <AccountEditInputCell profileKoBiddingOptionsRef={profileKoBiddingOptionsRef} {...params} />,  // Utilisation du nouveau composant
    },
    {
      field: 'campaign_name',
      headerName: 'Campaign',
      flex: 1,
      editable: true,
      valueGetter: (params) => params[0],
      valueOptions: Array.from(new Set(rows.map((row: any) => row.campaign_name[0]))), // Dynamically fetch unique values
      type: 'singleSelect',
      renderEditCell: (params: GridRenderCellParams) => <CampaignEditInputCell campaignOptionsRef={campaignOptionsRef} {...params} />,
    },
    {
      field: 'ad_group_name',
      headerName: 'Ad Group',
      flex: 1,
      valueOptions: Array.from(new Set(rows.map((row: any) => row.ad_group_name))), // Dynamically fetch unique values
      editable: true,
      type: 'singleSelect',
      renderEditCell: (params: GridRenderCellParams) => <AdGroupEditInputCell adGroupOptionsRef={adGroupOptionsRef} {...params} />,
    },
    {
      field: 'mode',
      headerName: 'Mode',
      flex: 1,
      editable: true,
      valueOptions: Array.from(new Set(rows.map((row: any) => row.mode))), // Dynamically fetch unique values
      type: 'singleSelect',
      renderEditCell: (params: GridRenderCellParams) => <ModeEditInputCell {...params} />,
    },
    {
      field: 'bidding_strategy_type',
      type: 'singleSelect',
      editable: false,
      valueOptions: Array.from(new Set(rows.map((row: any) => row.bidding_strategy_type))), // Dynamically fetch unique values
      headerName: 'Bid Strategy Type',
      flex: 1,
    },
    {
      field: 'country',
      headerName: 'Country',
      editable: false,
      flex: 1,
      type: 'singleSelect',
      valueGetter: (params) => params[0],
      valueOptions: Array.from(new Set(rows.map((row: any) => row.country[0]))), // Dynamically fetch unique values
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const countriesList = Array.isArray(params.row.country)
          ? params.row.country.join(', ')
          : params.row.country;

        return (
          <Box display="flex" alignItems="center">
            {countriesList}
          </Box>
        );
      },
    },
    {
      field: 'platform',
      valueOptions: Array.from(new Set(rows.map((row: any) => row.platform))), // Dynamically fetch unique values
      headerName: 'Platform',
      flex: 1,
      editable: true,
      type: 'singleSelect',
      renderEditCell: (params: GridRenderCellParams) => <PlatformEditInputCell {...params} />,
    },
    {
      field: 'max_cpc',
      headerName: 'Max CPC',
      flex: 1,
      editable: true,
      type: 'number',
      renderEditCell: (params: GridRenderCellParams) => <MaxCpcEditInputCell {...params} />,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      renderCell: (params: GridRenderCellParams) => (
        <span>
          {formatUpdatedAt(params?.row?.created_at, currentLang?.value)}
        </span>
      ),
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: true,
      type: 'boolean',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.value ? `Active - Edited on ${formatUpdatedAt(params.row.updated_at, currentLang?.value)}` : `Inactive - Edited on ${formatUpdatedAt(params.row.updated_at, currentLang?.value)}`} arrow>
          <Box display="flex" alignItems="center" justifyContent="center">
            {params.value ? (
              <Iconify icon={"material-symbols:check"} />
            ) : (
              <Iconify icon={"mdi:times"} />
            )}
          </Box>
        </Tooltip>
      ),
      flex: 1,
    },
  ];

// Action menu component
type ActionMenuProps = {
  params: GridRenderCellParams;
  editedRow: boolean;
  deleteItem: (id: number | string) => void;
  resetItem: (id: number | string) => void;
};

const ActionMenu = ({ params, deleteItem, resetItem, editedRow }: ActionMenuProps) => {
  const handleDelete = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    deleteItem(params.row.id);
  };

  const handleReset = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    resetItem(params.row.id);
  };

  return (
    <div>
      <IconButton
        onClick={handleReset}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
        disabled={!editedRow}
      >
        <Iconify sx={{ opacity: !editedRow ? 0.2 : 1 }} icon="material-symbols:refresh" />
      </IconButton>

      <IconButton
        onClick={handleDelete}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
      >
        <Iconify icon="solar:trash-bin-trash-bold" />
      </IconButton>
    </div>
  );
};

type Props = {
  data: DataRow[];
  pageSize: number;
  deleteItem?: (ids: (number | string)[]) => void;
  handleSave: (editedRows: { id: string | number; changes: Partial<GridRowModel> }[]) => void;
  onEdit: (hasEditedRows: boolean) => void;
  onApply: boolean;
  onReset: boolean;
};

export default function DataGridCustomLiveBiddingBasedKoBidding({
  data,
  pageSize,
  deleteItem,
  handleSave,
  onEdit,
  onApply,
  onReset,
}: Props) {

  const profileKoBiddingOptionsRef = useRef([]);
  const campaignOptionsRef = useRef([]);
  const adGroupOptionsRef = useRef([]);

  const [rows, setRows]: any = useState<DataRow[]>(data);
  const [initialRows] = useState<Map<number | string, DataRow>>(new Map(data.map(row => [row.id, { ...row }])));
  const [selectionModel, setSelectionModel] = useState<(number | string)[]>([]);
  const [editedRows, setEditedRows] = useState<Map<number | string, Partial<GridRowModel>>>(new Map());
  const [deletedRowIds, setDeletedRowIds] = useState<(number | string)[]>([]);
  const { currentLang, t } = useLocales();

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: true,
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: pageSize,
    page: 0,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const handlePaginationModelChange = useCallback((newModel: any) => {
    setPaginationModel(newModel);
  }, []);

  const [selectedCampaign, setSelectedCampaign]: any = useState<{ campaign_name: string, rowId: number | string } | null>(null);

  const [koBiddingSearchExist] = useCheckKoBiddingExistsMutation(); // Initialisez la mutation

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      const initialRow: any = initialRows.get(newRow.id);

      // Vérifier si des changements réels ont été effectués
      const isModified = Object.keys(newRow).some((key) => newRow[key] !== initialRow?.[key]);

      if (!isModified) {
        // Si aucun changement réel, retourner l'ancienne ligne sans vérifier les duplications
        return oldRow;
      }

      // Conditions de mise à jour des champs en fonction des changements détectés
      const keywordChanged = newRow.keyword !== oldRow.keyword;
      const accountChanged = newRow.account !== oldRow.account;
      const campaignChanged = newRow.campaign_name !== oldRow.campaign_name;
      const adGroupChanged = newRow.ad_group_name !== oldRow.ad_group_name;
      let isDuplicate = false;
      let isDeleted = false;

      // Réinitialisation des champs selon les changements détectés
      if (keywordChanged) {
        newRow.campaign_name = [];
        newRow.campaign_id = [];
        newRow.account = '';
        newRow.account_id = '';
        newRow.country = [];
        newRow.country_code = [];
        newRow.match_type = '';
        newRow.ad_group_name = '';
        newRow.ad_group_id = '';
      }

      if (accountChanged) {
        newRow.country = [];
        newRow.country_code = [];
        newRow.ad_group_name = '';
        newRow.ad_group_id = '';

        const selectedAccount: any = profileKoBiddingOptionsRef.current.find(
          (opt: any) => opt.profile === newRow.account
        );
        newRow.account_id = selectedAccount ? selectedAccount.profile_id : '';
      }

      if (campaignChanged) {
        newRow.country = [];
        newRow.country_code = [];
        newRow.ad_group_name = '';
        newRow.ad_group_id = '';
        setSelectedCampaign({ campaign_name: newRow.campaign_name, rowId: newRow.id });

        newRow.campaign_id = [selectedCampaign?.campaign_id];
      }

      if (adGroupChanged) {
        const selectedAdGroup: any = adGroupOptionsRef?.current?.find(
          (opt: any) => opt.ad_group_name === newRow.ad_group_name
        );
        newRow.ad_group_id = selectedAdGroup ? selectedAdGroup.ad_group_id : '';
      }

      // Vérification des duplications
      const localDuplicate = rows.some(
        (row: any) =>
          row.id !== newRow.id && // Exclure la ligne actuelle
          row.keyword === newRow.keyword &&
          row.account === newRow.account &&
          row.campaign_name === newRow.campaign_name &&
          row.ad_group_name === newRow.ad_group_name &&
          row.match_type === newRow.match_type
      );

      if (localDuplicate) {
        isDuplicate = true;
      } else {
        try {
          const response: any = await koBiddingSearchExist({
            keywords: newRow.keyword,
            profile: newRow.account,
            campaign: newRow.campaign_name,
            match_type: newRow.match_type,
            ad_group: newRow.ad_group_name,
            workspaceId: workspace?.id,
          });

          isDuplicate = response?.data?.exists || false;
          isDeleted = response?.data?.exists || false;
        } catch (error) {
          console.warn("Erreur lors de la vérification des duplications :", error);
          isDuplicate = true; // Considérer comme doublon en cas d'erreur
        }
      }

      const updatedRow = { ...newRow, isDuplicate, isDeleted };

      // Mise à jour des lignes et sauvegarde
      setRows((prevRows: any) =>
        prevRows.map((row: any) => (row.id === newRow.id ? updatedRow : row))
      );

      if (isModified || isDuplicate) {
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.set(newRow.id, updatedRow);
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      } else {
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.delete(newRow.id);
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }

      return updatedRow;
    },
    [initialRows, profileKoBiddingOptionsRef, adGroupOptionsRef, rows, koBiddingSearchExist, setRows, setEditedRows, handleSave, onEdit]
  );



  const workspace: any = useCurrentWorkspace()

  // Récupérer keyword_id et keyword_status via getKeywordInfoKoBidding
  const { data: keywordInfo, isLoading, isFetching } = useGetKeywordInfoKoBiddingQuery({
    client: workspace?.bqTable,
    keyword: rows.find((row: any) => row.id === selectedCampaign?.rowId)?.keyword,
    campaign: selectedCampaign?.campaign_name[0],
    profile: rows.find((row: any) => row.id === selectedCampaign?.rowId)?.account,
    match_type: rows.find((row: any) => row.id === selectedCampaign?.rowId)?.match_type?.toLowerCase().replace(/^\w/, (c: string) => c.toUpperCase()),
    ad_group_name: rows.find((row: any) => row.id === selectedCampaign?.rowId)?.ad_group_name
  },
    {
      skip: !selectedCampaign, // L'appel API est ignoré tant qu'il n'y a pas de campagne sélectionnée
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching && keywordInfo && selectedCampaign) {
      // Créer un tableau pour stocker les modifications
      const updatedRows = rows.map((row: any) => {
        if (row.id === selectedCampaign.rowId) {
          // Retourne la ligne mise à jour avec les nouveaux keyword_id et keyword_status
          return {
            ...row,
            keyword_id: keywordInfo[0]?.keyword_id,  // Logique pour keyword_id
            keyword_status: keywordInfo[0]?.keyword_status,  // Logique pour keyword_status
          };
        }
        return row;  // Retourne la ligne inchangée
      });

      // Mettre à jour l'état avec les nouvelles lignes
      setRows(updatedRows);

      // Récupérer les modifications existantes pour la ligne sélectionnée
      const existingChanges = editedRows.get(selectedCampaign.rowId) || {};

      // Fusionner les modifications existantes avec les nouvelles modifications
      const newChanges = {
        ...existingChanges,
        keyword_id: keywordInfo[0]?.keyword_id,
        keyword_status: keywordInfo[0]?.keyword_status,
      };

      // Créer un objet de changement avec l'ID et les nouvelles valeurs
      const changes = {
        id: selectedCampaign.rowId,
        changes: newChanges,
      };

      // Appeler handleSave avec les modifications
      handleSave([changes]);
    }
  }, [keywordInfo, selectedCampaign]);




  // Utilisation du hook avec l'option `skip` pour éviter les appels indésirables
  const { data: countryKoBiddingOptions, isLoading: isLoadingCountriesKoBidding } = useGetCountriesKoBiddingQuery(
    {
      client: workspace?.bqTable,
      keyword: rows.find((row: any) => row.id === selectedCampaign?.rowId)?.keyword,
      campaign: selectedCampaign?.campaign_name[0],
      profile: rows.find((row: any) => row.id === selectedCampaign?.rowId)?.account,
      match_type: rows.find((row: any) => row.id === selectedCampaign?.rowId)?.match_type
        ?.toLowerCase()
        .replace(/^\w/, (c: string) => c.toUpperCase()),

    },
    {
      skip: !selectedCampaign, // L'appel API est ignoré tant qu'il n'y a pas de campagne sélectionnée
    }
  );

  useEffect(() => {
    if (countryKoBiddingOptions && countryKoBiddingOptions.length > 0 && selectedCampaign) {
      setRows((prevRows: any) =>
        prevRows.map((row: any) =>
          row.id === selectedCampaign.rowId
            ? {
              ...row,
              country: countryKoBiddingOptions[0]?.country || [],
              country_code: countryKoBiddingOptions[0]?.country_code || [],
            }
            : row
        )
      );
    }
  }, [countryKoBiddingOptions, selectedCampaign]);  // Ajouter toutes les dépendances nécessaires


  useEffect(() => {
    if (data) {
      setRows((prevRows: any) => {
        // Fusionner les nouvelles données avec l'état local
        const updatedRows = data.map((newRow: DataRow) => {
          const existingRow = prevRows.find((row: any) => row.id === newRow.id);

          if (existingRow) {
            // Conserver l'état local des lignes modifiées ou supprimées
            if (existingRow.isDeleted) {
              return { ...existingRow }; // Conserver toutes les valeurs modifiées de la ligne supprimée
            }
            if (editedRows.has(newRow.id)) {
              return { ...existingRow }; // Conserver toutes les modifications locales
            }
          }

          // Sinon, retourner la nouvelle ligne telle qu'elle est
          return newRow;
        });

        return updatedRows;
      });
    }
  }, [data, editedRows]);

  const handleDeleteItem = useCallback(
    (id: number | string) => {
      // Trouver la ligne à supprimer par ID
      const rowToDelete = rows.find((row: any) => row.id === id);

      if (rowToDelete) {
        // Mettre à jour uniquement la ligne spécifique à supprimer
        const updatedRows = rows.map((row: any) =>
          row.id === id ? { ...row, isDeleted: true } : row
        );

        // Mettre à jour l'état des lignes
        setRows(updatedRows);
        setDeletedRowIds((prev) => [...prev, id]);

        // Mettre à jour les lignes éditées
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.set(id, { ...rowToDelete, isDeleted: true });
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }
    },
    [rows, handleSave, onEdit]
  );

  const handleResetItem = useCallback(
    (id: number | string) => {
      const originalRow = data.find((row) => row.id === id);
      if (originalRow) {
        setRows((prevRows: any) => prevRows.map((row: any) => (row.id === id ? originalRow : row)));
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.delete(id);
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }
    },
    [data, handleSave, onEdit]
  );

  const handleReset = useCallback(() => {
    setRows([...data]);
    setEditedRows(new Map());
    setSelectionModel([]);
    setDeletedRowIds([]);
    onEdit(false);
  }, [data, onEdit]);

  useEffect(() => {
    if (onReset) {
      handleReset();
    }
  }, [onReset, handleReset]);

  useEffect(() => {
    if (!onApply) {
      setEditedRows(new Map());
    }
  }, [onApply]);


  const NoRowsOverlay = () => (
    <Box sx={{ p: 2, textAlign: 'center' }}>
      <Typography variant="h6" color="textSecondary">
        No results found
      </Typography>
    </Box>
  );

  // Get class names for rows and cells
  const getRowClassName = (params: any) => {
    const classes: string[] = [];
    if (params.row.isDeleted) {
      classes.push('deleted');
    } else if (params.row.status === false) {
      classes.push('status-false');
    }
    if (params.row.isDuplicate) {
      classes.push('duplicate'); // Add class for duplicates
    }
    if (editedRows.has(params.id)) {
      classes.push('edited');
    }
    return classes.join(' ');
  };

  const getCellClassName = (params: any) => {
    if (params.row.status === false && ['keyword', 'platform', 'max_cpc', 'status', 'country', "match_type", "account", "campaign_name", "ad_group_name", "mode", "platform"].includes(params.field)) {
      return 'cell-opacity';
    }
    return '';
  };

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-row.edited': {
          backgroundColor: '#666546',
          '&:hover': {
            backgroundColor: '#666546 !important',
          },
        },
        '& .MuiDataGrid-row.deleted': {
          backgroundColor: '#4c3333',
          '&:hover': {
            backgroundColor: '#4c3333 !important',
          },
        },
        '& .MuiDataGrid-row.edited.status-false': {
          backgroundColor: '#666546', /* Override with edited color */
        },
        '& .MuiDataGrid-row.duplicate': {
          backgroundColor: '#4c3333!important', /* Background color for duplicate rows */
          '&:hover': {
            backgroundColor: '#4c3333 !important',
          },
        },
        '& .MuiDataGrid-cell.cell-opacity': {
          opacity: 0.3,
        },
      }}
    >
      <DataGridPro
        disableRowSelectionOnClick
        rows={rows}
        key={rows.length}
        pagination
        paginationModel={paginationModel}
        columns={createColumns(handleDeleteItem, handleResetItem, editedRows, profileKoBiddingOptionsRef, rows, currentLang, campaignOptionsRef, adGroupOptionsRef)}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        onPaginationModelChange={handlePaginationModelChange}
        disableDensitySelector
        onRowSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel as (number | string)[])}
        processRowUpdate={processRowUpdate}
        getRowClassName={getRowClassName}
        getCellClassName={getCellClassName}
        slots={{
          noRowsOverlay: NoRowsOverlay,
          toolbar: GridToolbar
        }}
      />
    </Box>
  );
}
