// React and core
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/system';

// MUI Components
import Card from '@mui/material/Card';
import {
    Box, Button, Container, Grid, Stack, Pagination,
    FormControl, InputAdornment, OutlinedInput,
    Skeleton, Typography, CardContent, Avatar, MenuItem, Checkbox, MenuList, Alert,
    Divider
} from '@mui/material';

// Custom hooks and utilities
import { fDate } from 'src/utils/format-time';
import { useLocales } from 'src/locales';
import { useGetKeywordsPlaTxtQuery, useGetCountriesPlaTxtQuery } from 'src/context/api/competitorsMonitoring/api';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings/';
import Iconify from 'src/components/iconify';

// Add new imports after existing imports
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
    Drawer, DrawerProps, IconButton, Badge, Chip,
    Autocomplete, TextField, FormControlLabel, Switch, Slider
} from '@mui/material';
import { FlagIcon } from 'src/components/iconify/flagIcon';
import countriesJson from "../../../../utils/countries.json"
import { useGetMinDateCompetitorQuery } from 'src/context/api/competitorsMonitoring/api';
import { useGetAdsTxtGlobalQuery } from 'src/context/api/competitorsMonitoring/api';
import { useGetBrandsQuery } from 'src/context/api/competitorsMonitoring/api';
import {
    setFilterBrandTXT,
    setFilterCountry,
    setFilterImpressionShareTXT,
    setFilterAveragePositionTXT,
    setFilterDomainTXT,
    setFilterKeyword,
    setFilterLoadedKeyword,
    setNotificationBrandInfringementTXT,
    setNotificationBrandInfringementNotificationTXT,
    setFilterMinDatePicker,
    setFilterLoadedCountry,
} from 'src/context/reducers/filter-settings/filterSlice';
import { ComponentBlockCustom } from 'src/sections/mui/component-block-custom';
import CustomPopover from 'src/components/custom-popover';

// Add FilterDrawer context
const FilterDrawerContext = createContext({
    open: false,
    toggleDrawer: () => { },
    onClose: () => { },
    onReset: () => { }
});

type Props = DrawerProps & {
    favorited?: boolean;
};

export const useFilterDrawer = () => useContext(FilterDrawerContext);

// Modifier la définition d'interface pour inclure les nouvelles propriétés
interface AdsResponse {
    ads: any[];
    total_count: number;
    total_pages: number;
    current_page: number;
    domains: string[];
}
interface WorkspaceList {
    id: string;
    bqTable: string;
    brands?: string[]; // Ajout de la propriété optionnelle brands
    // ...existing code...
}

// --- Component Definition ---
export default function GlobalTextAds({ domain, workspaceTable }: any) {
    // --- State Management ---
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState("impressionShare");
    const [sortOrder, setSortOrder] = useState('desc');
    const [selectedAds, setSelectedAds]: any = useState([]);
    const dispatch = useDispatch();
    const [filters, setFilters] = useState<Omit<Props, 'open'> & { open: boolean }>({
        open: false,
        favorited: false,
    });
    // --- Hooks ---
    const filterStore = useSelector((state: any) => state.filters);
    const theme = useTheme();
    const { t } = useLocales();
    const workspace = useCurrentWorkspace();

    const [brandInfringement, setBrandInfringement] = useState(false);
    const [filterCountry, setFilterCountryState] = useState<any>(undefined);
    const [displayFilterImpressionShare, setDisplayFilterImpressionShare] = useState(false)
    const [displayFilterAveragePosition, setDisplayFilterAveragePosition] = useState(false)

    const handleActionAlertBrandInfringement = () => {
        dispatch(setFilterBrandTXT(brands))
        dispatch(setNotificationBrandInfringementTXT(false))
    }


    const { data: minDateData, error: minDateError, isFetching: isFetchingMinDate } = useGetMinDateCompetitorQuery({
        domain: "all",
        workspaceTable: workspace?.bqTable,
        workspaceId: workspace?.id,
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
    });

    const { data: brands, error: brandsError, isLoading: isLoadingBrands, isFetching: isFetchingBrands } = useGetBrandsQuery({
        bqTable: workspace?.bqTable || "",
    });

    const pageSize = 30;

    const { data: ads, error: statsError, isFetching } = useGetAdsTxtGlobalQuery({
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        device: filterStore.devices.map((device: string) => device.toLowerCase()),
        countries: filterStore.countries,
        domain: "all",
        workspaceTable: workspace?.bqTable,
        page: currentPage,
        pageSize: pageSize,
        keywords: filterStore?.keywords,
        otherDomains: workspace?.friendlyDomains,
        brands: filterStore?.brandsTXT,
        domains: filterStore?.domainsTXT,
        sortBy: sortBy,
        sortOrder: sortOrder,
        search: searchQuery,
        impressionShare: filterStore?.impressionShareTXT,
        position: filterStore?.averagePositionTXT,
    });

    useEffect(() => {
        if (minDateData) {
            dispatch(setFilterMinDatePicker(minDateData.minDate));
        }
    }, [minDateData, dispatch]);

    // Add new API queries
    const { data: keywordsData, isFetching: isFetchingKeywords } = useGetKeywordsPlaTxtQuery({
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        adType: "TXT",
        countries: filterStore.countries,
        workspaceId: workspace?.id
    });

    useEffect(() => {
        dispatch(setFilterLoadedKeyword([]));
    }, [])

    useEffect(() => {
        dispatch(setFilterLoadedKeyword([]));

        if (!isFetchingKeywords) {
            dispatch(setFilterLoadedKeyword(keywordsData?.filters?.keywords))
        }
    }, [keywordsData, workspace])

    // Add new API queries
    const { data: countriesData } = useGetCountriesPlaTxtQuery({
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        adType: "TXT"
    });

    const { data: adsNotificationBrandInfringementTXT, error: adsNotificationBrandInfringementError, isFetching: isFetchingAdsNotificationBrandInfringementTXT, isLoading: isLoadingAdsNotificationBrandInfringement }: any = useGetAdsTxtGlobalQuery({
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        device: filterStore.devices.map((device: string) => device.toLowerCase()),
        countries: filterStore.countries,
        domain: "all",
        workspaceTable: workspace?.bqTable,
        page: currentPage,
        pageSize: pageSize,
        keywords: filterStore?.keywords,
        brands: brands,
        otherDomains: workspace?.friendlyDomains,
        impressionShare: filterStore?.impressionShareTXT,
        domains: filterStore?.domainsTXT,
        position: filterStore?.averagePositionTXT,
        sortBy: sortBy,
        sortOrder: sortOrder,
        search: searchQuery
    });

    useEffect(() => {
        if (workspace?.showTxtAlert) {
            if (adsNotificationBrandInfringementTXT === undefined) {
            } else {
                if (adsNotificationBrandInfringementTXT?.ads?.length !== 0)
                    if (filterStore?.brandsTXT?.length === 0)
                        dispatch(setNotificationBrandInfringementTXT(true))
                dispatch(setNotificationBrandInfringementNotificationTXT(adsNotificationBrandInfringementTXT))
            }
        }
    }, [adsNotificationBrandInfringementTXT, workspace?.showTxtAlert])

    // Add countries data processing
    const { countries } = useMemo(() => {
        return { countries: countriesData?.countries }
    }, [countriesData])

    useEffect(() => {
        if (countries) {
            setFilterCountryState(countries);
            dispatch(setFilterLoadedCountry(countriesData?.countries))
        }
    }, [countries, filterCountry]);

    console.log(filterStore?.loadedCountries)

    // --- Event Handlers ---
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handlePageChange = (event: any, value: any) => {
        setCurrentPage(value);
    };

    const handleAdHoverEnter = (index: any) => {
        setSelectedAds([index]);  // Sets the current hovered ad
    };

    const handleAdHoverLeave = () => {
        setSelectedAds([]);  // Resets when mouse leaves
    };

    const handleSortOrderChange = (event: any) => {
        setSortOrder(event.target.value);
    };

    const handleSortChange = (newValue: string) => {
        setSortBy(newValue);
    };

    const handleOrderChange = (newValue: any) => {
        setSortOrder(newValue);
    };

    const handleDeleteImpressionShareFilter = () => {
        dispatch(setFilterImpressionShareTXT([0, 100]))
        setDisplayFilterImpressionShare(false);
    }

    const handleDeleteAveragePositionFilter = () => {
        dispatch(setFilterAveragePositionTXT([0, 50]))
        setDisplayFilterAveragePosition(false);
    }

    // Add filter handlers
    const toggleDrawer = () => {
        setFilters((prev: Props) => ({ ...prev, open: !prev.open }));
    };

    const handleClose = () => {
        setFilters((prev: Props) => ({ ...prev, open: false }));
    };

    const handleReset = () => {
        dispatch(setFilterKeyword([]));
        dispatch(setFilterCountry([]));
        dispatch(setFilterBrandTXT([]));
        dispatch(setFilterDomainTXT([]));
        dispatch(setNotificationBrandInfringementTXT(true));
        dispatch(setFilterImpressionShareTXT([0, 100]))
        dispatch(setFilterAveragePositionTXT([0, 50]))
        handleDeleteImpressionShareFilter();
        handleDeleteAveragePositionFilter();
    };

    const handleCountryFilterChange = (selectedOptions: any) => {
        const updatedOptions = selectedOptions?.filter((option: string) => option !== t('dashboard.filters.all'));
        dispatch(setFilterCountry(updatedOptions));
    };

    const handleBrandInfringementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setBrandInfringement(isChecked);

        if (isChecked && brands && brands.length > 0) {
            dispatch(setFilterBrandTXT(brands));
        } else {
            dispatch(setFilterBrandTXT([]));
            dispatch(setNotificationBrandInfringementTXT(true));

        }
    };

    const handleDomainChange = (event: React.SyntheticEvent, newValue: string[]) => {
        dispatch(setFilterDomainTXT(newValue));
    };

    const handleDeleteKeywordFilter = (index: number) => {
        const updatedKeywords = [...filterStore.keywords];
        updatedKeywords.splice(index, 1);
        dispatch(setFilterKeyword(updatedKeywords));
    };

    const handleDeleteBrandFilter = () => {
        setBrandInfringement(false);
        dispatch(setFilterBrandTXT([]));
        dispatch(setNotificationBrandInfringementTXT(true))
    };
    const handleDeleteDomainFilter = (domainToDelete: string) => {
        const updatedDomains = filterStore.domainsTXT.filter(
            (domain: string) => domain !== domainToDelete
        );
        dispatch(setFilterDomainTXT(updatedDomains));
    };

    const handleDeleteCountryFilter = (index: number) => {
        const updatedCountries = [...filterStore.countries];
        updatedCountries.splice(index, 1);
        dispatch(setFilterCountry(updatedCountries));
    };

    const handleClearAllFilter = () => {
        dispatch(setFilterKeyword([]));
        dispatch(setFilterCountry([]));
        dispatch(setFilterDomainTXT([]));
        dispatch(setFilterBrandTXT([]));
        dispatch(setNotificationBrandInfringementTXT(true));
        dispatch(setFilterImpressionShareTXT([0, 100]));
        dispatch(setFilterAveragePositionTXT([0, 50]));
        handleDeleteImpressionShareFilter();
        handleDeleteAveragePositionFilter();
    }

    // Add keyword handling
    const handleKeywordChange = (value: any) => {
        if (Array.isArray(value)) {
            const keywordsArray = value.map((item) => item.search_term);
            dispatch(setFilterKeyword(keywordsArray));
        } else if (value && value.search_term) {
            dispatch(setFilterKeyword([value.search_term]));
        } else {
            dispatch(setFilterKeyword([]));
        }
    };


    // Options pour les menus
    const sortOptions = [
        { value: 'position', label: t('dashboard.textAdsPage.Ad_average_position') },
        { value: 'impressionShare', label: t('dashboard.textAdsPage.Ad_impressionShare') }
    ];

    const orderOptions = [
        { value: 'asc', label: 'Ascending' },
        { value: 'desc', label: 'Descending' },
    ];

    // Affiche le all pour les keywords
    const allKeywords = useMemo(() => {
        return [{ search_term: 'All', total_organic_searches: null }, ...filterStore.loadedKeywords];
    }, [filterStore.loadedKeywords]);

    // Pour le Popover "Sort by"
    const [anchorElSort, setAnchorElSort] = useState(null);

    // Pour le Popover "Order by"
    const [anchorElOrder, setAnchorElOrder] = useState(null);

    // Popover pour "Sort by"
    const popoverSort: any = {
        open: Boolean(anchorElSort),
        anchorEl: anchorElSort,
        onOpen: (event: any) => {
            setAnchorElSort(event.currentTarget);
        },
        onClose: () => {
            setAnchorElSort(null);
        },
    };

    // Popover pour "Order by"
    const popoverOrder: any = {
        open: Boolean(anchorElOrder),
        anchorEl: anchorElOrder,
        onOpen: (event: any) => {
            setAnchorElOrder(event.currentTarget);
        },
        onClose: () => {
            setAnchorElOrder(null);
        },
    };

    // --- Data Processing ---

    const filteredAds = ads?.ads?.filter((ad: any) =>
        (ad.title ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
        (ad.description ?? "").toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

    const sortedAds = [...filteredAds].sort((a, b) => {
        const compareValue = (valueA: number, valueB: number) => {
            if (sortOrder === 'asc') {
                return valueA - valueB;
            } else {
                return valueB - valueA;
            }
        };

        switch (sortBy) {
            case 'Impression Share':
                return compareValue(a.impression_share || 0, b.impression_share || 0);
            case 'Average Position':
                return compareValue(a.position || 0, b.position || 0);
            default:
                return 0;
        }
    });

    return (
        <FilterDrawerContext.Provider value={{
            open: filters.open,
            toggleDrawer,
            onClose: handleClose,
            onReset: handleReset
        }}>
            <Container maxWidth="xl">
                <Stack spacing={3} >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {/* Search bar */}
                        <FormControl sx={{ my: 1, p: 0 }}>
                            <OutlinedInput
                                sx={{ width: '100%' }}
                                id="outlined-adornment-amount"
                                placeholder={t('dashboard.textAdsPage.search_placeholder')}
                                size="small"
                                value={searchQuery}
                                onChange={handleSearch}
                                startAdornment={<InputAdornment position="start"><Iconify icon={"eva:search-fill"} /></InputAdornment>}
                            />
                        </FormControl>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            {/* Sort by and filter button */}
                            <Box gap={2} sx={{ display: 'flex', }}>
                                <Button
                                    disableRipple
                                    color="inherit"
                                    onClick={(event) => popoverSort.onOpen(event)}
                                    endIcon={
                                        <Iconify
                                            icon={
                                                popoverSort.open
                                                    ? 'eva:arrow-ios-upward-fill'
                                                    : 'eva:arrow-ios-downward-fill'
                                            }
                                        />
                                    }
                                    sx={{ fontWeight: 'fontWeightSemiBold' }}
                                >
                                    Sort by:
                                    <Box
                                        component="span"
                                        sx={{
                                            ml: 0.5,
                                            fontWeight: 'fontWeightBold',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {sortOptions.find((option) => option.value === sortBy)?.label}
                                    </Box>
                                </Button>
                                <CustomPopover
                                    open={popoverSort.open}
                                    anchorEl={popoverSort.anchorEl}
                                    onClose={popoverSort.onClose}
                                >
                                    <MenuList>
                                        {/* Vos options de tri */}
                                        {sortOptions.map((option: any) => (
                                            <MenuItem
                                                key={option.value}
                                                selected={option.value === sortBy}
                                                onClick={() => {
                                                    popoverSort.onClose();
                                                    handleSortChange(option.value);
                                                }}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </CustomPopover>
                            </Box>
                            {/* Order by button */}
                            <Box>
                                <Button
                                    disableRipple
                                    color="inherit"
                                    onClick={(event) => popoverOrder.onOpen(event)}
                                    endIcon={
                                        <Iconify
                                            icon={
                                                popoverOrder.open
                                                    ? 'eva:arrow-ios-upward-fill'
                                                    : 'eva:arrow-ios-downward-fill'
                                            }
                                        />
                                    }
                                    sx={{ fontWeight: 'fontWeightSemiBold' }}
                                >
                                    Order by:
                                    <Box
                                        component="span"
                                        sx={{
                                            ml: 0.5,
                                            fontWeight: 'fontWeightBold',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
                                    </Box>
                                </Button>

                                <CustomPopover
                                    open={popoverOrder.open}
                                    anchorEl={popoverOrder.anchorEl}
                                    onClose={popoverOrder.onClose}
                                >
                                    <MenuList>
                                        {/* Vos options d'ordre */}
                                        {orderOptions.map((option: any) => (
                                            <MenuItem
                                                key={option.value}
                                                selected={option.value === sortOrder}
                                                onClick={() => {
                                                    popoverOrder.onClose();
                                                    handleOrderChange(option.value);
                                                }}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </CustomPopover>
                            </Box>

                            {/* filter button */}
                            <Button onClick={toggleDrawer}>
                                {t('dashboard.textAdsPage.filters')}
                                <Badge
                                    color="error"
                                    variant="dot"
                                    invisible={
                                        !(
                                            filterStore?.keywords?.length ||
                                            filterStore?.domainsTXT?.length ||
                                            filterStore?.countries?.length ||
                                            filterStore?.brandsTXT?.length ||
                                            displayFilterImpressionShare ||
                                            displayFilterAveragePosition
                                        )
                                    }
                                    sx={{
                                        '& .MuiBadge-dot': {
                                            zIndex: 1,
                                        },
                                    }}
                                >
                                    <Iconify sx={{ ml: 0.5 }} icon={'mynaui:filter'} />
                                </Badge>
                            </Button>
                        </Box>
                    </Box>
                </Stack>
                <Box sx={{ position: 'relative', mt: 2 }}>
                    <Box>
                        {ads?.total_count > 0 ? `${ads?.total_count} ${ads?.total_count === 1 ? t('dashboard.textAdsPage.result') : t('dashboard.textAdsPage.results')} ${t('dashboard.textAdsPage.found')}` : '0 result found'}
                    </Box>

                    <Box sx={{ mt: 1.5 }}>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                            {filterStore?.keywords?.length !== 0 && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        border: '2px dotted rgb(145 158 171 / 0.2)',
                                        borderRadius: 1,
                                        p: 1,
                                    }}
                                >
                                    <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: 0.5 }}>
                                        Keywords:
                                    </Typography>
                                    {filterStore?.keywords?.slice(0, 2).map((keyword: any, index: number) => (
                                        <Chip
                                            key={index}
                                            size="small"
                                            label={keyword}
                                            sx={{ position: 'relative', ml: 1 }}
                                            onDelete={() => handleDeleteKeywordFilter(index)}
                                        />
                                    ))}
                                    {filterStore?.keywords?.length > 2 && (
                                        <Chip
                                            size="small"
                                            label={`+${filterStore.keywords.length - 2}`}
                                            sx={{
                                                position: 'relative',
                                                ml: 1,

                                            }}
                                        />
                                    )}
                                </Box>
                            )}



                            {filterStore?.countries?.length !== 0 &&
                                <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                    <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Countries:</Typography>
                                    {filterStore?.countries?.map((countrie: any, index: any) => (
                                        <Chip
                                            key={index}
                                            size='small'
                                            label={countrie}
                                            sx={{ position: 'relative', ml: 1 }}
                                            onDelete={() => handleDeleteCountryFilter(index)}
                                        />
                                    ))}
                                </Box>
                            }

                            {filterStore?.brandsTXT?.length !== 0 &&
                                <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                    <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Brand Protection:</Typography>
                                    <Chip
                                        size='small'
                                        label={"Infringement"}
                                        sx={{ position: 'relative', ml: 1 }}
                                        onDelete={() => handleDeleteBrandFilter()}
                                    />
                                </Box>
                            }


                            {filterStore?.domainsTXT?.length !== 0 &&
                                <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                    <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Competitors domains:</Typography>
                                    {filterStore?.domainsTXT?.map((domain: any, index: any) => (
                                        <Chip
                                            key={index}
                                            size='small'
                                            label={domain}
                                            sx={{ position: 'relative', ml: 1 }}
                                            onDelete={() => handleDeleteDomainFilter(domain)}
                                        />
                                    ))}
                                </Box>
                            }

                            {displayFilterImpressionShare &&
                                <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                    <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Impression Share:</Typography>
                                    <Chip
                                        size='small'
                                        variant='soft'
                                        label={`${filterStore?.impressionShareTXT[0]}% - ${filterStore?.impressionShareTXT[1]}%`}
                                        sx={{ position: 'relative', ml: 1 }}
                                        onDelete={() => handleDeleteImpressionShareFilter()}
                                    />
                                </Box>
                            }

                            {displayFilterAveragePosition &&
                                <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                    <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Position:</Typography>
                                    <Chip
                                        size='small'
                                        variant='soft'
                                        label={`${filterStore?.averagePositionTXT[0]} - ${filterStore?.averagePositionTXT[1]}`}
                                        sx={{ position: 'relative', ml: 1 }}
                                        onDelete={() => handleDeleteAveragePositionFilter()}
                                    />
                                </Box>
                            }


                            {(filterStore?.domainsTXT?.length !== 0 || filterStore?.keywords?.length !== 0 || filterStore?.countries?.length !== 0 || filterStore?.brandsTXT?.length !== 0 || displayFilterAveragePosition || displayFilterImpressionShare) &&
                                <Box>
                                    <Button
                                        onClick={handleClearAllFilter}
                                        startIcon={<Iconify icon="eva:trash-2-outline" />}
                                        sx={{ color: 'error.main', fontWeight: 'bold', textTransform: 'none', mt: .5 }}
                                    >
                                        Clear
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>

                {workspace?.showTxtAlert &&
                    <>
                        {!brandInfringement &&
                            <>
                                {!isFetchingAdsNotificationBrandInfringementTXT &&
                                    adsNotificationBrandInfringementTXT?.ads?.length !== 0 &&
                                    filterStore?.notificationBrandInfringementTXT !== false && (
                                        <Box>
                                            <Alert
                                                sx={{ my: 2, backgroundColor: theme.palette.mode === "dark" ? '#322f33' : '#FFF2EF', color: theme.palette.mode === "dark" ? '#e0ac8a' : '#B71D18' }}
                                                severity="error"
                                                action={
                                                    <>
                                                        <Button
                                                            color="inherit"
                                                            size="small"
                                                            sx={{
                                                                mr: 1,
                                                                backgroundColor: theme.palette.mode === "dark" ? '#a82e24' : "#FF5630",
                                                                color: 'white',
                                                            }}
                                                            onClick={handleActionAlertBrandInfringement}
                                                        >
                                                            Show
                                                        </Button>
                                                    </>
                                                }
                                            >
                                                <Typography fontSize={'.9rem'}>
                                                    Brand Infringement Alert: Some competitors are using your Brand in their Text Ads Content
                                                </Typography>
                                            </Alert>
                                        </Box>
                                    )}
                            </>
                        }
                    </>
                }

                <Grid spacing={3} container sx={{ mt: 2 }}>
                    {isFetching || (!ads && !statsError) ? (
                        Array.from(new Array(pageSize)).map((_, index) => (
                            <Grid item key={index} xs={12} sm={6} >
                                <Skeleton variant="rectangular" width="100%" height={170} style={{ borderRadius: 10 }} />
                            </Grid>
                        ))
                    ) : (
                        sortedAds.length > 0 ? (
                            sortedAds.map((ad: any, index: number) => (
                                <Grid item key={index} xs={12} sm={6}  >
                                    <Card
                                        sx={{
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            height: 170,
                                            bgcolor: selectedAds.includes(index) ? theme.palette.mode === "dark" ? '#1c252e' : 'white' : 'none',
                                            color: selectedAds.includes(index) ? theme.palette.mode === "dark" ? '#fff' : "#202124" : '#000',
                                            transition: 'transform 0.3s ease',
                                            '&:hover': {
                                                transform: 'scale(1.02)'
                                            },
                                            overflow: 'hidden',
                                            cursor: 'pointer'
                                        }}
                                        onMouseEnter={() => handleAdHoverEnter(index)}
                                        onMouseLeave={() => handleAdHoverLeave()}
                                    >
                                        {selectedAds.includes(index) ? (
                                            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', }}>
                                                <CardContent sx={{ height: '100%', width: '100%' }}>
                                                    <Stack direction={'row'} spacing={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%', width: '100%' }}>
                                                        <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', alignItems: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', border: 2, flexGrow: 1, flexBasis: 0, flexShrink: 1, borderRadius: 2, borderColor: theme.palette.mode === "dark" ? "#212a33" : "#6f777d" }}>
                                                            <Iconify icon="mdi:eye" width={25} color={theme.palette.mode === 'dark' ? '#fff' : '#1C252E'} />
                                                            <Typography variant="h6" color={theme.palette.mode === 'dark' ? '#fff' : '#1C252E'}>
                                                                {(ad?.impression_share * 100)?.toFixed(2) + "%" ?? "N/A"}
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="grey">
                                                                {t('dashboard.textAdsPage.Ad_impressionShare')}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', alignItems: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', border: 2, flexGrow: 1, flexBasis: 0, flexShrink: 1, borderRadius: 2, borderColor: theme.palette.mode === "dark" ? "#212a33" : "#6f777d" }}>
                                                            <Iconify icon="mdi:podium-gold" width={25} color={theme.palette.mode === 'dark' ? '#fff' : '#1C252E'} />
                                                            <Typography variant="h6" color={theme.palette.mode === 'dark' ? '#fff' : '#1C252E'}>
                                                                {ad?.position?.toFixed(1) || 'N/A'}
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="grey">
                                                                {t('dashboard.textAdsPage.Ad_average_position')}
                                                            </Typography>
                                                        </Box>
                                                        <Button onClick={() => window.open(ad?.link)} sx={{ display: 'flex', gap: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', border: 2, flexGrow: 1, height: '100%', flexBasis: 0, flexShrink: 1, borderRadius: 2, borderColor: theme.palette.mode === "dark" ? "#212a33" : "#6f777d" }}>
                                                            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', width: '100%', height: '100%', borderRadius: 2, }}>
                                                                <Iconify sx={{ width: 30, height: 30 }} icon={"mingcute:external-link-fill"} color={theme.palette.mode === 'dark' ? '#fff' : '#1C252E'} />
                                                                <Typography variant="body2" fontSize={15} sx={{ fontWeight: 'bold' }}>
                                                                    {t('dashboard.textAdsPage.Visit')}
                                                                </Typography>
                                                                <Typography variant="body2" sx={{ color: '#6f777d' }}>
                                                                    {t('dashboard.textAdsPage.Ad_landing_page')}
                                                                </Typography>
                                                            </Box>
                                                        </Button>
                                                    </Stack>
                                                </CardContent>
                                            </Box>
                                        ) : (
                                            <Box sx={{ backgroundColor: theme.palette.mode === 'dark' ? '#1C252E' : 'white', borderRadius: 2, p: 2, height: 230, }}>
                                                <Stack direction={'row'} spacing={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                    <Avatar sx={{ width: 24, height: 24, border: '1px solid #dbdde1', backgroundColor: '#f1f3f4' }} src={ad?.link_base64 || "/assets/no_img.png"} />
                                                    <Box gap={1} >
                                                        <Typography variant="body1" sx={{ fontSize: 12, color: theme.palette.mode === 'dark' ? '#ffffff' : '#000' }} >{ad.domain}</Typography>
                                                        <Typography variant="body1" sx={{ fontSize: 12, color: theme.palette.mode === 'dark' ? '#ffffff' : '#000' }} >{ad.link}</Typography>
                                                    </Box>

                                                </Stack>
                                                <Typography variant='body2' sx={{ fontSize: 18, color: theme.palette.mode === 'dark' ? '#8AB4F8' : '#1558D6', }}>{ad.title}</Typography>
                                                <Typography variant="body2" sx={{
                                                    color: theme.palette.mode === 'dark' ? '#fff' : '#1C252E',
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    WebkitLineClamp: 3,
                                                    lineClamp: 3
                                                }}
                                                    gutterBottom
                                                >
                                                    {ad.description}
                                                </Typography>

                                            </Box>
                                        )}
                                    </Card>
                                </Grid>
                            ))
                        ) : (
                            <Grid item xs={12}>
                                <Typography variant="h6" color="text.secondary" align="center">
                                    Aucun résultat trouvé.
                                </Typography>
                            </Grid>
                        )
                    )}
                </Grid>
                {ads?.ads?.length !== 0 &&
                    <Stack spacing={2} sx={{ mt: 5 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <Pagination
                                count={ads?.total_pages || 1}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Box>
                    </Stack>
                }
                {/* Add Filter Drawer at the end of container */}
                <Drawer
                    anchor="right"
                    open={filters.open}
                    onClose={handleClose}
                    PaperProps={{
                        sx: { width: 320 },
                    }}
                >
                    <Box sx={{ overflowX: 'hidden' }}>
                        <Stack spacing={3}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ px: 2, pt: 2 }}
                            >
                                <Typography variant="h6">{t('dashboard.textAdsPage.filters')}</Typography>
                                <Box>
                                    <IconButton onClick={handleReset}>
                                        <Badge
                                            color="error"
                                            variant="dot"
                                            invisible={
                                                !(
                                                    filterStore?.keywords?.length > 0 ||
                                                    filterStore?.countries?.length > 0 ||
                                                    filterStore?.domainsTXT?.length > 0 ||
                                                    filterStore?.brandsTXT?.length > 0
                                                )
                                            }
                                        >
                                            <Iconify icon="solar:restart-bold" />
                                        </Badge>
                                    </IconButton>
                                    <IconButton onClick={handleClose}>
                                        <Iconify icon="mingcute:close-line" />
                                    </IconButton>
                                </Box>
                            </Stack>

                            <Divider
                                sx={{
                                    borderColor: 'rgba(145, 158, 171, 0.2)', // Gris plus foncé
                                    borderWidth: '1px', // Ligne plus épaisse
                                    mb: 1
                                }}
                                variant="fullWidth"
                            />

                            {/* Keywords filter */}
                            <Stack px={2} spacing={3}>
                                <Autocomplete
                                    size='small'
                                    disableClearable
                                    multiple
                                    noOptionsText={isFetchingKeywords ? 'Loading...' : 'No keywords found'}
                                    options={filterStore?.loadedKeywords || []}
                                    getOptionLabel={(option: { search_term: string }) => option.search_term}
                                    value={
                                        filterStore.keywords.length === 0
                                            ? [allKeywords[0]] // L'option "All"
                                            : allKeywords.filter(option => filterStore.keywords.includes(option.search_term))
                                    }
                                    onChange={(event, newValue) => handleKeywordChange(newValue)}
                                    renderTags={(value, getTagProps) => {
                                        const selectedOptions = value || [];

                                        if (selectedOptions.length > 2) {
                                            return (
                                                <>
                                                    {selectedOptions.slice(0, 2).map((option, index) => (
                                                        <Chip
                                                            label={option.search_term}
                                                            size="small"
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))}
                                                    <Chip
                                                        label={`+${selectedOptions.length - 2}`}
                                                        size="small"
                                                        variant="soft"
                                                        sx={{ color: '#919eab', marginLeft: .3 }}
                                                    />
                                                </>
                                            );
                                        } else if (selectedOptions.length > 0) {
                                            return selectedOptions.map((option, index) => (
                                                <Chip
                                                    label={option.search_term}
                                                    size="small"
                                                    variant='soft'
                                                    {...getTagProps({ index })}
                                                    deleteIcon={option?.search_term === t('dashboard.filters.all') ? <></> : undefined}
                                                />
                                            ));
                                        } else {
                                            return (
                                                <Chip
                                                    label="All"
                                                    size="small"
                                                    variant='soft'
                                                />
                                            );
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Keywords"
                                        />
                                    )}
                                />
                                {/* Countries filter */}
                                <Autocomplete
                                    multiple={true}
                                    size="small"
                                    limitTags={1}
                                    disableCloseOnSelect
                                    options={filterCountry || []}
                                    getOptionLabel={(option: any) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('dashboard.filters.countries')}
                                        />
                                    )}
                                    value={
                                        filterStore?.countries?.length === 0
                                            ? [t('dashboard.filters.all')]
                                            : filterStore?.countries
                                    }
                                    renderOption={(props, option) => {
                                        const country = countriesJson?.find((c) => c.name === option);
                                        const flagIcon = country ? `circle-flags:${country.code}` : null;

                                        return (
                                            <li {...props} style={{ whiteSpace: 'nowrap' }} key={option}>
                                                {option !== t('dashboard.filters.all') && (
                                                    <>
                                                        <Checkbox
                                                            checked={filterStore?.countries.includes(option)}
                                                        />
                                                        {
                                                            flagIcon ? (
                                                                <Iconify icon={flagIcon} width={16} sx={{ mr: 1 }} />
                                                            ) : (
                                                                <img style={{ width: 14, height: 14, marginRight: 6 }} src="/assets/location.png" />
                                                            )
                                                        }
                                                    </>


                                                )}
                                                {option}
                                            </li>
                                        );
                                    }}
                                    onChange={(event, selectedOptions) => {
                                        // Ne pas permettre la désélection de "All" s'il est le seul sélectionné
                                        if (selectedOptions.length === 0) {
                                            handleCountryFilterChange([t('dashboard.filters.all')]);
                                            return;
                                        }
                                        // Si "All" est sélectionné, désélectionner tous les autres
                                        if (selectedOptions.includes(t('dashboard.filters.all'))) {
                                            handleCountryFilterChange([t('dashboard.filters.all')]);
                                            return;
                                        }
                                        handleCountryFilterChange(selectedOptions);
                                    }}
                                    renderTags={(selected, getTagProps) =>
                                        selected.map((option, index) => {
                                            const country = countriesJson?.find((c) => c.name === option);
                                            const flagCode = country ? country.code : null;

                                            return (
                                                <Chip
                                                    {...getTagProps({ index })}
                                                    key={option}
                                                    icon={
                                                        option !== t('dashboard.filters.all') ? (
                                                            flagCode ? (
                                                                <FlagIcon
                                                                    key={flagCode}
                                                                    code={flagCode}
                                                                    sx={{ width: 16, height: 16, borderRadius: '50%' }}
                                                                />
                                                            ) : (
                                                                <img style={{ width: 14, height: 14, marginRight: 1 }} src="/assets/location.png" />
                                                            )
                                                        ) : undefined
                                                    }
                                                    label={option}
                                                    size="small"
                                                    deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                                                    variant="soft"
                                                />
                                            );
                                        })
                                    }
                                />

                                {workspace?.showTxtAlert &&
                                    <ComponentBlockCustom title="Brand Protection">
                                        <FormControlLabel
                                            sx={{ p: 1.5, mt: .5 }}
                                            control={
                                                <Switch
                                                    checked={filterStore?.brandsTXT?.length !== 0}
                                                    onChange={handleBrandInfringementChange}
                                                />
                                            }
                                            label={"Brand Infringement"}
                                        />
                                    </ComponentBlockCustom>
                                }

                                <Autocomplete
                                    size="small"
                                    disableClearable
                                    multiple={true}
                                    value={
                                        filterStore?.domainsTXT?.length === 0
                                            ? ["All"]
                                            : filterStore?.domainsTXT
                                    }
                                    noOptionsText={ads?.domains ? "Loading..." : "No options"}
                                    options={ads?.domains ? ads?.domains : []}
                                    onChange={(event, newValue) => {

                                        const filteredValue = newValue.filter((value) => value !== "All");
                                        handleDomainChange(event, filteredValue.length > 0 ? filteredValue : []);
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                {...getTagProps({ index })}
                                                key={option}
                                                label={option}
                                                size="small"
                                                variant={'soft'}
                                                onDelete={
                                                    option === "All" ? undefined : getTagProps({ index }).onDelete
                                                }
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Competitors Domains"
                                        />
                                    )}
                                />

                                <ComponentBlockCustom sx={{ pt: 3, px: 4 }} title="Impression Share">
                                    <Slider
                                        value={filterStore?.impressionShareTXT || [0, 100]}
                                        onChange={(e, newValue) => {
                                            const valueArray: any = Array.isArray(newValue) ? newValue : [newValue, newValue];
                                            dispatch(setFilterImpressionShareTXT(valueArray));
                                            setDisplayFilterImpressionShare(!(valueArray[0] === 0 && valueArray[1] === 100));
                                        }}
                                        valueLabelDisplay="on"
                                        min={0}
                                        max={100}
                                        sx={{ mt: 3 }}
                                        valueLabelFormat={(value) => `${value}%`}
                                        step={1}
                                        marks={Array.from({ length: 11 }, (_, i) => ({
                                            value: i * 20,
                                            label: `${i * 20}%`,
                                        }))}
                                    />
                                </ComponentBlockCustom>

                                <ComponentBlockCustom sx={{ pt: 3, px: 4, mb: 1 }} title="Position">
                                    <Slider
                                        value={filterStore?.averagePositionTXT || [0, 50]}
                                        onChange={(e, newValue) => {
                                            const valueArray: any = Array.isArray(newValue) ? newValue : [newValue, newValue];
                                            dispatch(setFilterAveragePositionTXT(valueArray));
                                            // Désactive le filtre si on revient aux valeurs par défaut
                                            setDisplayFilterAveragePosition(!(valueArray[0] === 0 && valueArray[1] === 50));
                                        }}
                                        valueLabelDisplay="on"
                                        min={0}
                                        sx={{ mt: 3 }}

                                        max={50}
                                        marks={Array.from({ length: 11 }, (_, i) => ({
                                            value: i * 10,
                                            label: `${i * 10}`,
                                        }))}
                                        step={1}
                                    />
                                </ComponentBlockCustom>
                            </Stack>
                        </Stack>
                    </Box>
                </Drawer>
            </Container>
        </FilterDrawerContext.Provider>
    );
}