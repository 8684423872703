import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

const campaignExplorer = createApi({
    baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
    reducerPath: 'api.campaignExplorer',
    tagTypes: ['campaignExplorer'],
    endpoints: (builder) => ({
        getData: builder.query<any, { client: string, searchAdGroup: any, searchCampaign: any, searchKeyword: any, searchSearchTerm: any, sortPeriod: any }>({
            query: ({ client, searchAdGroup, searchCampaign, searchKeyword, searchSearchTerm, sortPeriod }) => ({
                url: `/campaignExplorer/getData`,
                method: 'POST',
                body: { client, searchAdGroup, searchCampaign, searchKeyword, searchSearchTerm, sortPeriod },
                headers: {
                    Accept: 'application/json',
                    'Cache-Control': 'max-age=0',
                },
            }),
        }),
    }),
});

export const {
    useGetDataQuery,
} = campaignExplorer;

export default campaignExplorer;