import { useState, useMemo, useEffect, createContext, useContext } from 'react';
import {
    Box,
    Container,
    Grid,
    Stack,
    InputAdornment,
    OutlinedInput,
    Typography, FormControl, Card, CardMedia, CardContent, Pagination, Rating, Button, Skeleton, MenuItem,
    useTheme,
    Chip,
    Slider,
    TextField,
    Drawer,
    DrawerProps,
    IconButton,
    FormControlLabel,
    Autocomplete,
    Badge,
    Checkbox,
    Tooltip,
    Switch,
    MenuList,
    Alert,
    Divider
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useGetAdsPlaGlobalQuery, useGetBrandsQuery, useGetKeywordsPlaTxtQuery, useGetMinDateCompetitorQuery, useGetCountriesPlaTxtQuery } from 'src/context/api/competitorsMonitoring/api';
import { useSelector } from 'react-redux';
import { fDate } from 'src/utils/format-time';
import { useLocales } from 'src/locales';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { setFilterAdvertizerPLA, setFilterAveragePositionPLA, setFilterBrandPLA, setFilterCountry, setFilterDomainPLA, setFilterImpressionSharePLA, setFilterKeyword, setFilterLoadedCountry, setFilterLoadedKeyword, setFilterMinDatePicker, setFilterPricingRangePLA, setFilterRatingRangePLA, setNotificationBrandInfringement, setNotificationBrandInfringementNotification } from 'src/context/reducers/filter-settings/filterSlice';
import { useDispatch } from 'react-redux';
import { FlagIcon } from 'src/components/iconify/flagIcon';
import countriesJson from "../../../../utils/countries.json"
import CustomPopover from 'src/components/custom-popover';
import { ComponentBlockCustom } from 'src/sections/mui/component-block-custom';

const FilterDrawerContext = createContext({
    open: false,
    toggleDrawer: () => { },
    onClose: () => { },
    onReset: () => { }
});

export const useFilterDrawer = () => useContext(FilterDrawerContext);

type Props = DrawerProps & {
    favorited?: boolean;
};

export default function GlobalPlaAds() {
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [filterCountry, setFilterCountryState] = useState<any>(undefined);
    const [displayFilterPricing, setDisplayFilterPricing] = useState(false)
    const [displayFilterImpressionShare, setDisplayFilterImpressionShare] = useState(false)
    const [displayFilterAveragePosition, setDisplayFilterAveragePosition] = useState(false)

    const adsPerPage = 30;
    const filterStore = useSelector((state: any) => state.filters);
    const [selectedAds, setSelectedAds]: any = useState([]);
    const theme = useTheme();
    const { t } = useLocales();
    const workspace: any = useCurrentWorkspace();
    const dispatch = useDispatch()
    const [brandInfringement, setBrandInfringement] = useState(false);
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortBy, setSortBy] = useState('impressionShare');
    const pageSize = 30;

    const { data: keywordsData, error: keywordsError, isFetching: isFetchingKeywords } = useGetKeywordsPlaTxtQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        adType: "PLA",
        countries: filterStore.countries,
        workspaceId: workspace?.id
    }, {
        refetchOnMountOrArgChange: true,
    });

    const marks = {
        rating: [
            { value: 0, label: '0' },
            { value: 1, label: '1' },
            { value: 2, label: '2' },
            { value: 3, label: '3' },
            { value: 4, label: '4' },
            { value: 5, label: '5' },
        ]
    };

    const { data: minDateData, error: minDateError, isFetching: isFetchingMinDate } = useGetMinDateCompetitorQuery({
        domain: "all",
        workspaceTable: workspace?.bqTable,
        workspaceId: workspace?.id,
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
    });

    useEffect(() => {
        if (minDateData) {
            dispatch(setFilterMinDatePicker(minDateData.minDate));
        }
    }, [minDateData, dispatch]);

    const { data: ads, error: statsError, isFetching } = useGetAdsPlaGlobalQuery({
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        device: filterStore.devices.map((device: string) => device.toLowerCase()),
        countries: filterStore.countries,
        domain: "all",
        workspaceTable: workspace?.bqTable,
        page: currentPage,
        pageSize: pageSize,
        otherDomains: workspace?.friendlyDomains,
        keywords: filterStore?.keywords,
        brands: filterStore?.brandsPLA,
        advertizer: filterStore?.advertizersPLA,
        domains: filterStore?.domainsPLA,
        ratingRange: filterStore?.ratingRangePLA,
        priceRange: filterStore?.pricingRangePLA || [0, 50000],
        impressionShare: filterStore?.impressionSharePLA,
        position: filterStore?.averagePositionPLA,
        sortBy: sortBy,
        sortOrder: sortOrder,
        search: searchQuery
    });

    const { data: brands, error: brandsError, isLoading: isLoadingBrands, isFetching: isFetchingBrands } = useGetBrandsQuery({
        bqTable: workspace?.bqTable,
    });

    useEffect(() => {
        dispatch(setFilterLoadedKeyword([]));
    }, [])

    useEffect(() => {
        dispatch(setFilterLoadedKeyword([]));

        if (!isFetchingKeywords) {
            dispatch(setFilterLoadedKeyword(keywordsData?.filters?.keywords))
        }
    }, [keywordsData, workspace])

    const { data: adsNotificationBrandInfringement, error: adsNotificationBrandInfringementError, isFetching: isFetchingAdsNotificationBrandInfringement, isLoading: isLoadingAdsNotificationBrandInfringement }: any = useGetAdsPlaGlobalQuery({
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        device: filterStore.devices.map((device: string) => device.toLowerCase()),
        countries: filterStore.countries,
        domain: "all",
        workspaceTable: workspace?.bqTable,
        page: currentPage,
        pageSize: pageSize,
        keywords: filterStore?.keywords,
        brands: brands,
        otherDomains: workspace?.friendlyDomains,
        advertizer: filterStore?.advertizersPLA,
        impressionShare: filterStore?.impressionSharePLA,
        domains: filterStore?.domainsPLA,
        ratingRange: filterStore?.ratingRangePLA,
        priceRange: filterStore?.pricingRangePLA || [0, 50000],
        position: filterStore?.averagePositionPLA,
        sortBy: sortBy,
        sortOrder: sortOrder,
        search: searchQuery
    });

    useEffect(() => {
        if (adsNotificationBrandInfringement === undefined) {
        } else {
            if (adsNotificationBrandInfringement?.ads?.length !== 0)
                if (filterStore?.brandsPLA?.length === 0)
                    dispatch(setNotificationBrandInfringement(true))
            dispatch(setNotificationBrandInfringementNotification(adsNotificationBrandInfringement))
        }

    }, [adsNotificationBrandInfringement])

    const handleRatingRangeChange = (event: any, newValue: any) => {
        dispatch(setFilterRatingRangePLA(newValue));
    };

    const handleSearch = (event: any) => {
        setSearchQuery(event.target.value);
    };

    const handleAdHoverEnter = (index: any) => {
        setSelectedAds([index]);
    };

    const handleAdHoverLeave = () => {
        setSelectedAds([]);
    };

    const truncateText = (text: any, length: any) => {
        if (text?.length > length) {
            return text?.substring(0, length) + '...';
        }
        return text;
    };

    const containsLetters = (str: string) => {
        return /[a-zA-Z]/.test(str);
    }

    const handleKeywordChange = (value: any) => {
        if (Array.isArray(value)) {
            const keywordsArray = value.map((item) => item.search_term);
            dispatch(setFilterKeyword(keywordsArray));
        } else if (value && value.search_term) {
            dispatch(setFilterKeyword([value.search_term]));
        } else {
            dispatch(setFilterKeyword([]));
        }
    };

    const { data: countriesData } = useGetCountriesPlaTxtQuery({
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        adType: "PLA"
    }, {
        refetchOnMountOrArgChange: false,
    });

    const { countries } = useMemo(() => {
        return { countries: countriesData?.countries }
    }, [countriesData])

    useEffect(() => {
        if (countries) {
            setFilterCountryState(countries);
            dispatch(setFilterLoadedCountry(countriesData?.countries))
        }
    }, [countries, filterCountry])

    const [filters, setFilters] = useState<Omit<Props, 'open'> & { open: boolean }>({
        open: false,
        favorited: false,
    });

    const toggleDrawer = () => {
        setFilters((prev: Props) => ({ ...prev, open: !prev.open, favorited: prev.favorited ?? false }));
    };

    const handleClose = () => {
        setFilters((prev: Props) => ({ ...prev, open: false }));
    };

    const handleReset = () => {
        dispatch(setFilterKeyword([]));
        dispatch(setFilterCountry([]));
        dispatch(setFilterBrandPLA([]));
        dispatch(setFilterDomainPLA([]));
        dispatch(setFilterAdvertizerPLA([]));
        dispatch(setFilterRatingRangePLA([0, 5]));
        dispatch(setFilterPricingRangePLA([0, 50000]));
        dispatch(setFilterImpressionSharePLA([0, 100]))
        dispatch(setFilterAveragePositionPLA([0, 50]))
        dispatch(setNotificationBrandInfringement(true))
        setDisplayFilterAveragePosition(false);
        setDisplayFilterImpressionShare(false);
        setDisplayFilterPricing(false);
    };

    const advertizerCounts = (ads?.ads || []).reduce((acc: any, ad: any) => {
        if (ad.seller_name) {
            acc[ad.seller_name] = (acc[ad.seller_name] || 0) + 1;
        }
        return acc;
    }, {});

    const advertizerOptions = Object.keys(advertizerCounts).sort((a, b) => advertizerCounts[b] - advertizerCounts[a]).filter(advertizer => advertizer && advertizer.trim() !== '');

    const handleAdvertizerChange = (event: any, value: any) => {
        dispatch(setFilterAdvertizerPLA(value));
    }

    const handleDomainChange = (event: any, value: any) => {
        dispatch(setFilterDomainPLA(value));
    }

    const handleDeleteKeywordFilter = (index: number) => {
        const updatedKeywords = [...filterStore.keywords];
        updatedKeywords.splice(index, 1);
        dispatch(setFilterKeyword(updatedKeywords));
    };

    const handleDeleteCountryFilter = (index: number) => {
        const updatedCountries = [...filterStore.countries];
        updatedCountries.splice(index, 1);
        dispatch(setFilterCountry(updatedCountries));
    };

    const handleDeleteBrandFilter = () => {
        setBrandInfringement(false);
        dispatch(setFilterBrandPLA([]));
        dispatch(setNotificationBrandInfringement(true))
    };

    const handleDeleteAdvertizerFilter = (index: number) => {
        const updatedAdvertizers = [...filterStore.advertizersPLA];
        updatedAdvertizers.splice(index, 1);
        dispatch(setFilterAdvertizerPLA(updatedAdvertizers));
    }

    const handleDeleteRatingRangeFilter = () => {
        dispatch(setFilterRatingRangePLA([0, 5]));
    }

    const handleClearAllFilter = () => {
        dispatch(setFilterKeyword([]));
        dispatch(setFilterCountry([]));
        dispatch(setFilterDomainPLA([]));
        dispatch(setFilterBrandPLA([]));
        dispatch(setFilterAdvertizerPLA([]));
        dispatch(setNotificationBrandInfringement(true))
        dispatch(setFilterRatingRangePLA([0, 5]));
        dispatch(setFilterImpressionSharePLA([0, 100]))
        dispatch(setFilterAveragePositionPLA([0, 50]))
        setDisplayFilterAveragePosition(false);
        setDisplayFilterImpressionShare(false);

        dispatch(setFilterPricingRangePLA([0, 50000]));
        setDisplayFilterPricing(false);
    }

    const handleDeletePricingRangeFilter = () => {
        dispatch(setFilterPricingRangePLA([0, 50000]));
        setDisplayFilterPricing(false);
    }

    const handleCountryFilterChange = (selectedOptions: any) => {
        const updatedOptions = selectedOptions?.filter((option: string) => option !== t('dashboard.filters.all'));
        dispatch(setFilterCountry(updatedOptions));
    };

    const allKeywords = useMemo(() => {
        return [{ search_term: 'All', total_organic_searches: null }, ...filterStore.loadedKeywords];
    }, [filterStore.loadedKeywords]);

    const handleBrandInfringementChange = (event: any) => {
        const isChecked = event.target.checked;
        setBrandInfringement(isChecked);

        if (isChecked) {
            if (brands && brands.length > 0) {
                dispatch(setFilterBrandPLA(brands));
            }
        } else {
            dispatch(setFilterBrandPLA([]));
            dispatch(setNotificationBrandInfringement(true))
        }
    };

    const sortOptions = [
        { value: 'position', label: 'Position' },
        { value: 'impressionShare', label: 'Impression Share' },
        { value: 'price', label: 'Price' },
        { value: 'review', label: 'Review' },
        { value: 'rating', label: 'Rating' },
    ];

    const orderOptions = [
        { value: 'asc', label: 'Ascending' },
        { value: 'desc', label: 'Descending' },
    ];

    const [anchorElSort, setAnchorElSort] = useState(null);
    const [anchorElOrder, setAnchorElOrder] = useState(null);

    const popoverSort: any = {
        open: Boolean(anchorElSort),
        anchorEl: anchorElSort,
        onOpen: (event: any) => {
            setAnchorElSort(event.currentTarget);
        },
        onClose: () => {
            setAnchorElSort(null);
        },
    };

    const popoverOrder: any = {
        open: Boolean(anchorElOrder),
        anchorEl: anchorElOrder,
        onOpen: (event: any) => {
            setAnchorElOrder(event.currentTarget);
        },
        onClose: () => {
            setAnchorElOrder(null);
        },
    };

    const handleSortChange = (newValue: any) => {
        setSortBy(newValue);
    };

    const handleOrderChange = (newValue: any) => {
        setSortOrder(newValue);
    };

    const handleActionAlertBrandInfringement = () => {
        dispatch(setFilterBrandPLA(brands))
        dispatch(setNotificationBrandInfringement(false))
    }

    const handleDeleteImpressionShareFilter = () => {
        dispatch(setFilterImpressionSharePLA([0, 100]))
        setDisplayFilterImpressionShare(false);
    }

    const handleDeleteAveragePositionFilter = () => {
        dispatch(setFilterAveragePositionPLA([0, 50]))
        setDisplayFilterAveragePosition(false);
    }

    return (
        <FilterDrawerContext.Provider value={{
            open: filters.open,
            toggleDrawer,
            onClose: handleClose,
            onReset: handleReset
        }}>
            <Container maxWidth="xl">
                <Stack spacing={3}>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <FormControl sx={{ my: 1, p: 0, position: 'relative' }}>
                            <OutlinedInput
                                sx={{ width: '100%' }}
                                id="outlined-adornment-amount"
                                placeholder={t('dashboard.textAdsPage.search_placeholder')}
                                size="small"
                                value={searchQuery}
                                onChange={handleSearch}
                                startAdornment={<InputAdornment position="start"><Iconify icon={"eva:search-fill"} /></InputAdornment>}
                            />
                        </FormControl>
                        <Box gap={2} sx={{ display: 'flex', }}>
                            <Box>
                                <Button
                                    disableRipple
                                    color="inherit"
                                    onClick={(event) => popoverSort.onOpen(event)}
                                    endIcon={
                                        <Iconify
                                            icon={
                                                popoverSort.open
                                                    ? 'eva:arrow-ios-upward-fill'
                                                    : 'eva:arrow-ios-downward-fill'
                                            }
                                        />
                                    }
                                    sx={{ fontWeight: 'fontWeightSemiBold' }}
                                >
                                    Sort by:
                                    <Box
                                        component="span"
                                        sx={{
                                            ml: 0.5,
                                            fontWeight: 'fontWeightBold',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {sortOptions.find((option) => option.value === sortBy)?.label}
                                    </Box>
                                </Button>

                                <CustomPopover
                                    open={popoverSort.open}
                                    anchorEl={popoverSort.anchorEl}
                                    onClose={popoverSort.onClose}
                                >
                                    <MenuList>
                                        {/* Vos options de tri */}
                                        {sortOptions.map((option: any) => (
                                            <MenuItem
                                                key={option.value}
                                                selected={option.value === sortBy}
                                                onClick={() => {
                                                    popoverSort.onClose();
                                                    handleSortChange(option.value);
                                                }}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </CustomPopover>
                            </Box>

                            <Box>
                                <Button
                                    disableRipple
                                    color="inherit"
                                    onClick={(event) => popoverOrder.onOpen(event)}
                                    endIcon={
                                        <Iconify
                                            icon={
                                                popoverOrder.open
                                                    ? 'eva:arrow-ios-upward-fill'
                                                    : 'eva:arrow-ios-downward-fill'
                                            }
                                        />
                                    }
                                    sx={{ fontWeight: 'fontWeightSemiBold' }}
                                >
                                    Order by:
                                    <Box
                                        component="span"
                                        sx={{
                                            ml: 0.5,
                                            fontWeight: 'fontWeightBold',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
                                    </Box>
                                </Button>

                                <CustomPopover
                                    open={popoverOrder.open}
                                    anchorEl={popoverOrder.anchorEl}
                                    onClose={popoverOrder.onClose}
                                >
                                    <MenuList>
                                        {orderOptions.map((option: any) => (
                                            <MenuItem
                                                key={option.value}
                                                selected={option.value === sortOrder}
                                                onClick={() => {
                                                    popoverOrder.onClose();
                                                    handleOrderChange(option.value);
                                                }}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </CustomPopover>
                            </Box>

                            <Button onClick={toggleDrawer}>
                                {t('dashboard.textAdsPage.filters')}
                                <Badge
                                    color="error"
                                    variant="dot"
                                    invisible={
                                        !(
                                            filterStore?.keywords?.length ||
                                            filterStore?.domainsPLA?.length ||
                                            filterStore?.countries?.length ||
                                            filterStore?.brandsPLA?.length ||
                                            filterStore?.advertizersPLA?.length ||
                                            displayFilterPricing ||
                                            displayFilterImpressionShare ||
                                            displayFilterAveragePosition ||
                                            (filterStore?.ratingRangePLA[0] !== 0 || filterStore?.ratingRangePLA[1] !== 5)
                                        )
                                    }
                                    sx={{
                                        '& .MuiBadge-dot': {
                                            zIndex: 1,
                                        },
                                    }}
                                >
                                    <Iconify sx={{ ml: 0.5 }} icon={'mynaui:filter'} />
                                </Badge>
                            </Button>
                        </Box>
                    </Box>
                </Stack>

                <Box sx={{ position: 'relative', mt: 2 }}>
                    <Box>
                        {ads ? `${ads?.total_count} result${ads?.total_count === 1 ? '' : 's'} found` : '0 result found'}
                    </Box>

                    <Box sx={{ mt: 1.5 }}>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>

                            {filterStore?.keywords?.length !== 0 && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        border: '2px dotted rgb(145 158 171 / 0.2)',
                                        borderRadius: 1,
                                        p: 1,
                                    }}
                                >
                                    <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: 0.5 }}>
                                        Keywords:
                                    </Typography>
                                    {filterStore?.keywords?.slice(0, 2).map((keyword: any, index: number) => (
                                        <Chip
                                            key={index}
                                            size="small"
                                            label={keyword}
                                            sx={{ position: 'relative', ml: 1 }}
                                            onDelete={() => handleDeleteKeywordFilter(index)}
                                        />
                                    ))}
                                    {filterStore?.keywords?.length > 2 && (
                                        <Chip
                                            size="small"
                                            label={`+${filterStore.keywords.length - 2}`}
                                            sx={{
                                                position: 'relative',
                                                ml: 1,

                                            }}
                                        />
                                    )}
                                </Box>
                            )}

                            {filterStore?.countries?.length !== 0 &&
                                <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                    <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Countries:</Typography>
                                    {filterStore?.countries?.map((countrie: any, index: any) => (
                                        <Chip
                                            key={index}
                                            size='small'
                                            label={countrie}
                                            variant='soft'
                                            sx={{ position: 'relative', ml: 1 }}
                                            onDelete={() => handleDeleteCountryFilter(index)}
                                        />
                                    ))}
                                </Box>
                            }

                            {filterStore?.brandsPLA?.length !== 0 &&
                                <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                    <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Brand Protection:</Typography>
                                    <Chip
                                        size='small'
                                        variant='soft'
                                        label={"Infringement"}
                                        sx={{ position: 'relative', ml: 1 }}
                                        onDelete={() => handleDeleteBrandFilter()}
                                    />
                                </Box>
                            }

                            {filterStore?.advertizersPLA?.length !== 0 &&
                                <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                    <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Advertizers:</Typography>
                                    {filterStore?.advertizersPLA?.map((advertizer: any, index: any) => (
                                        <Chip
                                            key={index}
                                            size='small'
                                            label={advertizer}
                                            variant='soft'
                                            sx={{ position: 'relative', ml: 1 }}
                                            onDelete={() => handleDeleteAdvertizerFilter(index)}
                                        />
                                    ))}
                                </Box>
                            }


                            {filterStore?.domainsPLA?.length !== 0 &&
                                <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                    <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Competitors domains:</Typography>
                                    {filterStore?.domainsPLA?.map((domain: any, index: any) => (
                                        <Chip
                                            key={index}
                                            size='small'
                                            label={domain}
                                            variant='soft'
                                            sx={{ position: 'relative', ml: 1 }}
                                            onDelete={() => console.log(index)}
                                        />
                                    ))}
                                </Box>
                            }

                            {(filterStore?.ratingRangePLA[0] !== 0 || filterStore?.ratingRangePLA[1] !== 5) &&
                                <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                    <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Rating:</Typography>
                                    <Chip
                                        size='small'
                                        variant='soft'
                                        icon={<Iconify icon="mdi:star" />}
                                        label={`${filterStore.ratingRangePLA[0]} - ${filterStore.ratingRangePLA[1]}`}
                                        sx={{
                                            position: 'relative',
                                            ml: 1,
                                            '& .MuiChip-icon': {
                                                color: 'gold',
                                                fontSize: '1rem',
                                            },
                                        }}
                                        onDelete={() => handleDeleteRatingRangeFilter()}
                                    />
                                </Box>
                            }

                            {displayFilterPricing &&
                                <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                    <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Price:</Typography>
                                    <Chip
                                        size='small'
                                        variant='soft'
                                        label={`${filterStore?.pricingRangePLA[0]} - ${filterStore?.pricingRangePLA[1]}`}
                                        sx={{ position: 'relative', ml: 1 }}
                                        onDelete={() => handleDeletePricingRangeFilter()}
                                    />
                                </Box>
                            }

                            {displayFilterImpressionShare &&
                                <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                    <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Impression Share:</Typography>
                                    <Chip
                                        size='small'
                                        variant='soft'
                                        label={`${filterStore?.impressionSharePLA[0]}% - ${filterStore?.impressionSharePLA[1]}%`}
                                        sx={{ position: 'relative', ml: 1 }}
                                        onDelete={() => handleDeleteImpressionShareFilter()}
                                    />
                                </Box>
                            }

                            {displayFilterAveragePosition &&
                                <Box sx={{ display: 'flex', flexDirection: 'row', border: '2px dotted rgb(145 158 171 / 0.2)', borderRadius: 1, p: 1 }}>
                                    <Typography sx={{ fontSize: '0.875rem', position: 'relative', top: .5 }}>Position:</Typography>
                                    <Chip
                                        size='small'
                                        variant='soft'
                                        label={`${filterStore?.averagePositionPLA[0]} - ${filterStore?.averagePositionPLA[1]}`}
                                        sx={{ position: 'relative', ml: 1 }}
                                        onDelete={() => handleDeleteAveragePositionFilter()}
                                    />
                                </Box>
                            }

                            {(displayFilterImpressionShare || displayFilterAveragePosition || filterStore?.domainsPLA?.length !== 0 || filterStore?.keywords?.length !== 0 || filterStore?.countries?.length !== 0 || filterStore?.brandsPLA?.length !== 0 || filterStore?.advertizersPLA?.length !== 0 || displayFilterPricing) &&
                                <Box>
                                    <Button
                                        onClick={handleClearAllFilter}
                                        startIcon={<Iconify icon="eva:trash-2-outline" />}
                                        sx={{ color: 'error.main', fontWeight: 'bold', textTransform: 'none', mt: .5 }}
                                    >
                                        Clear
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>

                {workspace?.showPlaAlert &&
                    <>
                        {!brandInfringement &&
                            <>
                                {!isFetchingAdsNotificationBrandInfringement &&
                                    adsNotificationBrandInfringement?.ads?.length !== 0 &&
                                    filterStore?.notificationBrandInfringement !== false && (
                                        <Box>
                                            <Alert
                                                sx={{ my: 2, backgroundColor: theme.palette.mode === "dark" ? '#322f33' : '#FFF2EF', color: theme.palette.mode === "dark" ? '#e0ac8a' : '#B71D18' }}
                                                severity="error"
                                                action={
                                                    <>
                                                        <Button
                                                            color="inherit"
                                                            size="small"
                                                            sx={{
                                                                mr: 1,
                                                                backgroundColor: theme.palette.mode === "dark" ? '#a82e24' : "#FF5630",
                                                                color: 'white',
                                                            }}
                                                            onClick={handleActionAlertBrandInfringement}
                                                        >
                                                            Show
                                                        </Button>
                                                    </>
                                                }
                                            >
                                                <Typography fontSize={'.9rem'}>
                                                    Brand Infringement Alert: Some competitors are using your Brand in their Product Listing Ads Content
                                                </Typography>
                                            </Alert>
                                        </Box>
                                    )}
                            </>
                        }
                    </>
                }

                <Grid container sx={{ mt: 2 }}>
                    {/* Ads area */}
                    <Grid container spacing={2} columns={10}>
                        {isFetching || (!ads && !statsError) ? (
                            Array.from(new Array(adsPerPage)).map((_, index) => (
                                <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={1}>
                                    <Skeleton variant="rectangular" width="100%" height={315} style={{ borderRadius: 10 }} />
                                </Grid>
                            ))
                        ) : (
                            ads?.ads.length > 0 ? (
                                ads?.ads.map((ad: any, index: any) => (
                                    <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={1}>
                                        <Card
                                            sx={{
                                                height: '100%',
                                                bgcolor: selectedAds.includes(index) ? theme.palette.mode === "dark" ? '#1c252e' : 'white' : 'none',
                                                color: selectedAds.includes(index) ? theme.palette.mode === "dark" ? '#fff' : "#202124" : '#000',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                borderRadius: 2,
                                                overflow: 'hidden',
                                                cursor: 'pointer'
                                            }}
                                            onMouseEnter={() => handleAdHoverEnter(index)}
                                            onMouseLeave={handleAdHoverLeave}
                                        >
                                            {selectedAds.includes(index) ? (
                                                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                    <CardContent>
                                                        <Tooltip title={`Impression Share`} arrow>
                                                            <Box sx={{ textAlign: 'center', mb: 2, border: 2, borderRadius: 2, borderColor: theme.palette.mode === "dark" ? "#212a33" : "#6f777d", p: 2 }}>
                                                                <Iconify
                                                                    sx={{ width: 25, height: 25 }}
                                                                    icon={"mdi:eye"}
                                                                />
                                                                <Typography variant="h6" sx={{ mt: 1 }}>
                                                                    {(ad?.impression_share * 100)?.toFixed(2) + "%"}
                                                                </Typography>
                                                            </Box>
                                                        </Tooltip>
                                                        <Tooltip title={`Average Position`} arrow>
                                                            <Box sx={{ textAlign: 'center', mb: 2, border: 2, borderRadius: 2, borderColor: theme.palette.mode === "dark" ? "#212a33" : "#6f777d", p: 2 }}>
                                                                <Iconify
                                                                    sx={{ width: 25, height: 25 }}
                                                                    icon={"mdi:podium-gold"}
                                                                />
                                                                <Typography variant="h6" sx={{ mt: 1 }}>
                                                                    {ad?.item_position}
                                                                </Typography>
                                                            </Box>
                                                        </Tooltip>
                                                        <Button onClick={() => window.open(ad?.link)} sx={{ flexDirection: 'column', textAlign: 'center', mb: 2, border: 2, width: "100%", borderRadius: 2, borderColor: theme.palette.mode === "dark" ? "#212a33" : "#6f777d", p: 2 }}>
                                                            <Iconify
                                                                sx={{ width: 25, height: 25 }}
                                                                icon={"mingcute:external-link-fill"}
                                                            />
                                                            <Typography variant="body2" fontSize={15} sx={{ fontWeight: 'bold', mt: 1 }}>
                                                                {t('dashboard.textAdsPage.Visit')}
                                                            </Typography>
                                                        </Button>
                                                    </CardContent>
                                                </Box>
                                            ) : (
                                                <>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '180px', overflow: 'hidden', backgroundColor: '#f8f8f8' }}>
                                                        <CardMedia
                                                            component="img"
                                                            image={ad?.image || "/assets/no_img.png"}
                                                            alt={ad?.title}
                                                            sx={{ maxHeight: '100%', objectFit: 'cover' }}
                                                        />
                                                    </Box>
                                                    <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'left', p: 1, backgroundColor: theme.palette.mode === "dark" ? '#1c252e' : "white" }}>
                                                        <Box>
                                                            <Typography sx={{
                                                                '&:hover': {
                                                                    textDecoration: 'underline'
                                                                },
                                                            }} fontSize={16} color={theme.palette.mode === "dark" ? "#99c3ff" : "#1a0dab"} gutterBottom>
                                                                {truncateText(ad?.title, 45)}
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            {ad?.price && (
                                                                <Typography variant="h6" color={theme.palette.mode === "dark" ? "#dddddd" : "#202124"}>
                                                                    {ad?.price}
                                                                </Typography>
                                                            )}

                                                            {ad?.seller_name && (
                                                                <Typography sx={{ mt: 1 }} variant="body2" color={theme.palette.mode === "dark" ? "#dddddd" : "#70757a"}>
                                                                    {ad?.seller_name}
                                                                </Typography>
                                                            )}

                                                            {ad?.shipping && (
                                                                <Typography sx={{ mt: 1 }} variant="body2" color="text.secondary">
                                                                    {ad?.shipping}
                                                                </Typography>
                                                            )}

                                                            {(ad?.rating && containsLetters(ad?.rating)) && (
                                                                <Typography sx={{ mt: 1 }} fontSize={14} color="#dddddd">
                                                                    {ad?.rating}
                                                                </Typography>
                                                            )}

                                                            {(ad?.rating && ad?.reviews_count) && (
                                                                <Box sx={{ display: 'flex', mt: 1, flexDirection: 'row' }}>
                                                                    <Rating size='small' name="read-only" value={parseFloat(ad?.rating)} readOnly />
                                                                    <Typography fontSize={14} sx={{ ml: .4 }} color={theme.palette.mode === "dark" ? "#dddddd" : "#70757a"}>({parseInt(ad?.reviews_count)?.toFixed(0)})</Typography>
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </CardContent>
                                                </>
                                            )}
                                        </Card>
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12}>
                                    <Typography variant="h6" color="text.secondary" align="center">
                                        {t('dashboard.textAdsPage.no_results')}
                                    </Typography>
                                </Grid>
                            )
                        )}
                    </Grid>
                </Grid>
                {ads?.ads?.length !== 0 &&
                    <Stack spacing={2} sx={{ mt: 5 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <Pagination
                                count={ads?.total_pages}
                                page={currentPage}
                                onChange={(event: any, value: any) => setCurrentPage(value)}
                                color="primary"
                            />
                        </Box>
                    </Stack>
                }
            </Container>
            <Drawer
                anchor="right"
                open={filters.open}
                onClose={handleClose}
                slotProps={{
                    backdrop: { invisible: true },
                }}
                PaperProps={{
                    sx: { width: 320 },
                }}
            >
                <Box sx={{ overflowX: 'hidden' }}>
                    <Stack spacing={3} sx={{ width: '100%' }}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ px: 2, pt: 2 }}
                        >
                            <Typography variant="h6">{t('dashboard.textAdsPage.filters')}</Typography>
                            <Box>
                                <IconButton onClick={handleReset}>
                                    <Badge
                                        color="error"
                                        variant="dot"
                                        invisible={
                                            !(
                                                filterStore?.keywords?.length ||
                                                filterStore?.countries?.length ||
                                                filterStore?.domainsPLA?.length ||
                                                filterStore?.brandsPLA?.length ||
                                                filterStore?.advertizersPLA?.length ||
                                                displayFilterPricing ||
                                                displayFilterImpressionShare ||
                                                displayFilterAveragePosition ||
                                                (filterStore?.ratingRangePLA[0] !== 0 || filterStore?.ratingRangePLA[1] !== 5)
                                            )
                                        }
                                        sx={{
                                            '& .MuiBadge-dot': {
                                                zIndex: 1,
                                            },
                                        }}
                                    >
                                        <Iconify icon="solar:restart-bold" />
                                    </Badge>
                                </IconButton>

                                <IconButton onClick={handleClose}>
                                    <Iconify icon="mingcute:close-line" />
                                </IconButton>
                            </Box>
                        </Stack>

                        <Divider
                            sx={{
                                borderColor: 'rgba(145, 158, 171, 0.2)', // Gris plus foncé
                                borderWidth: '1px', // Ligne plus épaisse
                                mb: 1
                            }}
                            variant="fullWidth"
                        />

                        <Stack spacing={3} px={2}>

                            <Autocomplete
                                size='small'
                                disableClearable
                                multiple
                                noOptionsText={isFetchingKeywords ? "Loading..." : "No options"}
                                options={filterStore?.loadedKeywords || []}
                                getOptionLabel={(option) => option.search_term}
                                value={
                                    filterStore.keywords.length === 0
                                        ? [allKeywords[0]] // L'option "All"
                                        : allKeywords.filter(option => filterStore.keywords.includes(option.search_term))
                                }
                                onChange={(event, newValue) => handleKeywordChange(newValue)}
                                renderTags={(value, getTagProps) => {
                                    const selectedOptions = value || [];

                                    if (selectedOptions.length > 2) {
                                        return (
                                            <>
                                                {selectedOptions.slice(0, 2).map((option, index) => (
                                                    <Chip
                                                        label={option.search_term}
                                                        size="small"
                                                        {...getTagProps({ index })}
                                                    />
                                                ))}
                                                <Chip
                                                    label={`+${selectedOptions.length - 2}`}
                                                    size="small"
                                                    variant="soft"
                                                    sx={{ color: '#919eab', marginLeft: .3 }}
                                                />
                                            </>
                                        );
                                    } else if (selectedOptions.length > 0) {
                                        return selectedOptions.map((option, index) => (
                                            <Chip
                                                label={option.search_term}
                                                size="small"
                                                variant='soft'
                                                {...getTagProps({ index })}
                                                deleteIcon={option?.search_term === t('dashboard.filters.all') ? <></> : undefined}
                                            />
                                        ));
                                    } else {
                                        return (
                                            <Chip
                                                label="All"
                                                size="small"
                                                variant='soft'
                                            />
                                        );
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Keywords"
                                    />
                                )}
                            />

                            <Autocomplete
                                multiple={true}
                                size="small"
                                limitTags={1}
                                disableCloseOnSelect
                                options={filterCountry || []}
                                getOptionLabel={(option: any) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('dashboard.filters.countries')}
                                    />
                                )}
                                value={
                                    filterStore?.countries?.length === 0
                                        ? [t('dashboard.filters.all')]
                                        : filterStore?.countries
                                }
                                renderOption={(props, option) => {
                                    const country = countriesJson?.find((c) => c.name === option);
                                    const flagIcon = country ? `circle-flags:${country.code}` : null;

                                    return (
                                        <li {...props} style={{ whiteSpace: 'nowrap' }} key={option}>
                                            {option !== t('dashboard.filters.all') && (
                                                <>
                                                    <Checkbox
                                                        checked={filterStore?.countries.includes(option)}
                                                    />
                                                    {
                                                        flagIcon ? (
                                                            <Iconify icon={flagIcon} width={16} sx={{ mr: 1 }} />
                                                        ) : (
                                                            <img style={{ width: 14, height: 14, marginRight: 6 }} src="/assets/location.png" />
                                                        )
                                                    }
                                                </>


                                            )}
                                            {option}
                                        </li>
                                    );
                                }}
                                onChange={(event, selectedOptions) => handleCountryFilterChange(selectedOptions)}
                                renderTags={(selected, getTagProps) =>
                                    selected.map((option, index) => {
                                        const country = countriesJson?.find((c) => c.name === option);
                                        const flagCode = country ? country.code : null;

                                        return (
                                            <Chip
                                                {...getTagProps({ index })}
                                                key={option}
                                                icon={
                                                    option !== t('dashboard.filters.all') ? (
                                                        flagCode ? (
                                                            <FlagIcon
                                                                key={flagCode}
                                                                code={flagCode}
                                                                sx={{ width: 16, height: 16, borderRadius: '50%' }}
                                                            />
                                                        ) : (
                                                            <img style={{ width: 14, height: 14, marginRight: 1 }} src="/assets/location.png" />
                                                        )
                                                    ) : undefined
                                                }
                                                label={option}
                                                size="small"
                                                deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                                                variant="soft"
                                            />
                                        );
                                    })
                                }
                            />

                            <Autocomplete
                                size="small"
                                disableClearable
                                multiple={true}
                                value={
                                    filterStore?.advertizersPLA?.length === 0
                                        ? ["All"] // Valeur spéciale "All" lorsqu'il n'y a pas de sélection
                                        : filterStore?.advertizersPLA
                                }
                                noOptionsText={advertizerOptions ? "Loading..." : "No options"}
                                options={advertizerOptions ? advertizerOptions : []}
                                onChange={(event, newValue) => {
                                    // Filtrer pour ne pas permettre la sélection explicite de "All"
                                    const filteredValue = newValue.filter((value) => value !== "All");
                                    handleAdvertizerChange(event, filteredValue.length > 0 ? filteredValue : []);
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            {...getTagProps({ index })}
                                            key={option}
                                            label={option}
                                            variant='soft'
                                            size="small"
                                            onDelete={
                                                option === "All" ? undefined : getTagProps({ index }).onDelete
                                            }
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Seller Name"
                                    />
                                )}
                            />

                            <Autocomplete
                                size="small"
                                disableClearable
                                multiple={true}
                                value={
                                    filterStore?.domainsPLA?.length === 0
                                        ? ["All"] // Valeur spéciale "All" lorsqu'il n'y a pas de sélection
                                        : filterStore?.domainsPLA
                                }
                                noOptionsText={ads?.domains ? "Loading..." : "No options"}
                                options={ads?.domains ? ads?.domains : []}
                                onChange={(event, newValue) => {
                                    // Filtrer pour ne pas permettre la sélection explicite de "All"
                                    const filteredValue = newValue.filter((value) => value !== "All");
                                    handleDomainChange(event, filteredValue.length > 0 ? filteredValue : []);
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            {...getTagProps({ index })}
                                            key={option}
                                            label={option}
                                            size="small"
                                            variant={'soft'} // Style spécifique pour "All"
                                            onDelete={
                                                option === "All" ? undefined : getTagProps({ index }).onDelete
                                            }
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Competitors Domains"
                                    />
                                )}
                            />

                            {workspace?.showPlaAlert &&
                                <ComponentBlockCustom title="Brand Protection">
                                    <FormControlLabel
                                        sx={{ p: 1.5, mt: .5 }}
                                        control={
                                            <Switch
                                                checked={filterStore?.brandsPLA?.length !== 0}
                                                onChange={handleBrandInfringementChange}
                                            />
                                        }
                                        label={"Brand Infringement"}
                                    />
                                </ComponentBlockCustom>
                            }


                            <ComponentBlockCustom sx={{ pt: 3, px: 2 }} title="Price">

                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        type="number"
                                        label={'From'}
                                        value={filterStore?.pricingRangePLA[0]}
                                        onChange={(e) => {
                                            const newValue = Number(e.target.value) || 0; // Assurez que la valeur est un nombre
                                            dispatch(setFilterPricingRangePLA([newValue, filterStore?.pricingRangePLA[1]]));
                                            setDisplayFilterPricing(true);
                                        }}
                                    />
                                    <TextField
                                        size="small"
                                        fullWidth
                                        type="number"
                                        label={'To'}
                                        value={filterStore?.pricingRangePLA[1]}
                                        onChange={(e) => {
                                            const newValue = Number(e.target.value) || 50000; // Assurez que la valeur est un nombre
                                            dispatch(setFilterPricingRangePLA([filterStore?.pricingRangePLA[0], newValue]));
                                            setDisplayFilterPricing(true);
                                        }}
                                    />
                                </Box>
                            </ComponentBlockCustom>


                            <ComponentBlockCustom sx={{ pt: 3, px: 4 }} title="Rating">
                                <Slider
                                    value={filterStore?.ratingRangePLA}
                                    onChange={handleRatingRangeChange}
                                    marks={marks.rating}
                                    sx={{ mt: 3 }}
                                    valueLabelDisplay="on"
                                    min={0}
                                    max={5}
                                    step={1}
                                />
                            </ComponentBlockCustom>

                            <ComponentBlockCustom sx={{ pt: 3, px: 4 }} title="Impression Share">
                                <Slider
                                    value={filterStore?.impressionSharePLA || [0, 100]}
                                    onChange={(e, newValue) => {
                                        dispatch(setFilterImpressionSharePLA(newValue));
                                        setDisplayFilterImpressionShare(true)
                                    }}
                                    valueLabelDisplay="on"
                                    min={0}
                                    max={100}
                                    sx={{ mt: 3 }}
                                    valueLabelFormat={(value) => `${value}%`}
                                    step={1}
                                    marks={Array.from({ length: 11 }, (_, i) => ({
                                        value: i * 20,
                                        label: `${i * 20}%`,
                                    }))}
                                />
                            </ComponentBlockCustom>

                            <ComponentBlockCustom sx={{ pt: 3, px: 4, mb: 1 }} title="Position">

                                <Slider
                                    value={filterStore?.averagePositionPLA || [0, 50]}
                                    onChange={(e, newValue) => {
                                        dispatch(setFilterAveragePositionPLA(newValue));
                                        setDisplayFilterAveragePosition(true)
                                    }}
                                    valueLabelDisplay="on"
                                    min={0}
                                    sx={{ mt: 3 }}

                                    max={50}
                                    marks={Array.from({ length: 11 }, (_, i) => ({
                                        value: i * 10,
                                        label: `${i * 10}`,
                                    }))}
                                    step={1}
                                />
                            </ComponentBlockCustom>
                        </Stack>
                    </Stack>
                </Box>
            </Drawer>
        </FilterDrawerContext.Provider>
    );
}