import { useState, useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { fPercent } from 'src/utils/format-number';
import { setFilterCountry } from 'src/context/reducers/filter-settings/filterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/system';
import { useLocales } from 'src/locales';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useResponsive } from 'src/hooks/use-responsive';
import countries from "../../utils/countries.json"
import Iconify from '../iconify';
import { formatCurrency } from 'src/utils/format-currency';
// ----------------------------------------------------------------------

// Fonction pour obtenir le code du pays
const getCountryCode = (countryName: string) => {
  const country = countries.find((c) => c.name.toLowerCase() === countryName.toLowerCase());
  return country ? country.code : '';
};


const createColumns = (theme: any, t: any, lgUp: any, formatCurrency: any, currency: any, locale: any): GridColDef[] => [
  {
    field: 'country',
    headerName: t('dashboard.global.country'),
    flex: lgUp ? 1 : undefined,
    minWidth: 150,  // Définir une largeur minimale appropriée
    renderCell: (params) => {
      const countryCode = getCountryCode(params.row.country);
      return (
        <Box display="flex" alignItems="center" sx={{ display: 'flex', flexDirection: 'row' }}>
          {countryCode
            ? <Iconify sx={{ mr: 1 }} icon={`circle-flags:${countryCode}`} />
            : <img style={{ width: 14, height: 14, marginRight: 10 }} src="/assets/location.png" />
          }
          {params.row.country}
        </Box>
      );
    },
  },
  {
    field: 'brandSearches',
    headerName: t('dashboard.brandTraffic.brandSearches'),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ backgroundColor: theme.palette.mode === "dark" ? '#402D2F' : '#fce1db', padding: 7, color: theme.palette.mode === "dark" ? '#c7866a' : "#b71f1a", borderRadius: 4, fontWeight: 700 }}>
        {params.row.brandSearches}
      </span>
    ),
  },
  {
    field: 'organicBrandTraffic',
    headerName: t('dashboard.brandOverview.organic_traffic'),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ backgroundColor: theme.palette.mode === "dark" ? '#21433a' : '#d8f3e3', padding: 7, color: theme.palette.mode === "dark" ? '#75ea89' : "#118d57", borderRadius: 4, fontWeight: 700 }}>
        {params.row.organicBrandTraffic}
      </span>
    ),
  },
  {
    field: 'paidBrandTraffic',
    headerName: t('dashboard.global.paid_traffic'),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ backgroundColor: theme.palette.mode === "dark" ? '#1c414e' : '#d3f1f7', padding: 7, color: theme.palette.mode === "dark" ? '#61f3f3' : "#06709e", borderRadius: 4, fontWeight: 700 }}>
        {params.row.paidBrandTraffic}
      </span>
    ),
  },
  {
    field: 'uncapturedBrandTraffic',
    headerName: t('dashboard.brandTraffic.uncaptured_brand_traffic'),
    flex: lgUp ? 1 : undefined,
    type: 'number',
    renderCell: (params) => (
      <span style={{ backgroundColor: theme.palette.mode === "dark" ? '#333c47' : '#eaecf0', padding: 7, color: theme.palette.mode === "dark" ? 'white' : "#637381", borderRadius: 4, fontWeight: 700 }}>
        {params.row.uncapturedBrandTraffic}
      </span>
    ),
  },
  {
    field: 'blendedCtr',
    headerName: t('dashboard.global.blended_ctr'),
    flex: lgUp ? 1 : undefined,
    width: 160,
    renderCell: (params) => (
      <span style={{ backgroundColor: theme.palette.mode === "dark" ? '#332b54' : '#eadbfd', padding: 7, color: theme.palette.mode === "dark" ? '#c281fb' : "#5119b7", borderRadius: 4, fontWeight: 700 }}>
        {params.row.blendedCtr === 0 ? '0%' : fPercent(params.row.blendedCtr)}
      </span>
    ),
    type: 'number',
  },
  {
    field: 'adCost',
    headerName: t('dashboard.global.ad_cost'),
    flex: lgUp ? 1 : undefined,
    renderCell: (params) => (
      <span style={{ backgroundColor: theme.palette.mode === "dark" ? '#453f2b' : '#fcefd4', padding: 7, color: theme.palette.mode === "dark" ? '#ffd666' : "#bb750d", borderRadius: 4, fontWeight: 700 }}>
        {formatCurrency(params.row.adCost, currency, locale, true, 1)}
      </span>
    ),
    type: 'number',
  },
];


// ----------------------------------------------------------------------

type Props = {
  data: {
    id: string;
    keyword: string;
  }[];
};

export default function DataGridCustomKeywords({ data }: Props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const { t } = useLocales();
  const workspace: any = useCurrentWorkspace();
  const lgUp = useResponsive('up', 'lg');

  if (!workspace) {
    return <div>Loading...</div>;
  }
  const currency = workspace?.currency || 'USD';
  const lang = localStorage.getItem('i18nextLng');

  const columns = createColumns(theme, t, lgUp, formatCurrency, workspace.currency, lang);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 6,
    page: 0,
  });

 
  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-root': {
          '--unstable_DataGrid-radius': '0px',
        },
        '& .css-jr9x4l-MuiTablePagination-displayedRows': {
          'display': 'none!important'
        },

        '& .css-yrdy0g-MuiDataGrid-columnHeaderRow': {
          background: theme.palette.mode === "dark" ? '#28323c!important' : '#f4f6f8!important', // Appliquer la couleur de fond
          py: 1.75
        },
        '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
          color: theme.palette.mode === "dark" ? '#959fa9!important' : '#637381!important'
        },
        "& .css-lnsnn8-MuiDataGrid-rowCount": {
          display: 'none!important'
        }
      }}
    >
      <DataGridPro
        autoHeight
        disableColumnMenu
        disableDensitySelector
        checkboxSelection={false}
        disableRowSelectionOnClick
        rows={data}
        columns={createColumns(theme, t, lgUp, formatCurrency, workspace.currency, lang)}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
      />
    </Box>
  );
}
