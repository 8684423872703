import { Container, MenuItem, TextField, Typography, useTheme } from "@mui/material";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { useGetStatsDashQuery } from "src/context/api/trafficAnalysis/api";
import ChartBar from "src/sections/charts/chart-bar";
import ChartColumnStacked from "src/sections/charts/chart-column-stacked";
import ChartDonutCustom from "src/sections/charts/chart-donut-custom";
import ChartMixed from "src/sections/charts/chart-mixed";
import BankingWidgetSummary from "./banking-widget-summary";
import { fDate } from "src/utils/format-time";
import Iconify from "src/components/iconify";
import Skeleton from '@mui/material/Skeleton';
import { SplashScreen } from "src/components/loading-screen";
import { useDispatch, useSelector } from "react-redux";
import { MotivationIllustration } from "src/assets/illustrations";
import Welcome from "./welcome";
import { useResponsive } from "src/hooks/use-responsive";
import ChartDonut from "src/sections/charts/chart-donut";
import { differenceInDays } from 'date-fns';
import { useEffect, useState } from "react";
import { setFilterKeyword, setFilterLoadedKeyword, setFilterMinDatePicker } from "src/context/reducers/filter-settings/filterSlice";
import ChartColumnStackedPercent from "src/sections/charts/chart-collumn-stacked-percent";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { useLocales } from "src/locales";
import { useBoolean } from "src/hooks/use-boolean";
import { formatWeekLabel } from "src/utils/dates-labels";
import { usePreviousLocation } from "src/components/previousLocationContext";

export default function trafficAnalysis() {
    const { t, currentLang } = useLocales();
    const theme = useTheme();
    const dispatch = useDispatch();
    const filterStore = useSelector((state: any) => state.filters);
    const lgUp = useResponsive('up', 'lg');
    const [selectedDisplayGraph, setSelectedDisplayGraphValue] = useState('Value');
    const workspace = useCurrentWorkspace();
    const disableWelcome = useBoolean();
    const prevLocation = usePreviousLocation(); // Accéder à l'URL précédente

    useEffect(() => {
        if (prevLocation === "/dash/competitor-focus" || prevLocation === "/dash/competitors-monitoring") {
            dispatch(setFilterLoadedKeyword([]));
            dispatch(setFilterKeyword([]))
        }
    }, [prevLocation])


    enum ColorSchema {
        PRIMARY = 'primary',
        SECONDARY = 'secondary',
        TERTIARY = 'tertiary',
        ERROR = 'error',
        WARNING = 'warning',
        INFO = 'info',
        SUCCESS = 'success'
    }
    const skipQuery = !filterStore.start || !filterStore.end || !workspace?.id;

    const { data: statsData, error: statsError, isFetching } = useGetStatsDashQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        }
    );

    const [customLabels, setCustomLabels] = useState<any>(statsData?.stats.map((item: any) => item?.timestep || '') || []);

    useEffect(() => {
        dispatch(setFilterMinDatePicker(statsData?.minDatePicker[0]?.first_date))
    }, [statsData])

    const getDaysDifference = (startDate: any, endDate: any) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        return differenceInDays(end, start);
    }

    const getTimestep = (startDate: any, endDate: any) => {
        const daysDifference = getDaysDifference(startDate, endDate);

        if (daysDifference > 365) {
            return 'year_month';
        } else if (daysDifference > 90) {
            return 'year_week';
        } else {
            return 'date';
        }
    }

    const { data: sparkLinesData } = useGetStatsDashQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: getTimestep(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null),
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,

        });

    const SparkLinesDataBrandSearches = sparkLinesData?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_organic_clicks || 0) + (item?.total_ad_clicks || 0) + (item?.uncaptured_brand_traffic || 0);
            return { y: sum, x: item?.year_month };
        } else if (daysDifference > 90) {
            const sum = (item?.total_organic_clicks || 0) + (item?.total_ad_clicks || 0) + (item?.uncaptured_brand_traffic || 0);
            return { y: sum, x: item?.year_week };
        } else {
            const sum = (item?.total_organic_clicks || 0) + (item?.total_ad_clicks || 0) + (item?.uncaptured_brand_traffic || 0);

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const SparkLinesDataTotalBrandTraffic = sparkLinesData?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_organic_clicks || 0) + (item?.total_ad_clicks || 0);
            return { y: sum, x: item?.year_month };
        } else if (daysDifference > 90) {
            const sum = (item?.total_organic_clicks || 0) + (item?.total_ad_clicks || 0);
            return { y: sum, x: item?.year_week };
        } else {
            const sum = (item?.total_organic_clicks || 0) + (item?.total_ad_clicks || 0);

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const SparkLinesDataOrganicBrandTraffic = sparkLinesData?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_organic_clicks || 0);
            return { y: sum, x: item?.year_month };
        } else if (daysDifference > 90) {
            const sum = (item?.total_organic_clicks || 0);
            return { y: sum, x: item?.year_week };
        } else {
            const sum = (item?.total_organic_clicks || 0);

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const SparkLinesDataAdBrandTraffic = sparkLinesData?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_ad_clicks || 0);
            return { y: sum, x: item?.year_month };
        } else if (daysDifference > 90) {
            const sum = (item?.total_ad_clicks || 0);
            return { y: sum, x: item?.year_week };
        } else {
            const sum = (item?.total_ad_clicks || 0);

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const SparkLinesDataBlendedCtr = sparkLinesData?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const totalClicks = (item?.total_ad_clicks || 0) + (item?.total_organic_clicks || 0);
            const totalSearches = item?.total_organic_searches || 1;
            const blendedCtr = (totalClicks / totalSearches) * 100;

            return { y: blendedCtr, x: item?.year_month };
        } else if (daysDifference > 90) {
            const totalClicks = (item?.total_ad_clicks || 0) + (item?.total_organic_clicks || 0);
            const totalSearches = item?.total_organic_searches || 1;
            const blendedCtr = (totalClicks / totalSearches) * 100;
            return { y: blendedCtr, x: item?.year_week };
        } else {
            const totalClicks = (item?.total_ad_clicks || 0) + (item?.total_organic_clicks || 0);
            const totalSearches = item?.total_organic_searches || 1;
            const blendedCtr = (totalClicks / totalSearches) * 100;

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');

            return { y: blendedCtr, x: `${year}-${month}-${day}` };
        }
    });

    const SparkLinesDataTotalAdCost = sparkLinesData?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_ad_cost || 0);
            return { y: sum, x: item?.year_month };
        } else if (daysDifference > 90) {
            const sum = (item?.total_ad_cost || 0);
            return { y: sum, x: item?.year_week };
        } else {
            const sum = (item?.total_ad_cost || 0);

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const stats = [
        {
            title: t('dashboard.brandTraffic.brandSearches'),
            value: statsData?.totals.organic_searches,
            compareData: ((statsData?.totals.organic_searches - statsData?.compare_totals.organic_searches) / statsData?.compare_totals.organic_searches) * 100,
            color: ColorSchema.ERROR,
            textColor: '#b71d18',
            spanColor: theme.palette.mode === "dark" ? '#db8377' : 'transparent',
            icon: "icon-park-twotone:search",
            series: SparkLinesDataBrandSearches
        },
        {
            title: t('dashboard.brandTraffic.totalBrandTraffic'),
            value: statsData?.totals.brand_traffic,
            color: ColorSchema.SECONDARY,
            compareData: ((statsData?.totals.brand_traffic - statsData?.compare_totals.brand_traffic) / statsData?.compare_totals.brand_traffic) * 100,
            textColor: '#880e4f',
            icon: "solar:user-circle-bold-duotone",
            spanColor: theme.palette.mode === "dark" ? '#a64b7b' : 'transparent',
            series: SparkLinesDataTotalBrandTraffic
        },
        {
            title: t('dashboard.brandTraffic.organic_brand_traffic'),
            value: statsData?.totals.organic_clicks,
            color: ColorSchema.SUCCESS,
            compareData: ((statsData?.totals.organic_clicks - statsData?.compare_totals.organic_clicks) / statsData?.compare_totals.organic_clicks) * 100,
            textColor: '#118d57',
            spanColor: theme.palette.mode === "dark" ? '#6dad8d' : 'transparent',
            icon: "uim:google",
            series: SparkLinesDataOrganicBrandTraffic

        },
        {
            title: t('dashboard.brandTraffic.ad_brand_traffic'),
            value: statsData?.totals.ad_clicks,
            color: ColorSchema.TERTIARY,
            compareData: ((statsData?.totals.ad_clicks - statsData?.compare_totals.ad_clicks) / statsData?.compare_totals.ad_clicks) * 100,
            textColor: '#006c9c',
            spanColor: theme.palette.mode === "dark" ? '#65b5c9' : 'transparent',
            icon: "cib:google-ads",
            series: SparkLinesDataAdBrandTraffic

        },
        {
            title: t('dashboard.brandTraffic.blendedCtr'),
            value: statsData?.totals.blended_ctr,
            compareData: ((statsData?.totals.blended_ctr - statsData?.compare_totals.blended_ctr) / statsData?.compare_totals.blended_ctr) * 100,
            color: ColorSchema.INFO,
            textColor: '#5119b7',
            icon: "icon-park-twotone:click",
            spanColor: theme.palette.mode === "dark" ? '#9e77da' : 'transparent',
            series: SparkLinesDataBlendedCtr
        },
        {
            title: t('dashboard.global.ad_cost'),
            value: statsData?.totals.ad_cost,
            compareData: ((statsData?.totals.ad_cost - statsData?.compare_totals.ad_cost) / statsData?.compare_totals.ad_cost) * 100,
            color: ColorSchema.WARNING,
            textColor: '#b76e00',
            icon: "solar:money-bag-bold-duotone",
            spanColor: theme.palette.mode === "dark" ? '#ffd066' : 'transparent',
            series: SparkLinesDataTotalAdCost
        },
    ]

    const BrandedTrafficDistributionChart = [];

    if (workspace?.template !== "E-commerce") {
        BrandedTrafficDistributionChart.push(
            {
                name: t('dashboard.brandTraffic.adCost'),
                type: 'bar',
                data: statsData?.stats?.map((item: any) => item?.total_ad_cost),
            },
            {
                name: t('dashboard.global.blended_ctr'),
                type: 'line',
                data: statsData?.stats?.map((item: any) => item?.blended_ctr),
            }
        );
    } else {
        BrandedTrafficDistributionChart.push(
            {
                name: t('dashboard.global.blended_ctr'),
                type: 'line',
                data: statsData?.stats?.map((item: any) => item?.blended_ctr),
            },
            {
                name: t('dashboard.brandTraffic.searchAdCost'),
                type: 'bar',
                data: statsData?.stats?.map((item: any) => item?.total_ad_cost_search),
            },
            {
                name: t('dashboard.brandTraffic.shoppingAdCost'),
                type: 'bar',
                data: statsData?.stats?.map((item: any) => item?.total_ad_cost_shopping),
            }
        );

        // Vérifier si total_ad_cost_pmax existe dans les données
        if (statsData?.stats?.some((item: any) => item?.total_ad_cost_pmax !== undefined)) {
            BrandedTrafficDistributionChart.push(
                {
                    name: t('dashboard.brandTraffic.pmaxAdCost'),
                    type: 'bar',
                    data: statsData?.stats?.map((item: any) => item?.total_ad_cost_pmax),
                }
            );
        }
    }

    const BlendedCTRAdCostChart = [];

    if (workspace?.template !== "E-commerce") {
        BlendedCTRAdCostChart.push(
            { name: t('dashboard.brandTraffic.organicBrandTraffic'), data: statsData?.stats?.map((item: any) => item?.total_organic_clicks) },
            { name: t('dashboard.brandTraffic.adBrandTraffic'), data: statsData?.stats?.map((item: any) => item?.total_ad_clicks) },
            { name: t('dashboard.brandTraffic.uncapturedBrandTraffic'), data: statsData?.stats?.map((item: any) => item?.uncaptured_brand_traffic) }
        );
    } else {
        // Condition pour E-commerce
        BlendedCTRAdCostChart.push(
            { name: t('dashboard.brandTraffic.organicBrandTraffic'), data: statsData?.stats?.map((item: any) => item?.total_organic_clicks) },
            { name: t('dashboard.brandTraffic.searchPaid'), data: statsData?.stats?.map((item: any) => item?.total_ad_clicks_search) },
            { name: t('dashboard.brandTraffic.shoppingPaid'), data: statsData?.stats?.map((item: any) => item?.total_ad_clicks_shopping) }
        );

        // Vérifier si ad_clicks_pmax existe et l'ajouter avant Uncaptured Brand Traffic
        if (statsData?.stats?.some((item: any) => item?.total_ad_clicks_pmax !== undefined)) {
            BlendedCTRAdCostChart.push(
                { name: t('dashboard.brandTraffic.pmaxPaid'), data: statsData?.stats?.map((item: any) => item?.total_ad_clicks_pmax) }
            );
        }

        // Ajouter Uncaptured Brand Traffic
        BlendedCTRAdCostChart.push(
            { name: t('dashboard.brandTraffic.uncapturedBrandTraffic'), data: statsData?.stats?.map((item: any) => item?.uncaptured_brand_traffic) }
        );
    }


    const Top20Keywords = [];

    if (workspace?.template !== "E-commerce") {
        Top20Keywords.push(
            { name: t('dashboard.brandTraffic.organicBrandTraffic'), data: statsData?.keywords?.slice(0, 20).map((item: any) => item?.total_organic_clicks) },
            { name: t('dashboard.brandTraffic.paidBrandTraffic'), data: statsData?.keywords?.slice(0, 20).map((item: any) => item?.total_ad_clicks) },
            { name: t('dashboard.brandTraffic.uncapturedBrandTraffic'), data: statsData?.keywords?.slice(0, 20).map((item: any) => item?.uncaptured_brand_traffic) }
        );
    } else {
        Top20Keywords.push(
            { name: t('dashboard.brandTraffic.organicBrandTraffic'), data: statsData?.keywords.map((item: any) => item?.total_organic_clicks) },
            { name: t('dashboard.brandTraffic.searchPaid'), data: statsData?.keywords.map((item: any) => item?.total_ad_clicks_search) },
            { name: t('dashboard.brandTraffic.shoppingPaid'), data: statsData?.keywords.map((item: any) => item?.total_ad_clicks_shopping) },
        );

        // Vérifier si ad_clicks_pmax existe dans les keywords
        if (statsData?.keywords?.some((item: any) => item?.total_ad_clicks_pmax !== undefined)) {
            Top20Keywords.push(
                { name: t('dashboard.brandTraffic.pmaxPaid'), data: statsData?.keywords?.map((item: any) => item?.total_ad_clicks_pmax) }
            );
        }

        // Ajouter Uncaptured Brand Traffic
        Top20Keywords.push(
            { name: t('dashboard.brandTraffic.uncapturedBrandTraffic'), data: statsData?.keywords?.map((item: any) => item?.uncaptured_brand_traffic) }
        );
    }

    const trafficBrandedFormat = [
        {
            value: "Value",
            label: t('dashboard.global.value'),
        },
        {
            value: "Percent",
            label: t('dashboard.global.percent'),
        },
    ];

    useEffect(() => {
        if (statsData && !isFetching) {
            if (filterStore.timestep === 'year_week') {
                const yearWeekRegex = /^\d{4}-\d{2}$/;
                const formattedLabels = statsData.stats.map((item: any) => {
                    if (yearWeekRegex.test(item[filterStore.timestep])) {
                        const [year, week] = item[filterStore.timestep].split('-');
                        return formatWeekLabel(parseInt(year), parseInt(week), currentLang.value);
                    }
                });
                setCustomLabels(formattedLabels);

            } else if (filterStore.timestep === 'year_month') {
                const formatedMontlyDates = statsData.stats.map((item: any) => {
                    const [year, week] = item[filterStore.timestep].split('-');
                    return `${week}/${year}`;
                })
                setCustomLabels(formatedMontlyDates);
            } else {
                const formatedDailyDates = statsData.stats.map((item: any) => {
                    const newDate = new Date(item[filterStore.timestep]);
                    const month = String(newDate.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based, so we add 1
                    const day = String(newDate.getUTCDate()).padStart(2, '0');
                    const year = newDate.getUTCFullYear();
                    if (currentLang.value === 'fr') {
                        return `${day}/${month}/${year}`;
                    } else {
                        return `${month}/${day}/${year}`;
                    }
                })
                setCustomLabels(formatedDailyDates);
            }
        }
    }, [currentLang.value, statsData, filterStore.timestep]);


    return (
        <>
            {(!filterStore?.countries || !filterStore?.keywords || !filterStore?.devices)
                ? <SplashScreen />
                : <Container maxWidth="xl" sx={{ marginTop: 1 }}>

                    {!disableWelcome?.value &&
                        <Grid sx={{ marginBottom: 3, borderRadius: 2 }} spacing={2} xs={12} lg={12} md={12}>
                            <Welcome
                                onClose={() => disableWelcome.onTrue()}
                                title={t('dashboard.brandTraffic.welcomeTrafficAnalysis')}
                                description={t('dashboard.brandTraffic.descriptionTrafficAnalysis')}
                                img={lgUp ? <MotivationIllustration /> : <></>}
                            />
                        </Grid>
                    }
                    <div>
                        <Grid container spacing={2}>
                            {stats.map((stat, index) => (
                                <Grid key={index} xs={6} md={4} lg={2}>
                                    {isFetching || (!statsData && !statsError) ? (
                                        <Skeleton
                                            width={'100%'}
                                            height={'110px'}
                                            style={{ borderRadius: 10 }}
                                            variant="rounded"
                                            animation="wave"
                                        >
                                            <>
                                                <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
                                                <Typography color="success.main">Active</Typography>
                                            </>
                                        </Skeleton>
                                    ) : (
                                        <BankingWidgetSummary
                                            spanColor={stat.spanColor}
                                            title={stat?.title}
                                            compareData={stat?.compareData}
                                            textColor={stat?.textColor}
                                            icon={stat?.icon}
                                            color={stat.color}
                                            percent={0}
                                            total={stat?.value}
                                            chart={{
                                                series: stat.series,
                                            }}
                                        />
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    </div>

                    <div>
                        <Grid container spacing={2}>
                            <Grid xs={12} md={6} lg={6}>
                                <Paper
                                    sx={{
                                        backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                        boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                        height: '500px',
                                        borderRadius: 2,
                                        marginTop: 2,
                                        marginBottom: 1
                                    }}
                                >
                                    {isFetching || (!statsData && !statsError)
                                        ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                            <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                            <Typography color={"success.main"}>Active</Typography>
                                        </Skeleton>
                                        :
                                        <div style={{ padding: 20, paddingLeft: 25, paddingRight: 25 }}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div style={{ marginBottom: 20 }}>
                                                    <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.brandTraffic.organicTrafficVsAdTraffic')}</Typography>
                                                    <Typography variant="h6" sx={{ mb: 5 }}>{t('dashboard.brandTraffic.branded_traffic_distribution')}</Typography>
                                                </div>


                                                <TextField
                                                    sx={{ width: 150, height: 50, position: 'relative', top: -25 }}
                                                    size="small"
                                                    variant="outlined"
                                                    select
                                                    fullWidth
                                                    label={t('dashboard.global.display')}
                                                    value={selectedDisplayGraph}
                                                    onChange={(event) => setSelectedDisplayGraphValue(event.target.value)}
                                                >
                                                    {trafficBrandedFormat.map((option, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>


                                            </div>

                                            {selectedDisplayGraph === "Value"
                                                ? <ChartColumnStacked
                                                    series={statsData ? BlendedCTRAdCostChart : []}
                                                    labels={customLabels}
                                                />
                                                : <ChartColumnStackedPercent
                                                    series={statsData ? BlendedCTRAdCostChart : []}
                                                    labels={customLabels}
                                                />
                                            }

                                        </div>
                                    }

                                </Paper>
                            </Grid>
                            <Grid xs={12} md={6} lg={6}>
                                <Paper
                                    sx={{
                                        backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                        boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                        height: '500px',
                                        borderRadius: 2,
                                        marginTop: 2,
                                        marginBottom: 1
                                    }}
                                >

                                    {isFetching || (!statsData && !statsError)
                                        ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                            <>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </>
                                        </Skeleton>
                                        : <div>
                                            <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                                                <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.brandTraffic.trafficCost')}</Typography>

                                                <Typography variant="h6" sx={{ mb: 5 }}>
                                                    {t('dashboard.brandTraffic.blendedCtrAndAdCost')}
                                                </Typography>

                                            </div>

                                            <ChartMixed
                                                series={statsData ? BrandedTrafficDistributionChart : []}
                                                labels={customLabels}
                                            />
                                        </div>
                                    }

                                </Paper>
                            </Grid>
                        </Grid>
                    </div>

                    {filterStore?.keywords?.length !== 1 ?
                        <div>
                            <Grid container spacing={2}>
                                <Grid xs={12} md={6} lg={6}>
                                    <Paper
                                        sx={{
                                            backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                            boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                            height: { lg: '632px', xs: 500 },
                                            borderRadius: 2,
                                            marginTop: 1.3,
                                            marginBottom: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {isFetching || (!statsData && !statsError) ? (
                                            <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                                <>
                                                    <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                    <Typography color={"success.main"}>Active</Typography>
                                                </>
                                            </Skeleton>
                                        ) : (
                                            <div style={{ padding: '20px 25px', width: '100%' }}>
                                                <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.brandTraffic.brandKeywords')}</Typography>

                                                <Typography variant="h6" sx={{ mb: 2 }}>
                                                    {filterStore?.keywords?.length > 1
                                                        ? t('dashboard.brandTraffic.selectedKeywordsTrafficPotential')
                                                        : t('dashboard.brandTraffic.top20BrandKeywordsTrafficPotential')
                                                    }
                                                </Typography>

                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', overflow: 'hidden' }}>
                                                    <ChartDonutCustom
                                                        labels={statsData ? statsData?.keywords?.map((item: any) => item?.search_term) : []}
                                                        series={statsData?.keywords?.map((item: any) => Number(item?.total_organic_searches))}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Paper>

                                </Grid>
                                <Grid xs={12} md={6} lg={6}>
                                    <Paper
                                        sx={{
                                            backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                            boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                            height: '632px',
                                            borderRadius: 2,
                                            marginTop: 1.3,
                                            marginBottom: 1
                                        }}
                                    >
                                        {isFetching || (!statsData && !statsError)
                                            ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                                <>
                                                    <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                    <Typography color={"success.main"}>Active</Typography>
                                                </>
                                            </Skeleton>
                                            : <div style={{ padding: 20, paddingLeft: 25, paddingRight: 25 }}>

                                                <div style={{ marginBottom: 20 }}>
                                                    <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.brandTraffic.brandKeywords')}</Typography>
                                                    <Typography variant="h6" sx={{ mb: 5 }}>
                                                        {filterStore?.keywords?.length > 1
                                                            ? t('dashboard.brandTraffic.selectedKeywordsTrafficDistribution')
                                                            : t('dashboard.brandTraffic.top20BrandedKeywords')
                                                        }
                                                    </Typography>
                                                </div>

                                                <ChartBar
                                                    categories={statsData ? statsData?.keywords?.map((item: any) => item?.search_term) : []}
                                                    series={Top20Keywords}
                                                />
                                            </div>
                                        }

                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>

                        : <Grid xs={12} md={12} lg={12} spacing={2}>
                            <Paper
                                sx={{
                                    backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                    boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                    height: { lg: '500px', xs: 870 },
                                    borderRadius: 2,
                                    marginTop: 1.3,
                                    marginBottom: 1,
                                    width: '100%'
                                }}
                            >

                                {statsData?.keywords.length !== 1
                                    ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                        <>
                                            <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                            <Typography color={"success.main"}>Active</Typography>
                                        </>
                                    </Skeleton>

                                    : <div style={{ padding: 20, paddingLeft: 25, paddingRight: 25 }}>

                                        <Grid container>
                                            <Grid sx={{ height: '100%' }} xs={12} md={6}>
                                                <div style={{ marginBottom: 20 }}>
                                                    <h2 style={{ fontSize: '.75rem', color: 'grey', textTransform: 'uppercase' }} className="uppercase">{t('dashboard.brandTraffic.brandKeyword')}</h2>
                                                    <h1 style={{ fontSize: '1.25rem', top: -8, position: 'relative' }}>
                                                        {t('dashboard.brandTraffic.selectedKeywordTrafficDistribution')}
                                                    </h1>
                                                </div>
                                                <Typography style={{ fontSize: 65, fontWeight: 'bold', color: theme.palette[ColorSchema.SUCCESS].light }}>
                                                    {((parseInt(statsData?.keywords[0].total_organic_clicks, 10) + parseInt(statsData?.keywords[0].total_ad_clicks, 10)) / (parseInt(statsData?.keywords[0].total_organic_clicks, 10) + parseInt(statsData?.keywords[0].total_ad_clicks, 10) + parseInt(statsData?.keywords[0].uncaptured_brand_traffic, 10)) * 100).toFixed(1)} %
                                                </Typography>
                                                <span style={{ fontSize: '1.25rem' }}>{t('dashboard.brandTraffic.of_potential_traffic_on')} <span style={{ color: theme.palette.mode === "dark" ? theme.palette[ColorSchema.INFO].main : theme.palette[ColorSchema.INFO].light, fontWeight: "bold" }}>{statsData?.keywords[0]?.search_term} </span>
                                                    keyword is captured

                                                    {(parseInt(statsData?.keywords[0]?.total_organic_clicks) !== 0 && parseInt(statsData?.keywords[0]?.total_ad_clicks) !== 0)
                                                        ? ' ' + t('dashboard.brandTraffic.by_organic_and_paid_channels')
                                                        :
                                                        (parseInt(statsData?.keywords[0]?.total_organic_clicks)) === 0 && parseInt(statsData?.keywords[0]?.total_ad_clicks) !== 0
                                                            ? " " + t('dashboard.brandTraffic.by_paid_channels')
                                                            : (parseInt(statsData?.keywords[0]?.total_organic_clicks)) !== 0 && parseInt(statsData?.keywords[0]?.total_ad_clicks) === 0
                                                            && ' ' + t('dashboard.brandTraffic.by_organic')
                                                    }
                                                </span>
                                                {parseInt(statsData?.keywords[0].total_organic_clicks, 10) + parseInt(statsData?.keywords[0].total_ad_clicks, 10) && parseInt(statsData?.compareKeywords[0].total_organic_clicks, 10) + parseInt(statsData?.compareKeywords[0].total_ad_clicks, 10) !== 0
                                                    ? <>
                                                        <div style={{ marginTop: '70px', borderLeft: `4px solid ${theme.palette[ColorSchema.WARNING].light}`, paddingLeft: '10px' }}>

                                                            {filterStore?.compare !== false &&
                                                                <>
                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                        <Iconify style={{ position: 'relative', marginLeft: 10 }} width={23} icon={'noto:light-bulb'} />
                                                                        <Typography style={{ marginLeft: 5, fontWeight: 'bold' }}> {t('dashboard.brandTraffic.performanceInsight')}</Typography>
                                                                    </div>
                                                                    <div style={{ marginTop: 4, marginLeft: 10 }}>
                                                                        <span> {t('dashboard.brandTraffic.overall_traffic_on_this_keyword_decreased_by')}
                                                                            <span style={{
                                                                                fontWeight: 'bold',
                                                                                fontSize: '1.3rem',
                                                                                color: (((((parseInt(statsData?.keywords[0].total_organic_clicks, 10) + parseInt(statsData?.keywords[0].total_ad_clicks, 10))) - ((parseInt(statsData?.compareKeywords[0].total_organic_clicks, 10) + parseInt(statsData?.compareKeywords[0].total_ad_clicks, 10)))) / ((parseInt(statsData?.compareKeywords[0].total_organic_clicks, 10) + parseInt(statsData?.compareKeywords[0].total_ad_clicks, 10)))) * 100) >= 0 ? '#118d57' : '#B71D18'
                                                                            }}>
                                                                                {' '}
                                                                                {(((((parseInt(statsData?.keywords[0].total_organic_clicks, 10) + parseInt(statsData?.keywords[0].total_ad_clicks, 10))) - ((parseInt(statsData?.compareKeywords[0].total_organic_clicks, 10) + parseInt(statsData?.compareKeywords[0].total_ad_clicks, 10)))) / ((parseInt(statsData?.compareKeywords[0].total_organic_clicks, 10) + parseInt(statsData?.compareKeywords[0].total_ad_clicks, 10)))) * 100).toFixed(1)} %

                                                                                {(((parseInt(statsData?.keywords[0].total_organic_clicks, 10) + parseInt(statsData?.keywords[0].total_ad_clicks, 10)) - (parseInt(statsData?.compareKeywords[0].total_organic_clicks, 10) + parseInt(statsData?.compareKeywords[0].total_ad_clicks, 10))) / (parseInt(statsData?.compareKeywords[0].total_organic_clicks, 10) + parseInt(statsData?.compareKeywords[0].total_ad_clicks, 10))) > 0
                                                                                    ? <Iconify icon={'eva:trending-up-fill'} />
                                                                                    : <Iconify icon={'eva:trending-down-fill'} />
                                                                                }
                                                                                {' '}
                                                                            </span>

                                                                            {t('dashboard.brandTraffic.compared_to_the_comparison_period')}
                                                                            <span>
                                                                                {' ('}{new Date(filterStore?.compareStart).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })} {t('dashboard.global.to')} {' '}
                                                                                {new Date(filterStore?.compareEnd).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}{')'}
                                                                            </span>

                                                                        </span>
                                                                    </div>
                                                                </>
                                                            }


                                                        </div>
                                                    </>
                                                    : null
                                                }
                                            </Grid>
                                            <Grid xs={12} md={6}>
                                                <div style={{
                                                    marginTop: 30,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '100%',
                                                    width: '100%'
                                                }}>
                                                    <ChartDonut
                                                        series={[parseInt(statsData?.keywords[0].total_organic_clicks, 10), parseInt(statsData?.keywords[0].total_ad_clicks, 10), parseInt(statsData?.keywords[0].uncaptured_brand_traffic, 10)]}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                }
                            </Paper>
                        </Grid>
                    }

                </Container>
            }
        </>
    )
}